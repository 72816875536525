import React from 'react';
import ProjectEdit from 'pages/project/Edit';
import Project from 'pages/project/Index';

/**
 * 配車関連
 */
import OperationSchedules from 'pages/instruction/Schedules';
import OperationScheduleEdit from 'pages/instruction/ScheduleEdit';
import OperationIndex from 'pages/instruction/Index';
import OperationEdit from 'pages/instruction/Edit';

/*
import OperationIndex from "pages/instruction/Index";
import OperationDriver from "pages/instruction/Driver";
import OperationList from "pages/instruction/List";
import OperationProject from "pages/instruction/Project";
import OperationTimelineProject from "pages/instruction/Timeline";
*/
import MstUser from 'pages/mst/user/Index';
import MstUserEdit from 'pages/mst/user/Edit';
import MstCompany from 'pages/mst/company/Index';
import MstCompanyEdit from 'pages/mst/company/Edit';
import MatLuggage from 'pages/mst/luggage/Index';
import MatLuggageEdit from 'pages/mst/luggage/Edit';
import MstAddress from 'pages/mst/address/Index';
import MstAddressEdit from 'pages/mst/address/Edit';
import MstProjectKind from 'pages/mst/projectKind/Index';
import MstProjectKindEdit from 'pages/mst/projectKind/Edit';
import MstVehicle from 'pages/mst/vehicle/Index';
import MstVehicleEdit from 'pages/mst/vehicle/Edit';
import MstVehicleType from 'pages/mst/vehicleType/Index';
import MstVehicleTypeEdit from 'pages/mst/vehicleType/Edit';
import MstRole from 'pages/mst/role/Index';
import MstRoleEdit from 'pages/mst/role/Edit';
import MstSelfIfomationEdit from 'pages/mst/selfInfomation/Edit';
import MstTranMethod from 'pages/mst/transportMethod/Index';
import MstTranMethodEdit from 'pages/mst/transportMethod/Edit';

/**
 * 請求関連
 */
import PaperworkProjectCheck from 'pages/paperwork/ProjectCheck';
import PaperworkClosing from 'pages/paperwork/closing/Index';
import PaperworkDetails from 'pages/paperwork/closing/CompanyDetails';
import PaperworkInvoice from 'pages/paperwork/invoice/Index';
import PaperworkOtherInvoice from 'pages/paperwork/customInvoice/Index';
import PaperworkOtherInvoiceEdit from 'pages/paperwork/customInvoice/Edit';

/**
 * メニューの型
 * flg=falseの場合、サブメニュー表示エリアには表示されない
 */
export type typeSubMenu = {
  path: string;
  label: string;
  element: JSX.Element;
  flg: boolean;
  aTab: boolean;
};

export type typeMenu = {
  label: string;
  path: string;
  subMenus: typeSubMenu[];
  aTab?: boolean;
  element: JSX.Element;
};

const menuProject: typeSubMenu[] = [
  {
    path: '/project/input',
    label: '案件入力',
    element: <ProjectEdit />,
    flg: true,
    aTab: false,
  },
  {
    path: '/project/edit/:id',
    label: '案件編集',
    element: <ProjectEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/project/',
    label: '案件一覧',
    element: <Project />,
    flg: true,
    aTab: false,
  },
];

const menuOperation: typeSubMenu[] = [
  {
    path: '/operation/index',
    label: '配車一覧',
    element: <OperationIndex />,
    flg: true,
    aTab: false,
  },
  {
    path: '/operation/instructions',
    label: '運行指示',
    element: <OperationSchedules />,
    flg: true,
    aTab: false,
  },
  {
    path: '/operation/instructions/edit',
    label: '運行指示作成',
    element: <OperationScheduleEdit />,
    flg: true,
    aTab: false,
  },
  {
    path: '/operation/instruction/edit/:id',
    label: '運行指示詳細',
    element: <OperationEdit />,
    flg: false,
    aTab: false,
  },
  /*
  {
    path: "/operation/driver",
    label: "ドライバー",
    element: <OperationDriver />,
    flg: true,
    aTab: false,
  },
  {
    path: "/operation/project",
    label: "案件",
    element: <OperationProject />,
    flg: true,
    aTab: false,
  },
  {
    path: "/operation/timeline",
    label: "案件（タイムライン）",
    element: <OperationTimelineProject />,
    flg: true,
    aTab: false,
  },
  {
    path: "/operation/list",
    label: "一覧",
    element: <OperationList />,
    flg: true,
    aTab: false,
  },
  */
];

/**
 * マスタ系
 */
const menuMst: typeSubMenu[] = [
  {
    path: '/mst/self-information',
    label: '自社情報',
    element: <MstSelfIfomationEdit />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/user',
    label: '社員管理',
    element: <MstUser />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/user/input',
    label: 'ユーザ新規登録',
    element: <MstUserEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/user/edit/:id',
    label: 'ユーザ詳細',
    element: <MstUserEdit />,
    flg: false,
    aTab: false,
  },

  /////////////////////////////////// 会社マスタ
  {
    path: '/mst/company/',
    label: '会社',
    element: <MstCompany />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/company/input',
    label: '会社新規登録',
    element: <MstCompanyEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/company/edit/:id',
    label: '会社詳細',
    element: <MstCompanyEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/company/edit/:id/:p_id',
    label: '担当者詳細',
    element: <></>,
    flg: false,
    aTab: false,
  },
  ///////////////////////////////////// 荷種マスタ
  {
    path: '/mst/luggage',
    label: '荷種',
    element: <MatLuggage />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/luggage/input',
    label: '荷種新規登録',
    element: <MatLuggageEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/luggage/edit/:id',
    label: '荷種詳細',
    element: <MatLuggageEdit />,
    flg: false,
    aTab: false,
  },

  ///////////////////////////////////// 住所マスタ
  {
    path: '/mst/address/',
    label: '住所',
    element: <MstAddress />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/address/input',
    label: '住所新規登録',
    element: <MstAddressEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/address/edit/:id',
    label: '住所詳細',
    element: <MstAddressEdit />,
    flg: false,
    aTab: false,
  },

  ///////////////////////////////////// 案件区分
  {
    path: '/mst/project-kind/',
    label: '案件区分',
    element: <MstProjectKind />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/project-kind/input',
    label: '案件区分登録',
    element: <MstProjectKindEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/project-kind/edit/:id',
    label: '案件区分詳細',
    element: <MstProjectKindEdit />,
    flg: false,
    aTab: false,
  },

  ///////////////////////////// 車両マスタ
  {
    path: '/mst/vehicle/',
    label: '車両情報',
    element: <MstVehicle />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/vehicle/input',
    label: '車両登録',
    element: <MstVehicleEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/vehicle/edit/:id',
    label: '車両詳細',
    element: <MstVehicleEdit />,
    flg: false,
    aTab: false,
  },

  ///////////////////////////// 車両種マスタ
  {
    path: '/mst/vehicleType/',
    label: '車両種情報',
    element: <MstVehicleType />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/vehicleType/input',
    label: '車両種登録',
    element: <MstVehicleTypeEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/vehicleType/edit/:id',
    label: '車両種詳細',
    element: <MstVehicleTypeEdit />,
    flg: false,
    aTab: false,
  },

  ///////////////////////////// 権限マスタ
  {
    path: '/mst/role/',
    label: '権限',
    element: <MstRole />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/role/input',
    label: '権限登録',
    element: <MstRoleEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/role/edit/:id',
    label: '権限詳細',
    element: <MstRoleEdit />,
    flg: false,
    aTab: false,
  },

  ///////////////////////////// 輸送手段マスタ
  {
    path: '/mst/transport-method',
    label: '輸送手段',
    element: <MstTranMethod />,
    flg: true,
    aTab: false,
  },
  {
    path: '/mst/transport-method/input',
    label: '輸送手段登録',
    element: <MstTranMethodEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/mst/transport-method/edit/:id',
    label: '輸送手段詳細',
    element: <MstTranMethodEdit />,
    flg: false,
    aTab: false,
  },
];

/**
 * 事務系
 */
const menuPaperwork: typeSubMenu[] = [
  /*
  {
    path: '/paperwork/project-check',
    label: '案件チェック',
    element: <PaperworkProjectCheck />,
    flg: true,
    aTab: false,
  },
  */
  {
    path: '/paperwork/closing',
    label: '締め処理',
    element: <PaperworkClosing />,
    flg: true,
    aTab: false,
  },
  {
    path: '/paperwork/closing/company/:c_id/person/:p_id/details',
    label: '締め処理',
    element: <PaperworkDetails />,
    flg: false,
    aTab: false,
  },
  {
    path: '/paperwork/invoice',
    label: '請求書',
    element: <PaperworkInvoice />,
    flg: true,
    aTab: false,
  },
  {
    path: '/paperwork/custom-invoice',
    label: '個別請求',
    element: <PaperworkOtherInvoice />,
    flg: true,
    aTab: false,
  },
  {
    path: '/paperwork/custom-invoice/input',
    label: '別請求書作成',
    element: <PaperworkOtherInvoiceEdit />,
    flg: false,
    aTab: false,
  },
  {
    path: '/paperwork/custom-invoice/edit/:id',
    label: '別請求書編集',
    element: <PaperworkOtherInvoiceEdit />,
    flg: false,
    aTab: false,
  },
];

const menuSystem: typeSubMenu[] = [
  {
    path: '/logout',
    label: 'ログアウト',
    element: <></>,
    flg: true,
    aTab: false,
  },
];

export const menus: typeMenu[] = [
  {
    path: 'project',
    label: '案件入力',
    subMenus: menuProject,
    aTab: false,
    element: <Project />,
  },
  {
    path: 'full-screen/schedules',
    label: '予定表',
    subMenus: [],
    aTab: true,
    element: <></> /*<OperationIndex />*/,
  },
  {
    path: 'operation/index',
    label: '配車',
    subMenus: menuOperation,
    aTab: false,
    element: <OperationIndex /> /*<OperationIndex />*/,
  },
  {
    path: 'mst',
    label: 'マスタ',
    subMenus: menuMst,
    aTab: false,
    element: <></>,
  },
  {
    path: 'paperwork',
    label: '事務',
    subMenus: menuPaperwork,
    aTab: false,
    element: <></>,
  },
  {
    path: 'system',
    label: 'システム',
    subMenus: menuSystem,
    aTab: false,
    element: <></>,
  },
];
