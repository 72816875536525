import React, { useContext, useEffect } from 'react';
import Modal from 'atoms/Modal';
import { SelectChangeEvent } from '@mui/material';
import { Grid, Typography } from '@mui/material';
import { mathDateTime, changeDateFromTypeDate } from 'functions/time';
import { ItemSelect } from 'components/Common';
import { DateInput } from 'atoms/DateInput';
import { ClientCompany } from 'components/project/Index';
import { PrimaryButton, SecondButton, LinkPrimaryButton } from 'atoms/Button';
import { tProjectSearch } from 'types/project';
import * as ProjectSearchItem from 'components/project/Search';
import { MstDataContext } from 'contexts/Mst';
import Loading from 'atoms/Loading';
import { SmallSelect } from 'atoms/Select';
import log from 'functions/logger';

export interface callbackProps {
  terms: tProjectSearch;
}

interface GridItemFieldProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  size?: Record<string, number>;
}

interface ModalSearchProps {
  terms: tProjectSearch | any;
  setTerms: React.Dispatch<React.SetStateAction<tProjectSearch | any>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (props: callbackProps) => void;
}

/**
 * 検索などで使う項目
 */
export default function Main({
  terms,
  setTerms,
  open,
  setOpen,
  callback,
}: ModalSearchProps) {
  const handleClickSeach = () => {
    callback({ terms: terms });
    setOpen(false);
  };

  return (
    <Modal
      title={'検索：案件情報'}
      actions={
        <>
          <PrimaryButton label="検索" onClick={handleClickSeach} />
          <SecondButton onClick={() => setOpen(false)} label={'閉じる'} />
          <LinkPrimaryButton label="TOPへ" href={'/'} />
        </>
      }
      open={open}
      onClose={() => setOpen(false)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <RangeLoadDatetime values={terms} setValues={setTerms} />
        </Grid>
        <ClientCompany
          values={terms}
          setValues={setTerms}
          size={{ xs: 12, lg: 4 }}
        />
        <ProjectSearchItem.Status
          values={terms}
          count={2}
          setValues={setTerms}
        />
        {/*
        <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
        */}
        {/*
        <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
        */}
      </Grid>
    </Modal>
  );
}

/**
 * 初回積日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeLoadDatetime({
  values,
  setValues,
}: {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}) {
  return (
    <>
      <Typography variant="h6">積日範囲</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.load_datetime_from || ''}
            name="load_datetime_from"
            onDateChange={(newDate: string) => {
              const Add7Day = mathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                load_datetime_from: newDate,
                load_datetime_to: changeDateFromTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: 'center' }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.load_datetime_to || ''}
            name="load_datetime_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, load_datetime_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

/**
 * 最終卸日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeUnloadDatetime({
  values,
  setValues,
}: {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}) {
  return (
    <>
      <Typography variant="h6">卸日範囲</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.unload_datetime_from || ''}
            name="unload_datetime_from"
            onDateChange={(newDate: string) => {
              const Add7Day = mathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                unload_datetime_from: newDate,
                unload_datetime_to: changeDateFromTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: 'center' }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.unload_datetime_to || ''}
            name="unload_datetime_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, unload_datetime_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export const Status = ({
  values,
  count = 1,
  setValues,
  size = undefined,
}: GridItemFieldProps & { count?: number }) => {
  const { SYSTEM } = useContext(MstDataContext);
  const [status, setStatus] = React.useState<(number | null)[]>(
    values.status || new Array(count - 1).fill(null)
  );

  const handleChangeValues = (index: number, value: number) => {
    setStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = value === -1 ? null : value;
      return newStatus;
    });
  };

  useEffect(() => {
    setValues((prev: any) => {
      return {
        ...prev,
        status: status,
      };
    });
  }, [status]);

  if (!SYSTEM) return <Loading flg={true} />;

  const SelectItem = (index: number) => (
    <Grid item xs={3} key={`status-label-${index}`}>
      <SmallSelect
        labelId={`status-label-${index}`}
        id={`status-${index}`}
        items={SYSTEM.project.status.list}
        selectedValue={status[index]}
        onChange={(
          event: SelectChangeEvent<number | string>,
          child: React.ReactNode
        ) => handleChangeValues(index, Number(event.target.value))}
      />
    </Grid>
  );

  return (
    <Grid item xs={12}>
      <Typography variant="h6">{`区分`}</Typography>
      <Grid container spacing={2}>
        {Array.from({ length: count - 1 }).map((_, i) => SelectItem(i))}
      </Grid>
    </Grid>
  );
};

export const Kind = ({
  values,
  setValues,
  size = undefined,
}: GridItemFieldProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  if (!SYSTEM) return <Loading flg={true} />;

  const options = [{ id: -1, label: '-' }, ...SYSTEM.project.status.list];

  return (
    <ItemSelect
      labelId={`status-label`}
      name={`"status`}
      label="区分"
      value={values.status}
      items={options}
      setValues={setValues}
      size={size}
    />
  );
};
