import { create, API_URL } from 'functions/axios';
import { tProject, tWaypoints } from 'types/project';
import { typeHash } from 'types';

/**
 * 案件情報登録
 * @returns
 */
export function setProject(projectInfo: tProject, waypoints: tWaypoints) {
  const ins = create();
  return ins.post(API_URL.base + '/project/store', {
    projectInfo: projectInfo,
    waypoints: waypoints,
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjects(page: number, filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + '/project/index', {
    params: { page: page, filter: filter, order: order },
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjectList(
  filter: any = {},
  order: any = {},
  withs: any = []
) {
  const ins = create();
  return ins.get(API_URL.base + '/project/list', {
    params: { filter: filter, order: order, withs: withs },
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProject(id: string | number) {
  const ins = create();
  return ins.get(API_URL.base + '/project/show/' + id);
}

/**
 * 案件情報更新チェック処理
 * @returns
 */
export function checkUpdateProject(
  projectInfo: tProject,
  waypoints: tWaypoints
) {
  const ins = create();
  return ins.post(API_URL.base + '/project/update/check/' + projectInfo.id, {
    projectInfo: projectInfo,
    waypoints: waypoints,
  });
}

/**
 * 案件情報更新
 * @returns
 */
export function updateProject(projectInfo: tProject, waypoints: tWaypoints) {
  const ins = create();
  return ins.post(API_URL.base + '/project/update/' + projectInfo.id, {
    projectInfo: projectInfo,
    waypoints: waypoints,
  });
}

/**
 * 案件情報ステータス更新
 * @returns
 */
export function updateProjectStatus(p_id: number, status: number) {
  const ins = create();
  return ins.post(API_URL.base + '/project/update/status', {
    id: p_id,
    status: status,
  });
}

/**
 * 案件情報登録
 * @returns
 */
export function deleteProject(id: number) {
  const ins = create();
  return ins.post(API_URL.base + '/project/destroy/' + id);
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjectCards(
  page: number,
  filter: any = {},
  order: any = {}
) {
  const ins = create();
  return ins.get(API_URL.base + '/project/card/index', {
    params: { page: page, filter: filter, order: order },
  });
}

/**
 * 案件情報取得
 * @returns
 */
export function getProjectCardList(filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + '/project/card/list', {
    params: { filter: filter, order: order },
  });
}
