import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import initInstruction from 'const/instruction/index';
import { tInstruction } from 'types/instruction';
import { getInstruction } from 'functions/api/instruction';
import ContentFrame from 'frames/ContentsFrame';
import * as InstButton from 'components/instruction/Button';

export default function Main() {
  const { id } = useParams();
  const [values, setValues] = useState<tInstruction>(initInstruction);

  const getData = (id: tInstruction['id']) => {
    getInstruction(id, (data: tInstruction) => {
      setValues(data);
    });
  };

  useEffect(() => {
    if (id) {
      getData(Number(id));
    }
  }, [id]);

  return (
    <ContentFrame
      HeadContent={<HeadContent id={Number(id)} values={values} />}
      MainContent={<MainContent values={values} />}
      SubContent={<SubContent />}
    />
  );
}

interface HeadContentProps {
  id: tInstruction['id'];
  values: tInstruction;
}
const HeadContent = ({ id, values }: HeadContentProps) => {
  return (
    <>
      <InstButton.Update label={'更新'} id={id} data={values} />
    </>
  );
};

interface MainContentProps {
  values: tInstruction;
}
const MainContent = ({ values }: MainContentProps) => {
  return <>{values.id}</>;
};

const SubContent = () => {
  return <></>;
};
