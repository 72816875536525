import React, { useState, useEffect } from 'react';
import { tUser, initialUser } from 'types/mst';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import * as cUser from 'components/mst/User';
import { getUser, storeUser, updateUser, deleteUser } from 'functions/api/mst';
import { PrimaryButton } from 'atoms/Button';

export default function Main() {
  const [user, setUser] = useState<tUser>(initialUser);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        getUser(id)
          .then((res) => {
            if (res.status !== 200) throw new Error('データ取得に失敗しました');
            setUser(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  const setPostRequestStore = () => {
    storeUser(user)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        window.location.href = '/mst/user';
      })
      .catch((err) => {
        alert('データ登録に失敗しました');
        console.error(err);
      });
  };

  const setPostRequestUpdate = () => {
    updateUser(user)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        window.location.href = '/mst/user';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setPostRequestDelete = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteUser(user.id)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        window.location.href = '/mst/user';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestUpdate} label="更新" />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStore} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <cUser.Email values={user} setValues={setUser} />

        <cUser.FName values={user} setValues={setUser} />
        <cUser.GName values={user} setValues={setUser} />
        <cUser.FNameKana values={user} setValues={setUser} />
        <cUser.GNameKana values={user} setValues={setUser} />
        <cUser.Role values={user} setValues={setUser} />
        {/* <cUser.Vehicle values={user} setValues={setUser} /> */}

        <cUser.Birthday values={user} setValues={setUser} />
        <cUser.Tell values={user} setValues={setUser} />
        <cUser.PostNumber values={user} setValues={setUser} />
        <cUser.Address values={user} setValues={setUser} />

        <cUser.HireDate values={user} setValues={setUser} />
        <cUser.Vehicle values={user} setValues={setUser} />
      </Grid>
    </div>
  );
}
