import React, { useContext } from 'react';
import { tTransportKey, tTransportCard } from 'types/transport';
import {
  destroyInstructions,
  storeInstructions,
} from 'functions/api/instruction';
import { MstDataContext } from 'contexts/Mst';
import { useDrop } from 'react-dnd';
import { tInstruction } from 'types/instruction';
import initInstruction from 'const/instruction';
import { tWaypoint } from 'types/project';
import { mathDateTime, changeDateFromTypeDate } from 'functions/time';
import log from 'functions/logger';

interface InsertProps {
  instructions: tInstruction[];
  tranKey?: tTransportKey;
  callbackSuccess?: ((data: tInstruction[]) => void) | undefined;
}

/**
 * tmp運行指示への登録処理
 * 対象がなければ削除になる
 * @param param0
 */
export const inserts = ({
  instructions,
  tranKey = { pj_id: 0, no: 0 },
  callbackSuccess = undefined,
}: InsertProps) => {
  const callback = (data: tInstruction[]) => {
    if (callbackSuccess) {
      callbackSuccess(data);
    }
  };
  if (instructions.length === 0) {
    // 削除処理
    destroyInstructions(tranKey, callback);
  } else {
    // tmpテーブルに登録
    storeInstructions(instructions, (data: tInstruction[]) => {
      callback(data);
    });
  }
};

/**
 * 宵積完走の運行指示を作成
 * @param comp
 */
interface CompEveningProps {
  datetime: string;
  defInstruction: tInstruction;
  lastWaypoint: tWaypoint | undefined;
  lastInstruction: tInstruction | undefined;
  instructions: tInstruction[];
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
}
export const makeCompEvening = ({
  defInstruction,
  datetime,
  lastWaypoint,
  lastInstruction,
  instructions,
  setInstructions,
}: CompEveningProps) => {
  if (lastInstruction === undefined) {
    lastInstruction = initInstruction;
  }
  const newInst = {
    ...initInstruction,
    ...defInstruction,

    // 開始情報は最終運行指示、または最初の積地情報とする
    start_datetime: changeDateFromTypeDate(
      mathDateTime(datetime, [0, 0, -1, 0, 0, 0])
    ),
    start_a_id: lastInstruction.start_a_id || null,
    start_name: lastInstruction.start_name || lastWaypoint?.a_name || '',
    start_prefectures:
      lastInstruction.start_prefectures || lastWaypoint?.prefectures || '',
    start_city: lastInstruction.start_city || lastWaypoint?.city || '',
    start_street: lastInstruction.start_street || lastWaypoint?.street || '',
    start_building:
      lastInstruction.start_building || lastWaypoint?.building || '',
    start_trailer_v_id: lastInstruction.start_trailer_v_id || null,

    // 終了地点は
    end_datetime: datetime,
    end_a_id: lastWaypoint?.a_id || null,
    end_name: lastWaypoint?.a_name || '',
    end_prefectures: lastWaypoint?.prefectures || '',
    end_city: lastWaypoint?.city || '',
    end_street: lastWaypoint?.street || '',
    end_building: lastWaypoint?.building || '',
    end_trailer_v_id: lastInstruction.end_trailer_v_id || null,

    /*
    tm_id: lastInstruction.tm_id,
    user_id: lastInstruction.user_id,
    v_id: lastInstruction.v_id,
    c_id: lastInstruction.c_id,
    c_vehicle: lastInstruction.c_vehicle,
    price: lastInstruction.price,
    flg_picup: lastInstruction.flg_picup,
    instruction_datetime: lastInstruction.instruction_datetime,
    instruction_user_id: lastInstruction.instruction_user_id,
    */
  };

  setInstructions([...instructions, newInst]);

  return [...instructions, newInst];
};

/**
 * ドロップ処理のハンドラ
 * @param day
 * @param callbackDrop
 * @param extraData
 * @returns
 */
export const useDropHandler = (
  day: string,
  callbackDrop: (item: tInstruction) => void,
  extraData: Partial<tInstruction> = {}
) => {
  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: 'TransportCard', // ドラッグ対象の種類
    drop: (item: tTransportCard) => {
      // ドロップされた時の処理
      const defInst: tInstruction = {
        ...initInstruction,
        pj_id: item.pj_id,
        no: item.tran_no,
        start_datetime: `${day} 00:00:00`,
        ...extraData, // driver_id や tm_id を渡せるようにする
      };
      callbackDrop(defInst);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return { isOver, dropRef };
};
