import React from 'react';
import { CheckboxProps } from '@mui/material/Checkbox';
import { FormControlLabel, Checkbox, Typography } from '@mui/material';

interface SmallTextFieldProps {
  label?: string;
  labelId?: string;
  props: CheckboxProps; // ここでpropsを定義します
}

export const SmallCheckbox: React.FC<SmallTextFieldProps> = ({
  label = '',
  labelId = '',
  props,
}) => {
  return (
    <FormControlLabel
      control={<Checkbox size="small" {...props} />}
      label={<Typography variant="h6">{label}</Typography>}
      id={labelId}
      labelPlacement="top" // ラベルをチェックボックスの上に配置
      sx={{
        display: 'flex',
        flexDirection: 'column-reverse', // 縦に配置し、ラベルを上に
        alignItems: 'flex-start', // 左寄せ
      }}
    />
  );
};
