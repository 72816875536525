import React from 'react';
import themeBase from 'themes/Base';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import deepmerge from 'deepmerge';

const themeFullScreen = createTheme(
  deepmerge(themeBase, {
    ...themeBase,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          main: {
            height: '100vh !important',
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl',
        },
        styleOverrides: {
          root: {
            '&.SlideArea': {
              position: 'fixed',
              zIndex: 1000,
              bottom: 0,
              backgroundColor: themeBase.palette.common.white,
              maxWidth: '100vw',
              marginLeft: 0,

              '&.SlideArea-bottom': {
                bottom: 0,
                left: 0,
                width: '100%',
                minHeight: '150px', // 自動で変えられるように
              },
            },
          },
        },
      },
    },
  })
);

interface ThemeProps {
  children: React.ReactNode;
}

export const FullScreenThemeProvider: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={themeFullScreen}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
