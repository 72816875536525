import { tDailyMemo, tDailyMemoCalender } from 'types/dailymemo';
import { strDateOrigin } from 'functions/time';

export function convertToDailyMemoCalendar(
  dailyMemos: tDailyMemo[]
): tDailyMemoCalender {
  const calendar: tDailyMemoCalender = {};

  dailyMemos.forEach((memo) => {
    const date = strDateOrigin(memo.datetime);
    if (!calendar[date]) {
      calendar[date] = [];
    }
    calendar[date].push(memo);
  });

  return calendar;
}

export function getDailyMemosToDate(
  dailyMemos: tDailyMemo[],
  date: string
): tDailyMemo[] {
  const obj: tDailyMemo[] = [];

  dailyMemos.forEach((memo) => {
    if (date === strDateOrigin(memo.datetime)) {
      obj.push(memo);
    }
  });

  return obj;
}
