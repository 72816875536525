import React from 'react';
import { Outlet } from 'react-router-dom';
import { FullScreenThemeProvider } from 'themes/FullScreenTheme';
import { Box } from '@mui/material';

//interface MainProps {}
export default function Main() {
  //const [currentPage, setCurrentPage] = useState('');

  const styles = {
    main: {
      height: '100vh !important',
    },
  };

  return (
    <FullScreenThemeProvider>
      <Box component={'main'} sx={styles.main}>
        <Outlet />
      </Box>
    </FullScreenThemeProvider>
  );
}
