import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { tRole } from 'types/mst';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material';
import { getRoles } from 'functions/api/mst';
import { PrimaryButton } from 'atoms/Button';
import PageNation from 'components/Pagenation';

export default function Main() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<tRole[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({});

  // データ取得処理
  const getSearch = async (page: number) => {
    try {
      // ここにデータ取得処理を記述します
      getRoles(page, search)
        .then((res) => {
          if (res.status !== 200) throw new Error('データ取得に失敗しました');
          setRoles(res.data.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalPages(res.data.last_page);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate('/mst/role/input');
              }}
              label="新規"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ maxWidth: '680px' }}>
            <TableHead>
              <TableRow>
                <TableCell>権限名</TableCell>
                <TableCell>管理者</TableCell>
                <TableCell>案件入力</TableCell>
                <TableCell>配車</TableCell>
                <TableCell>事務</TableCell>
                <TableCell>マスタ</TableCell>
                <TableCell>ドライバー</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles?.map((role) => {
                return (
                  <TableRow
                    key={`roles-${role.id}`}
                    onDoubleClick={() => navigate(`/mst/role/edit/${role.id}`)}
                  >
                    <TableCell>{role.name}</TableCell>
                    <TableCell>{role.admin ? '◯' : '×'}</TableCell>
                    <TableCell>{role.project ? '◯' : '×'}</TableCell>
                    <TableCell>{role.operation ? '◯' : '×'}</TableCell>
                    <TableCell>{role.paperwork ? '◯' : '×'}</TableCell>
                    <TableCell>{role.mst ? '◯' : '×'}</TableCell>
                    <TableCell>{role.driver ? '◯' : '×'}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
