import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import ContentFrame from 'frames/ContentsFrame';
import TableFrame from 'frames/TableFrame';
import {
  getCustomInvoiceEdit,
  getCustomInvoiceEditList,
} from 'functions/api/paperwork';
import { tCustomInvoiceEdit } from 'types/invoice';
import { initCustomInvoiceEdit } from 'const/invoice/index';
import * as CIEBtn from 'components/customInvocie/Button';
import Details from 'components/customInvocie/Detail';
import { changeDateFromTypeDate } from 'functions/time';
import log from 'loglevel';
import { Box, TableRow, TableCell, Typography } from '@mui/material';
import { formatNumber } from 'functions/index';
import { apiGetListSuccess } from 'types/index';

export default function Main() {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const c_id = searchParams.get('c_id'); // クエリパラメータ "c_id" を取得

  const [init, setInit] = useState<tCustomInvoiceEdit>(initCustomInvoiceEdit);
  const [values, setValues] = useState<tCustomInvoiceEdit>({
    ...init,
    c_id: Number(c_id),
  });
  const [list, setList] = useState<tCustomInvoiceEdit[]>([]);

  const callbackGetData = (data: tCustomInvoiceEdit) => {
    setValues(data);
    getList(data.c_id);
  };
  const callbackGetList = ({ data }: apiGetListSuccess) => {
    log.debug('callbackGetList', 'data', data);
    setList(data);
  };

  const getList = (cId: tCustomInvoiceEdit['c_id']) => {
    const search = { c_id: cId };
    getCustomInvoiceEditList({
      filter: search,
      order: [],
      withs: [],
      callbackSuccess: callbackGetList,
    });
  };

  useEffect(() => {
    if (id) {
      getCustomInvoiceEdit({
        id: Number(id),
        withs: [],
        callbackSuccess: callbackGetData,
      });
    }
  }, [id]);

  useEffect(() => {
    setInit((prev) => ({
      ...prev,
      date: changeDateFromTypeDate(new Date()),
      tax_id: 10,
    }));
  }, []);

  useEffect(() => {
    getList(values.c_id);
  }, [values.c_id]);

  return (
    <ContentFrame
      HeadContent={<HeadContent data={values} />}
      MainContent={<MainContent data={values} setData={setValues} />}
      SubContent={<SubContent list={list} />}
    />
  );
}

interface HeadContentProps {
  data: tCustomInvoiceEdit;
}
const HeadContent = ({ data }: HeadContentProps) => {
  const navigate = useNavigate();
  const callbackInsert = (data: tCustomInvoiceEdit) => {
    log.debug('callbackInsert', 'data', data);
    navigate(`/paperwork/custom-invoice/edit/${data.id}`);
  };

  return (
    <>
      <CIEBtn.Insert callbackSuccess={callbackInsert} data={data} />
      {data.id && <CIEBtn.Update data={data} />}
    </>
  );
};

interface MainContentProps {
  data: tCustomInvoiceEdit;
  setData: React.Dispatch<React.SetStateAction<tCustomInvoiceEdit>>;
}
const MainContent = ({ data, setData }: MainContentProps) => {
  return <Details data={data} setData={setData} />;
};

interface SubContentProps {
  list: tCustomInvoiceEdit[];
}
const SubContent = ({ list }: SubContentProps) => {
  if (!list || list.length === 0) return <></>;
  return (
    <Box sx={{ maxHeight: 300, height: '100%', overflow: 'hidden' }}>
      <TableFrame
        HeadContent={<SubHeadContet data={list} />}
        TableHeaderRows={<HeadRow />}
        TableBodyRows={<TableBodyRows data={list} />}
        SubContent={<></>}
      />
    </Box>
  );
};

interface SubHeadContetProps {
  data: tCustomInvoiceEdit[];
}
const SubHeadContet = ({ data }: SubHeadContetProps) => {
  return <CIEBtn.MakeInvoice data={data} />;
};

const HeadRow = () => {
  return (
    <TableRow>
      <TableCell>日付</TableCell>
      <TableCell>品名</TableCell>
      <TableCell>数量</TableCell>
      <TableCell>単価</TableCell>
      <TableCell>金額</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  data: tCustomInvoiceEdit[];
}
const TableBodyRows = ({ data }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  return (
    <>
      {data.map((row, index) => (
        <TableRow
          key={index}
          onDoubleClick={() =>
            navigate(`/paperwork/custom-invoice/edit/${row.id}`)
          }
        >
          <TableCell>{row.date}</TableCell>
          <TableCell>{row.label}</TableCell>
          <TableCell>
            <Typography align="right">
              {formatNumber(row.quantity.toString())}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography align="right">
              {formatNumber(row.unit_price.toString())}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography align="right">
              {formatNumber(row.price_tax_include.toString())}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
