import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { ListItem } from 'types/index';
import log from 'functions/logger';

interface SmallTextFieldProps {
  label?: string;
  props: TextFieldProps; // ここでpropsを定義します
}

const Master: React.FC<TextFieldProps> = (props, inputProps) => {
  return <TextField fullWidth {...props} />;
};

export const SmallTextField: React.FC<SmallTextFieldProps> = ({
  label,
  props,
}) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Master {...props} size="small" hiddenLabel />
    </>
  );
};

export const TableCellTextField: React.FC<SmallTextFieldProps> = ({
  label = undefined,
  props,
}) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Master {...props} size="small" hiddenLabel />
    </>
  );
};

interface SelectTextFieldProps {
  label?: string;
  items: Record<string, string>[];
  selectedValue: string;
  props: TextFieldProps;
}
export const SelectTextField = ({
  label,
  items,
  selectedValue,
  props,
}: SelectTextFieldProps) => {
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <TextField
        select
        value={selectedValue}
        variant="outlined"
        fullWidth
        {...props}
      >
        {items.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

interface AutocompleteTextFieldProps {
  name: string;
  freeSolo?: boolean;
  disableClearable?: boolean;
  label: string;
  options: Record<string, any>[];
  value: Record<string, any> | null;
  inputValue?: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    newValue: ListItem
  ) => void;
}
export const AutocompleteTextField = ({
  name,
  freeSolo = false,
  disableClearable = false,
  label,
  options,
  value,
  inputValue = '',
  onChange,
}: AutocompleteTextFieldProps) => {
  if (!inputValue) {
    inputValue = value ? value.label : '';
  }
  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <Autocomplete
        autoSelect={true}
        fullWidth={true}
        freeSolo={freeSolo}
        disableClearable={disableClearable}
        sx={{ padding: 0 }}
        getOptionLabel={(option) =>
          option.label ? String(option.label) : String(option.name)
        }
        value={value}
        inputValue={inputValue}
        onChange={(e, newValue) => {
          log.debug('onChange', newValue);
          onChange(e, newValue);
        }}
        onInputChange={(e, newInputValue) => {
          log.debug('onInputChange', newInputValue);
          if (newInputValue === 'undefined' || !newInputValue) {
            // クリアボタンが押されたとき
            if (e?.type === 'click') {
              newInputValue = ''; // クリアボタンクリック時の処理
            } else {
              const html = e.target as HTMLInputElement;
              // フォーカスが外れた時などの処理
              newInputValue = html.value;
            }
          }
          onChange(e, { id: 0, label: newInputValue } as ListItem);
        }}
        options={options}
        renderInput={(params) => (
          <SmallTextField label={undefined} props={params} />
        )}
        limitTags={5} // 最大表示数を5件に制限
      />
    </>
  );
};
