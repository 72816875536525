import { create, API_URL } from 'functions/axios';
import { tInstruction, tInstructionCard } from 'types/instruction';
import { tTransportKey } from 'types/transport';
import { apiListProps, apiGetIndexProps } from 'types/index';
import log from 'functions/logger';

/**
 * 運行指示
 * @returns
 */
export function getInstructionList(
  callbackSuccess: ((data: tInstruction[]) => void) | undefined = undefined,
  callbackError: (() => void) | undefined = undefined,
  filter: any = {},
  order: any = {},
  withs: any = []
) {
  if (order.length === 0) {
    order = [
      { field: 'pj_id', direction: 'asc' },
      { field: 'no', direction: 'asc' },
      { field: 'order', direction: 'asc' },
    ];
  }
  if (withs.length === 0) {
    withs = ['project'];
  }
  const ins = create();
  return ins
    .get(API_URL.base + '/instruction/list', {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}

/**
 * 運行指示(ページネーション付き)
 * @returns
 */
export function getInstructionCards({
  page = 1,
  filter = {},
  order = [],
  withs = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiGetIndexProps) {
  if (order.length === 0) {
    order = [
      { field: 'pj_id', direction: 'asc' },
      { field: 'no', direction: 'asc' },
      { field: 'order', direction: 'asc' },
    ];
  }
  if (withs.length === 0) {
    withs = ['project'];
  }
  const ins = create();
  return ins
    .get(API_URL.base + '/instruction/card/index', {
      params: { page: page, filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess({
          data: res.data.data,
          currentPage: res.data.current_page,
          lastPage: res.data.last_page,
          filter: filter,
        });
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}

/**
 * 運行指示
 * @returns
 */
export function getInstructionCardList({
  filter = {},
  order = [],
  withs = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiListProps) {
  if (order.length === 0) {
    order = [
      { field: 'pj_id', direction: 'asc' },
      { field: 'no', direction: 'asc' },
      { field: 'order', direction: 'asc' },
    ];
  }
  if (withs.length === 0) {
    withs = ['project'];
  }
  const ins = create();
  return ins
    .get(API_URL.base + '/instruction/card/list', {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getInstructionCardList', res.data);
      if (callbackSuccess) {
        callbackSuccess({ data: res.data, filter: filter });
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}

/**
 * 運行指示新規登録
 * @returns
 */
export function storeInstructions(
  data: tInstruction[],
  callbackSuccess?: ((data: tInstruction[]) => void) | undefined,
  callbackError?: (() => void) | undefined
) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].transport_company) {
      delete data[i].transport_company;
    }
    if (data[i].driver) {
      delete data[i].driver;
    }
    if (data[i].project) {
      delete data[i].project;
    }
    if (data[i].start_trailer) {
      delete data[i].start_trailer;
    }
    if (data[i].end_trailer) {
      delete data[i].end_trailer;
    }
    if (data[i].vehicle) {
      delete data[i].vehicle;
    }
  }

  const ins = create();
  return ins
    .post(API_URL.base + '/instruction/stores', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの登録に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
      alert('データを登録しました');
      return;
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの登録に失敗しました');
    });
}

/**
 * 運行指示（仮）新規登録
 * @returns
 */
export function getInstruction(
  id: tInstruction['id'],
  callbackSuccess?: ((data: tInstruction) => void) | undefined,
  callbackError?: (() => void) | undefined
) {
  const ins = create();
  return ins
    .get(`${API_URL.base}/instruction/show/${id}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データ取得に成功しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
      return;
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}
/**
 * 運行指示更新
 * @param callbackSuccess
 * @param callbackError
 * @param id
 * @param data
 * @returns
 */
export function update(
  id: tInstruction['id'],
  data: any,
  callbackSuccess?: ((data: tInstruction) => void) | undefined,
  callbackError?: (() => void) | undefined
) {
  const ins = create();
  return ins
    .post(API_URL.base + `/instruction/update/${id}`, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの更新に失敗しました');
      }
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
      alert('データの更新に成功しました');
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの更新に失敗しました');
    });
}

/**
 * 運行指示マスタ（仮）新規登録
 * @returns
 */
export function destroyInstructions(
  data: tTransportKey,
  callbackSuccess: (data: tInstruction[]) => void,
  callbackError?: (() => void) | undefined
) {
  const ins = create();
  return ins
    .post(API_URL.base + '/instruction/destroys', data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの削除に失敗しました');
      }
      callbackSuccess(res.data);
      alert('データの削除に成功しました');
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの削除に失敗しました');
    });
}
