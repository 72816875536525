import React from 'react';
import { Box, Popover } from '@mui/material';

export const Toolchip = ({
  open,
  anchorEl,
  handleClose,
  children,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  children?: React.ReactNode;
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom', // カードの下に表示
        horizontal: 'center', // カードの中央に表示
      }}
      transformOrigin={{
        vertical: 'top', // メニューの上側を基準に
        horizontal: 'center', // メニューの中央を基準に
      }}
    >
      <Box sx={{ padding: 2 }}>{children}</Box>
    </Popover>
  );
};

export default Toolchip;
