import React, { useState, useEffect } from 'react';
import { tAddress } from 'types/address';
import { initAddress } from 'const/address';
import { useParams } from 'react-router-dom';
import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import {
  getAddress,
  storeAddress,
  updateAddress,
  deleteAddress,
} from 'functions/api/mst';
import { Console_log } from 'functions';
import { GridItemTextField } from 'components/GridItem';
import { PrimaryButton } from 'atoms/Button';
import * as ComponentAddress from 'components/mst/Address';

export default function Main() {
  const { id } = useParams();
  const [address, setAddress] = useState<tAddress>(initAddress);

  Console_log('id', id);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        // 住所データ取得
        getAddress(id).then((res) => {
          if (res.status !== 200)
            throw new Error('住所データ取得に失敗しました');
          Console_log('addresses.data', res.data);
          setAddress(res.data);
        });
      } catch (error) {
        alert('住所データ取得に失敗しました');
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  // 住所マスタ更新
  const setPostRequestUpdateAddress = () => {
    updateAddress(address)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        window.location.href = '/mst/address';
      })
      .catch((err) => {
        alert('データ更新に失敗しました');
        console.error(err);
      });
  };

  // 住所マスタ新規登録
  const setPostRequestStoreAddress = () => {
    storeAddress(address)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        window.location.href = '/mst/address';
      })
      .catch((err) => {
        alert('データ登録に失敗しました');
        console.error(err);
      });
  };

  const setPostRequestDeleteAddress = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteAddress(address.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        window.location.href = '/mst/address';
      })
      .catch((err) => {
        alert('データ削除に失敗しました');
        console.error(err);
      });
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton
                    onClick={setPostRequestUpdateAddress}
                    label="更新"
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton
                    onClick={setPostRequestDeleteAddress}
                    label="削除"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={2}>
                <PrimaryButton
                  onClick={setPostRequestStoreAddress}
                  label="登録"
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <GridItemTextField
          name={'name'}
          label="名称"
          value={address.name}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, name: e.target.value })
          }
        />

        <GridItemTextField
          name={'post_number'}
          label="郵便番号"
          value={address.post_number}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, post_number: e.target.value })
          }
        />
        <Grid item xs={12}></Grid>

        <ComponentAddress.Prefectures values={address} setValues={setAddress} />
        <ComponentAddress.City values={address} setValues={setAddress} />
        <ComponentAddress.Street values={address} setValues={setAddress} />
        <ComponentAddress.Building values={address} setValues={setAddress} />

        <Grid item xs={12}></Grid>

        <GridItemTextField
          name={'tell'}
          label="電話番号"
          value={address.tell}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, tell: e.target.value })
          }
        />

        <GridItemTextField
          name={'fax'}
          label="FAX"
          value={address.fax}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setAddress({ ...address, fax: e.target.value })
          }
        />

        <Grid item xs={12}></Grid>

        <Grid item xs={12} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name={'flg_load'}
                checked={address.flg_load}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAddress({ ...address, flg_load: e.target.checked })
                }
              />
            }
            label="積地"
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name={'flg_unload'}
                checked={address.flg_unload}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAddress({ ...address, flg_unload: e.target.checked })
                }
              />
            }
            label="卸地"
          />
        </Grid>

        <Grid item xs={12} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                name={'flg_office'}
                checked={address.flg_office}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setAddress({ ...address, flg_office: e.target.checked })
                }
              />
            }
            label="事務所"
          />
        </Grid>
      </Grid>
    </div>
  );
}
