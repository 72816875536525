import { tSelfInformation } from 'types/mst';

// 初期値の定義
export const initSelfInformation: tSelfInformation = {
  id: 0,
  domain: '',
  corporate_number: '',
  name: '',
  postal_code: '',
  address1: '',
  address2: '',
  tel: '',
  fax: '',
  billing_info: null,
  notes: null,
};
