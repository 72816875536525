import { getAddAnyDay } from 'functions/time';
import { tOrder } from 'types';

const initInstSearch = {
  key: null,
  pj_id: null,
  no: null,
  order: null,
  start_datetime_from: getAddAnyDay(-7, 0, 0),
  start_datetime_to: getAddAnyDay(7, 0, 0),
  end_datetime_from: '',
  end_datetime_to: '',
  tm_id: null,
  status: null,
  user_id: null,
  v_id: null,
  c_id: null,
};

export default initInstSearch;

export const initOrder: tOrder[] = [
  { field: 'start_datetime', direction: 'asc' },
  { field: 'tm_id', direction: 'asc' },
  { field: 'driver_id', direction: 'asc' },
  { field: 'c_id', direction: 'asc' },
  { field: 'end_datetime', direction: 'asc' },
  { field: 'pj_id', direction: 'asc' },
  { field: 'no', direction: 'asc' },
  { field: 'order', direction: 'asc' },
];
