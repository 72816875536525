import { tInstruction } from 'types/instruction';

const initInstruction: tInstruction = {
  id: 0,
  key: '',
  pj_id: 0,
  no: 0,
  order: null,
  tm_id: 1,
  status: 0,
  start_datetime: '',
  start_a_id: 0,
  start_name: '',
  start_prefectures: '',
  start_city: '',
  start_street: '',
  start_building: '',
  start_trailer_v_id: null,
  end_datetime: '',
  end_a_id: 0,
  end_name: '',
  end_prefectures: '',
  end_city: '',
  end_street: '',
  end_building: '',
  end_trailer_v_id: null,
  user_id: null,
  v_id: null,
  c_id: null,
  c_vehicle: null,
  price: 0,

  flg_picup: false,

  instruction_datetime: '',
  instruction_user_id: null,
  execute_datetime: '',
  execute_user_id: null,
  complete_datetime: '',
  complete_user_id: null,

  // リレーション
  transport_company: undefined,
  driver: undefined,
  vehicle: undefined,
  start_trailer: undefined,
  end_trailer: undefined,
};

export default initInstruction;
