import React from 'react';
import { tCompany } from 'types/company';
import * as cCommon from 'components/Common';
import { days as closingDays } from 'const/comapny/closingDate';
import { closingDateType } from 'const/comapny/closingDateType';

interface GridItemFieldProps {
  values: tCompany;
  setValues: React.Dispatch<React.SetStateAction<tCompany>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="会社名"
    name="name"
    value={values.name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Abbreviation = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="略称"
    name="abbreviation"
    value={values.abbreviation}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Tell = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="電話番号"
    name="tell"
    value={values.tell}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Fax = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="FAX"
    name="fax"
    value={values.fax}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const EMail = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="メールアドレス"
    name="e_mail"
    value={values.e_mail}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const PresidentPosition = ({
  values,
  setValues,
}: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="代表役職"
    name="president_position"
    value={values.president_position}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const PresidentName = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="代表者名"
    name="president_name"
    value={values.president_name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgUse = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="用者利用"
    name="flg_use"
    value={values.flg_use}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgOrder = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="受注元"
    name="flg_order"
    value={values.flg_order}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgSupplier = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="発注先"
    name="flg_supplier"
    value={values.flg_supplier}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const ClosingDate = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemSelect
    id={`closing_date`}
    label="締め日"
    labelId="closing_date-lbl"
    value={values.closing_date}
    items={closingDays()}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const ClosingDateType = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemSelect
    id={`closing_date_type`}
    label="基準日"
    labelId="closing_date_type-lbl"
    items={closingDateType}
    value={values.closing_date_type}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgBillingPerson = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemCheckbox
    label="担当者別に請求書を発行"
    name="flg_billing_person"
    value={values.flg_billing_person}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const InvoiceNo = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="インボイス番号"
    name="invoice_no"
    value={values.invoice_no}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Address1 = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="住所"
    name="address1"
    value={values.address1}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Address2 = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="建物名など"
    name="address2"
    value={values.address2}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const PostNumber = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="郵便番号"
    name="post_number"
    value={values.post_number}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);
