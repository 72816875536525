import React, { useState, useEffect } from 'react';
import PJDetail from 'components/project/Detail';
import Modal from 'atoms/Modal';
import {
  tProject,
  tWaypoints,
  initProject,
  initWaypoints,
} from 'types/project';
import * as PJButton from 'components/project/Button';
import * as InstButton from 'components/instruction/Button';
import { SecondButton } from 'atoms/Button';
import { getProject } from 'functions/api/project';
import { ThemeProviderCustom } from 'themes/Base';
import log from 'functions/logger';

interface MainProps {
  open: boolean;
  onClose: () => void;
  pjId: number;
  initCustom?: tProject;
  callbackNomal: () => void;
  callbackError?: (() => void) | undefined;
}

export default function Main({
  open,
  onClose,
  pjId,
  initCustom = initProject,
  callbackNomal,
  callbackError = undefined,
}: MainProps) {
  const [project, setProject] = useState<tProject>(initCustom);
  const [waypoints, setWaypoints] = useState<tWaypoints>(initWaypoints);

  useEffect(() => {
    if (pjId) {
      getProject(pjId)
        .then((res) => {
          setProject(res.data);
          setWaypoints(res.data.waypoints);
        })
        .catch((err) => {
          console.error(err);
          alert('データの取得に失敗しました');
        });
    } else {
      setProject(initCustom);
      setWaypoints(initCustom.waypoints);
    }
  }, [pjId, initCustom]);

  return (
    <ThemeProviderCustom>
      <Modal
        title={`案件情報`}
        open={open}
        onClose={onClose}
        actions={
          <>
            {pjId ? (
              <>
                <PJButton.Update
                  projectInfo={project}
                  waypoints={waypoints}
                  callbackNomal={callbackNomal}
                  callbackError={callbackError}
                />
                <InstButton.ModalInstruction pjId={pjId} />
              </>
            ) : (
              <>
                <PJButton.Insert
                  label={'登録'}
                  projectInfo={project}
                  waypoints={waypoints}
                  callbackNomal={callbackNomal}
                  callbackError={callbackError}
                />
                <PJButton.Insert
                  label={'続けて登録'}
                  projectInfo={project}
                  waypoints={waypoints}
                  callbackError={callbackError}
                />
              </>
            )}
            <SecondButton onClick={onClose} label={'閉じる'} />
          </>
        }
      >
        <PJDetail
          values={project}
          setValues={setProject}
          waypoints={waypoints}
          setWaypoints={setWaypoints}
        />
      </Modal>
    </ThemeProviderCustom>
  );
}
