import React, { useContext, useEffect, useState, useMemo } from 'react';
import { useSelection } from 'contexts/Schedules';
import { tTransportCard, tTransportKey } from 'types/transport';
import { tInstructionCard } from 'types/instruction';
import { MstDataContext } from 'contexts/Mst';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DragTransportCardArea } from 'components/TransportCard';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TableCell, TableRow } from '@mui/material';
import { tInstruction, tInstSearch } from 'types/instruction';
import {
  getCharWeekday,
  changeDateFromTypeDate,
  getCharWeekdayENG,
  isSameDate,
} from 'functions/time';
import instSearchDefault from 'const/instruction/search';
import Loading from 'atoms/Loading';
import * as InstCard from 'components/instruction/InstructionCard';
import InstrcutionThemeProvider from 'themes/InstructionTheme';
import initInstruction from 'const/instruction';
import InstructionModal from 'components/instruction/Modal';
import * as InstButton from 'components/instruction/Button';
import { ListItem } from 'types/index';
import { tTransportMethod } from 'types/mst';
import * as PJButton from 'components/project/Button';
import TableFrame from 'frames/TableFrame';
import log from 'functions/logger';
import { strDateTimeOrigin } from 'functions/time';
import { getInstructionCardList } from 'functions/api/instruction';
import { useDropHandler } from 'functions/instruction/handle';
import { searchList } from 'functions/instruction/index';
import { cbGetInstructionCardList } from 'types/instruction';

const ddType = 'TransportCard';

let glbSaerchInst: tInstSearch = {
  ...instSearchDefault,
};

export default function Main() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { transportCards } = useSelection();
  const transports: tTransportKey[] = transportCards.map((card) => {
    return { pj_id: card.pj_id, no: card.tran_no };
  });
  const [instructions, setInstructions] = useState<tInstructionCard[]>([]);
  const [searchInst, setSearchInst] = useState<tInstSearch>(instSearchDefault);
  const [trigger, setTrigger] = useState('');

  const [openInstModal, setOpenInstModal] = useState(false);
  const [dropInst, setDropInst] = useState<tInstruction>(initInstruction);

  const handleDrop = (item: tInstruction) => {
    // 運行指示入力モーダルを表示
    log.debug('drop', item);
    setDropInst(item);
    setOpenInstModal(true);
  };

  const closeInstModal = () => {
    setOpenInstModal(false);
    setTrigger(new Date().getTime().toString());
  };

  useEffect(() => {
    if (!openInstModal) {
      setDropInst(initInstruction);
    }
  }, [openInstModal]);

  useEffect(() => {
    glbSaerchInst = searchInst;
  }, [searchInst]);

  const callbackGetList = ({ data, filter }: cbGetInstructionCardList) => {
    setInstructions(data);
    setSearchInst(filter);
  };

  const getList = () => {
    searchList(searchInst, [], [], callbackGetList);
  };

  useEffect(() => {
    getList();
  }, [trigger, searchInst]);

  return (
    <DndProvider backend={HTML5Backend}>
      <InstrcutionThemeProvider>
        <TableFrame
          HeadContent={
            <Buttons setSearchInst={setSearchInst} trigger={trigger} />
          }
          TableHeaderRows={<HeadData />}
          TableBodyRows={
            <BodyData
              data={instructions}
              terms={searchInst}
              handleDrop={handleDrop}
              setInstructions={setInstructions}
            />
          }
          SubContent={
            <DragTransportCardArea
              ddType={ddType}
              transportCards={transportCards}
              optionNode={
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate('/full-screen/schedules');
                  }}
                >
                  予定表へ
                </Button>
              }
            />
          }
        />
      </InstrcutionThemeProvider>

      <InstructionModal
        open={openInstModal}
        onClose={closeInstModal}
        tranKey={{ pj_id: dropInst?.pj_id || 0, no: dropInst?.no || 0 }}
        info={dropInst}
        callbackNomal={() => {
          closeInstModal();
          // 表示するtmpデータに追加する
        }}
      />
    </DndProvider>
  );
}

interface ButtonsProps {
  setSearchInst: React.Dispatch<React.SetStateAction<tInstSearch>>;
  trigger: string;
}
const Buttons = ({ setSearchInst, trigger }: ButtonsProps) => {
  return (
    <>
      <InstButton.ShowSearchModal
        callbackSearch={(data: tInstSearch) => {
          setSearchInst(data);
        }}
        trigger={trigger}
      />
    </>
  );
};

//interface DataCellProps {}
const HeadData = () => {
  const { drivers, tranMethods, SYSTEM } = useContext(MstDataContext);
  return (
    <TableRow>
      <TableCell className="day">日付</TableCell>
      {drivers?.map((driver) => {
        return (
          <TableCell key={`head-driver-${driver.id}`} className="driver">
            {driver.label}
          </TableCell>
        );
      })}
      {tranMethods
        ?.filter((tm) => tm.id !== SYSTEM?.tranMethod.own.id)
        .map((tm) => {
          return (
            <TableCell key={`head-tranMethod-${tm.id}`} className="driver">
              {tm.abbreviation}
            </TableCell>
          );
        })}
    </TableRow>
  );
};

interface BodyDataProps {
  data: tInstructionCard[];
  terms: tInstSearch;
  handleDrop: (item: tInstruction) => void;
  setInstructions: React.Dispatch<React.SetStateAction<tInstructionCard[]>>;
}
const BodyData = ({
  data,
  terms,
  handleDrop,
  setInstructions,
}: BodyDataProps) => {
  // 行ヘッダの日付
  const dateRange: string[] = useMemo(() => {
    const startDate = new Date(terms.start_datetime_from || '');
    const endDate = new Date(terms.start_datetime_to || '');
    const dates: string[] = [];

    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      dates.push(changeDateFromTypeDate(new Date(date))); // YYYY-MM-DD形式
    }

    return dates;
  }, [terms.start_datetime_from, terms.start_datetime_to]);

  return (
    <>
      {dateRange.map((date) => {
        const rowData = data.filter((inst) => {
          return isSameDate(inst.start_datetime, date);
        });
        return (
          <DayRow
            key={`dayrow-${date}`}
            day={date}
            data={rowData}
            callbackDrop={handleDrop}
            flgOdd={dateRange.indexOf(date) % 2 === 0}
            setInstructions={setInstructions}
          />
        );
      })}
    </>
  );
};

const DayRow = ({
  day,
  data,
  callbackDrop,
  flgOdd,
  setInstructions,
}: {
  day: string;
  data: tInstructionCard[];
  callbackDrop: (item: tInstruction) => void;
  flgOdd: boolean;
  setInstructions: React.Dispatch<React.SetStateAction<tInstructionCard[]>>;
}) => {
  const weekdayJP = getCharWeekday(day); // 曜日
  const weekdayENG = getCharWeekdayENG(day); // 曜日(英語)

  const { loading, drivers, tranMethods, SYSTEM } = useContext(MstDataContext);

  const DataCellDriver = ({
    driver,
    driverData,
    day,
    callbackDrop,
  }: {
    driver: ListItem;
    driverData: tInstructionCard[];
    day: string;
    callbackDrop: (item: tInstruction) => void;
  }) => {
    const { isOver, dropRef } = useDropHandler(day, callbackDrop, {
      user_id: driver.id,
      tm_id: SYSTEM?.tranMethod.own.id || 1,
    });

    return (
      <TableCell
        className={`${driver.label} ${isOver ? 'isOver' : ''}`}
        ref={dropRef}
        sx={{
          ...(isOver && { backgroundColor: 'rgba(0, 128, 0, 0.2)' }), // ドロップ中の視覚的な変化を追加
        }}
      >
        <Box>
          {driverData.map((inst: tInstructionCard) => {
            return (
              <InstCardWithToolchip
                key={`inst-${inst.id}`}
                inst={inst}
                setInstructions={setInstructions}
              />
            );
          })}
        </Box>
      </TableCell>
    );
  };

  const DataCellMethod = ({
    method,
    methodData,
    day,
    callbackDrop,
  }: {
    method: tTransportMethod;
    methodData: tInstructionCard[];
    day: string;
    callbackDrop: (item: tInstruction) => void;
  }) => {
    const { isOver, dropRef } = useDropHandler(day, callbackDrop, {
      tm_id: method.id,
    });

    return (
      <TableCell
        className={`head-tranMethod-${method.id} ${isOver ? 'isOver' : ''}`}
        ref={dropRef}
        sx={{
          ...(isOver && { backgroundColor: 'rgba(0, 128, 0, 0.2)' }), // ドロップ中の視覚的な変化を追加
        }}
      >
        <Box>
          {methodData.map((inst: tInstructionCard) => {
            return (
              <InstCardWithToolchip
                key={`inst-${inst.id}`}
                inst={inst}
                setInstructions={setInstructions}
              />
            );
          })}
        </Box>
      </TableCell>
    );
  };

  return (
    <TableRow className={`${weekdayENG} ${flgOdd ? 'odd' : 'even'}`}>
      <TableCell variant="head" className="day">
        <Box sx={{ minHeight: '40px', display: 'flex', flexFlow: 'column' }}>
          <Typography>{`${day}(${weekdayJP})`}</Typography>
          <Loading flg={loading || !drivers ? true : false} />
        </Box>
      </TableCell>
      {drivers?.map((driver) => {
        const driverData = data.filter((inst) => {
          return inst.driver_id === driver.id;
        });
        return (
          <DataCellDriver
            key={`head-driver-${driver.id}`}
            driver={driver}
            driverData={driverData}
            day={day}
            callbackDrop={callbackDrop}
          />
        );
      })}

      {tranMethods
        ?.filter((tm) => tm.id !== SYSTEM?.tranMethod.own.id)
        .map((tm) => {
          const methodData = data.filter((inst) => {
            return inst.tm_id === tm.id;
          });
          return (
            <DataCellMethod
              key={`head-tranMethod-${tm.id}`}
              method={tm}
              methodData={methodData}
              day={day}
              callbackDrop={callbackDrop}
            />
          );
        })}
    </TableRow>
  );
};

const InstCardWithToolchip = ({
  inst,
  setInstructions,
}: {
  inst: tInstructionCard;
  setInstructions: React.Dispatch<React.SetStateAction<tInstructionCard[]>>;
}) => {
  const [trigger, setTrigger] = useState('');

  return (
    <InstCard.WithToolchip
      className={''}
      type="line"
      labelType="driver"
      key={`inst-${inst.id}`}
      instruction={inst}
      flgOmit={false}
      toolchipChildren={ToolChips(
        inst.pj_id || 0,
        inst.id || 0,
        inst.status || 0,
        setInstructions
      )}
      trigger={trigger}
    />
  );
};

const ToolChips = (
  pjId: number,
  instId: tInstruction['id'],
  status: tInstruction['status'],
  setInstructions: React.Dispatch<React.SetStateAction<tInstructionCard[]>>
) => {
  const { SYSTEM, loginUser } = useContext(MstDataContext);
  const nowDate = strDateTimeOrigin(new Date());

  const callbackSuccess = () => {
    // 更新後の処理
    searchList(glbSaerchInst, [], [], ({ data }) => {
      if (data.length > 0) {
        setInstructions(data);
      } else {
        setInstructions([]);
      }
    });
  };

  if (!SYSTEM) return null;
  return (
    <>
      <PJButton.Detail pj_id={pjId || 0} label={'案件詳細'} />
      {status < SYSTEM.instruction.status.InTransit && (
        <InstButton.Update
          label="開始"
          id={instId}
          data={{
            status: SYSTEM.instruction.status.InTransit,
            instruction_datetime: nowDate,
            instruction_user_id: loginUser.id,
            execute_datetime: nowDate,
            execute_user_id: loginUser.id,
          }}
          callbackSuccess={callbackSuccess}
        />
      )}
      {status >= SYSTEM.instruction.status.InTransit &&
        status < SYSTEM.instruction.status.Instructed && (
          <>
            <InstButton.Update
              label="完了"
              id={instId}
              data={{
                status: SYSTEM.instruction.status.Instructed,
                complete_datetime: nowDate,
                complete_user_id: loginUser.id,
              }}
              callbackSuccess={callbackSuccess}
            />
          </>
        )}
    </>
  );
};
