import React, { useState, useEffect } from 'react';
import { tRole, initialRole } from 'types/mst';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { getRole, storeRole, updateRole, deleteRole } from 'functions/api/mst';
import { Console_log } from 'functions';
import { PrimaryButton } from 'atoms/Button';
import * as cRole from 'components/mst/Role';
import log from 'functions/logger';

export default function Main() {
  const { id } = useParams();
  const [role, setRole] = useState<tRole>(initialRole);

  Console_log('id', id);

  const getSearch = async (id: number) => {
    try {
      // 住所データ取得
      getRole(id).then((res) => {
        if (res.status !== 200) throw new Error('データ取得に失敗しました');
        Console_log('role.data', res.data);
        setRole(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getSearch(Number(id));
    }
  }, [id]);

  // 更新
  const setPostRequestUpdateRole = () => {
    updateRole(role)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        window.location.href = '/mst/role';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 住所マスタ新規登録
  const setPostRequestStoreRole = () => {
    storeRole(role)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        window.location.href = '/mst/role';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setPostRequestDeleteRole = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteRole(role.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        window.location.href = '/mst/role';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  log.debug('role', role);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton
                    onClick={setPostRequestUpdateRole}
                    label="更新"
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton
                    onClick={setPostRequestDeleteRole}
                    label="削除"
                  />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStoreRole} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>

        <cRole.Name values={role} setValues={setRole} />

        <cRole.FlgAdmin values={role} setValues={setRole} />
        <cRole.FlgProject values={role} setValues={setRole} />
        <cRole.FlgOperation values={role} setValues={setRole} />
        <cRole.FlgPaperwork values={role} setValues={setRole} />
        <cRole.FlgMst values={role} setValues={setRole} />
        <cRole.FlgDriver values={role} setValues={setRole} />
      </Grid>
    </div>
  );
}
