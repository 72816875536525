import { typeHashValue, typeSetIndexTable, typeSetRowTable } from 'types/index';

/**
 * テーブル型の入力フォームの変更を処理する関数
 * @param value
 * @param rowField
 * @param columnField
 * @param setState
 */
export const handleIndexTableChange = (
  value: typeHashValue,
  rowField: number,
  columnField: string,
  setState: typeSetIndexTable
) => {
  setState((prev) => ({
    ...prev,
    [rowField]: { ...prev[rowField], [columnField]: value },
  }));
};

/**
 * テーブル型の入力フォームの変更を処理する関数
 * @param value
 * @param rowValues
 * @param setState
 * @param field
 */
export const handleRowTableChange = (
  value: typeHashValue,
  rowField: number,
  columnField: string,
  setState: typeSetRowTable
) => {
  setState((prev) => ({
    ...prev,
    [rowField]: { ...prev[rowField], [columnField]: value },
  }));
};

/**
 * Hash型の入力フォームの変更を処理する関数
 * @param value
 * @param state
 * @param setState
 */
export const handleChangeValues = (
  value: any,
  name: string | number,
  setState: any
) => {
  setState((prev: any) => ({ ...prev, [name]: value }));
};
