import { create, API_URL } from 'functions/axios';
import { tProject, tWaypoints } from 'types/project';
import { typeHash } from 'types';

export function getTransports(page: number, filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'pj_no', direction: 'asc' },
      { field: 'no', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/index', {
    params: { page: page, filter: filter, order: order },
  });
}

export function getTransportList(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'pj_id', direction: 'asc' },
      { field: 'no', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/list', {
    params: { filter: filter, order: order },
  });
}

export function getTransport(id: number) {
  const ins = create();
  return ins.get(`${API_URL.base}/transport/show/${id}`, {});
}

export function getTransportByParams(
  pj_id: number | undefined,
  no: number | undefined
) {
  const ins = create();
  return ins.get(API_URL.base + '/transport/show/none', {
    params: { pj_id: pj_id, no: no },
  });
}

/**
 * 予定表案件情報取得
 * @returns
 */
export function getTransportCards(filter: any, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: 'load_datetime', direction: 'asc' },
      { field: 'pj_id', direction: 'asc' },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + '/transport/card/list', {
    params: { filter: filter, order: order },
  });
}

/**
 * 予定表案件情報取得
 * @returns
 */
export function getTransportCard(pj_id: number, tran_no: number) {
  const ins = create();
  return ins.get(`${API_URL.base}/transport/card/show/${pj_id}/${tran_no}`);
}
