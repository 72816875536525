import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import { menus } from 'const/menu';
import { useLocation } from 'react-router-dom';
import { MstDataContext } from 'contexts/Mst';
import { tUser } from 'types/mst';
import { Link as RouterLink } from 'react-router-dom';
import LoadingCircular from 'atoms/Loading';

export default function Main() {
  const theme = useTheme(); // useThemeをコンポーネントの中で呼び出す
  const location = useLocation();
  const { loading, loginUser } = useContext(MstDataContext);

  const styles = {
    headerContainer: {
      width: '100%',
      height: '40px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    headerMenu: {
      height: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'flex-start', // 左寄せに変更
      gap: theme.spacing(1), // リストアイテム間のスペースを追加（必要に応じて調整）
      padding: '0 16px', // 左右にパディングを追加（必要に応じて調整）
    },
  };

  if (loading || !loginUser) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <header style={styles.headerContainer}>
      <List sx={styles.headerMenu}>
        <ListItemButton component={Link} href="/">
          <ListItemIcon>
            <img
              src={`${process.env.PUBLIC_URL}/nmr_logo.png`}
              alt={'メインロゴ'}
              width={24}
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                height: '40px',
                width: 'auto',
              }}
            />
          </ListItemIcon>
        </ListItemButton>
        {menus.map((menu) => {
          const isSubPage = location.pathname.startsWith(`/${menu.path}`);
          return (
            <ListItemButton
              key={menu.path}
              component={RouterLink}
              to={`/${menu.path}`}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                if (menu.aTab) {
                  e.preventDefault();
                  window.location.href = `/${menu.path}`;
                }
              }}
            >
              <ListItemText
                primary={
                  menu.path === 'system' && loginUser
                    ? `${loginUser['family_name']} ${loginUser['given_name']}`
                    : menu.label
                }
                primaryTypographyProps={{
                  color: theme.palette.text.secondary,
                  fontSize: '1rem',
                  borderBottom: isSubPage ? '2px solid' : 'none',
                }}
              />
            </ListItemButton>
          );
        })}
      </List>
    </header>
  );
}

const LinkHref = ({
  menu,
  loginUser,
  isSubPage,
}: {
  menu: any;
  loginUser: tUser;
  isSubPage: boolean;
}) => {
  return (
    <ListItemButton key={menu.path} component={RouterLink} to={`/${menu.path}`}>
      <ListItemText
        primary={
          menu.path === 'system' && loginUser
            ? loginUser['family_name']
            : menu.label
        }
        primaryTypographyProps={{
          borderBottom: isSubPage ? '2px solid' : 'none',
        }}
      />
    </ListItemButton>
  );
};
