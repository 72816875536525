import { tAddress } from 'types/address';

export type tUser = {
  id: number;
  email: string;
  password: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
  birthday: string;
  tell: string;
  post_number: string;
  address: string;
  role_id: number;
  v_id: number | null;
  hire_date: string;

  // ミューテータ
  full_name: string;

  // リレーション
  role?: tRole;
  vehicle?: tVehicle;
};

export const initialUser: tUser = {
  id: 0,
  email: '',
  password: '',
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  family_name: '',
  given_name: '',
  family_name_kana: '',
  given_name_kana: '',
  birthday: new Date().toISOString().split('T')[0],
  tell: '',
  post_number: '',
  address: '',
  role_id: 0,
  v_id: null,
  hire_date: new Date().toISOString().split('T')[0],
  full_name: '',
};

export type tRole = {
  id: number;
  name: string;
  admin: boolean;
  project: boolean;
  operation: boolean;
  paperwork: boolean;
  mst: boolean;
  driver: boolean;
};
export const initialRole: tRole = {
  id: 0,
  name: '',
  admin: false,
  project: false,
  operation: false,
  paperwork: false,
  mst: false,
  driver: false,
};

export type tCarModel = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  name: string;
  car_weight: number;
  max_load_weight: number;
  flg_license_nomal: boolean;
  flg_license_semimediu: boolean;
  flg_license_mediu: boolean;
  flg_license_large: boolean;
  flg_license_speial: boolean;
};

// types/mst.ts

export type tVehicle = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  plate: string;
  plate_branch: string;
  plate_kind: string;
  plate_hiragana: string;
  plate_number: string;
  vt_id: number;
  inspection: Date;

  // リレーション
  vehicle_type?: tVehicleType;
};
// 初期値の定義
export const initialVehicle: tVehicle = {
  id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  plate: '',
  plate_branch: '',
  plate_kind: '',
  plate_hiragana: '',
  plate_number: '',
  vt_id: 0,
  inspection: new Date(),
};

// 車両種マスタ
export interface tVehicleType {
  id: number;
  name: string;
  description: string;
  cmId?: number | null;
  flg_trailer: boolean;
}
export const initialVehicleType: tVehicleType = {
  id: 0,
  name: '',
  description: '',
  cmId: null,
  flg_trailer: false,
};

// types/mst.ts

export type tTax = {
  id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  label: string;
  tax: number;
  flg: boolean;
};

// types/mst.ts

export type tLuggage = {
  id: number;
  /*
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  */
  name: string;
  unit: string;
  volume: number;
  cm_ids: [];
};

export type tProjectKind = {
  id: number;
  domain: string;
  name: string;
  color: string;
  created_at: Date; // or Date if you will convert to Date type in your code
  updated_at: Date; // or Date if you will convert to Date type in your code
  deleted_at?: Date | null; // optional field for soft deletes
};
export const initialProjectKind: tProjectKind = {
  id: 0,
  domain: '',
  name: 'その他',
  color: '', // A sample color code
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
};

export type tPerson = {
  id: number;
  c_id: number;
  family_name: string;
  given_name: string;
  family_name_kana: string;
  given_name_kana: string;
  tell: string;
  email: string;
  post_number: string;
  address1: string;
  address2: string;
  office_a_id: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
};

// 初期値の定義
export const initialPerson: tPerson = {
  id: 0,
  c_id: 0,
  family_name: '',
  given_name: '',
  family_name_kana: '',
  given_name_kana: '',
  tell: '',
  email: '',
  post_number: '',
  address1: '',
  address2: '',
  office_a_id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
};

export type tSelfInformation = {
  id: number; // ID
  domain: string; // ドメイン
  corporate_number: string; // 法人番号
  name: string; // 会社名
  postal_code: string; // 郵便番号
  address1: string; // 住所1
  address2: string; // 住所2
  tel: string; // 電話番号
  fax: string; // FAX番号
  billing_info?: string | null; // 請求先情報（銀行情報含む）, nullの場合もあるのでオプションにしています
  notes?: string | null; // 備考欄, nullの場合もあるのでオプションにしています
};

/**
 * 輸送手段マスタ
 */
export type tTransportMethod = {
  id: number; // ID
  domain: string; // ドメイン
  name: string; // 名称
  abbreviation: string; // 略称
  name_chip: string; // チップ名
  start_time: string | null; // 開始時刻（ISO文字列形式）
  start_a_id: tAddress['id'] | null; // 開始：住所ID
  start_name: tAddress['name']; // 開始：名称
  start_prefectures: tAddress['prefectures']; // 開始：都道府県
  start_city: tAddress['city']; // 開始：市区町村
  start_street: tAddress['street']; // 開始：番地
  start_building: tAddress['building']; // 開始：建屋
  end_time: string | null; // 終了時刻（ISO文字列形式）
  end_a_id: tAddress['id']; // 終了：住所ID
  end_name: tAddress['name']; // 終了：名称
  end_prefectures: tAddress['prefectures']; // 終了：都道府県
  end_city: tAddress['city']; // 終了：市区町村
  end_street: tAddress['street']; // 終了：番地
  end_building: tAddress['building']; // 終了：建屋
};
