import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'atoms/Modal';
import { SecondButton } from 'atoms/Button';
import SheetInvoice, { PDFDownload, PDFPreview } from 'sheets/invoice/type01';
import { tInvoice } from 'types/invoice';
import { getInvoice } from 'functions/api/paperwork';
import { MstDataContext } from 'contexts/Mst';
import Loading from 'atoms/Loading';
import log from 'functions/logger';
import { initInvoice } from 'const/invoice';

interface MainProps {
  sheetName: string;
  invoiceId: tInvoice['id'];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function Main({
  sheetName,
  invoiceId,
  open,
  setOpen,
}: MainProps) {
  const navigate = useNavigate();
  const [invoice, setInvoice] = useState<tInvoice>(initInvoice);
  const { selfInfomation } = useContext(MstDataContext);

  const callbackGetData = (data: tInvoice) => {
    setInvoice(data);
  };
  useEffect(() => {
    if (!invoiceId) return;
    log.debug('invoiceId', invoiceId);
    getInvoice(invoiceId, callbackGetData);
  }, [invoiceId]);

  if (!selfInfomation) {
    return <Loading flg={true} />;
  }
  return (
    <Modal
      width={'xl'}
      open={open}
      onClose={() => setOpen(false)}
      title={'sheetName'}
      actions={
        <>
          <SecondButton
            onClick={() => {
              setOpen(false);
            }}
            label="閉じる"
          />
          <PDFDownload
            invoice={invoice}
            details={invoice?.details || []}
            mycompany={selfInfomation}
            name={sheetName}
          />
        </>
      }
    >
      <PDFPreview
        invoice={invoice}
        details={invoice?.details || []}
        mycompany={selfInfomation}
      />
    </Modal>
  );
}
