import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import * as PComponent from 'components/project/Index';
import * as PWComponent from 'components/project/Waypoint';
import { tProject, tWaypoints } from 'types/project';
import { getProject } from 'functions/api/project';
import { initProject, initialWaypoint } from 'types/project';

interface MainProps {
  values: tProject;
  setValues: React.Dispatch<React.SetStateAction<tProject>>;
  waypoints: tWaypoints;
  setWaypoints: React.Dispatch<React.SetStateAction<tWaypoints>>;
}

export default function Main({
  values,
  setValues,
  waypoints,
  setWaypoints,
}: MainProps) {
  return (
    <Grid container spacing={2}>
      <PComponent.ClientCompany values={values} setValues={setValues} />
      <PComponent.Person values={values} setValues={setValues} />

      <PComponent.Status values={values} setValues={setValues} />
      <PComponent.Kind values={values} setValues={setValues} />
      <PComponent.VehicleType values={values} setValues={setValues} />
      {/* <PComponent.Cars values={values} setValues={setValues} /> */}
      {/* memo:指示書送付済みフラグを追加 */}

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <PWComponent.WaypointsTable
            label="積地・卸地"
            values={waypoints}
            setValues={setWaypoints}
          />
        </Grid>
      </Grid>

      <PComponent.Weight values={values} setValues={setValues} />
      <PComponent.PriceUnit values={values} setValues={setValues} />
      {/*}
      <PComponent.Tax values={values} setValues={setValues} />
      <PComponent.PriceTax values={values} setValues={setValues} />
      <PComponent.PriceTaxInclude values={values} setValues={setValues} />
      */}
      <PComponent.LuggageID values={values} setValues={setValues} />
      <PComponent.InCharge values={values} setValues={setValues} />
      <Grid item xs={12}></Grid>
      <PComponent.Memo values={values} setValues={setValues} />

      <PComponent.InternalMemo values={values} setValues={setValues} />
    </Grid>
  );
}

interface ViewProps {
  pjId: number;
}

/**
 * 表示用
 * @param param0
 * @returns
 */
export function View({ pjId }: ViewProps) {
  const [values, setValues] = useState<tProject>(initProject);
  const [waypoints, setWaypoints] = useState<tWaypoints>([initialWaypoint]);

  useEffect(() => {
    // プロジェクト情報取得
    getProject(pjId)
      .then((res) => {
        setValues(res.data);
        setWaypoints(res.data.waypoints);
      })
      .catch((err) => {
        console.error(err);
        alert('データの取得に失敗しました');
      });
  }, [pjId]);

  if (!values || !setValues) return null;
  if (!waypoints || !setWaypoints) return null;

  return (
    <Main
      values={values}
      setValues={setValues}
      waypoints={waypoints}
      setWaypoints={setWaypoints}
    />
  );
}
