import { tCompany, tCompanySearch } from 'types/company';
export const initCompany: tCompany = {
  id: 0,
  created_at: new Date(),
  updated_at: new Date(),
  deleted_at: null,
  name: '',
  abbreviation: '',
  office_a_id: 0,
  post_number: '',
  tell: '',
  fax: '',
  e_mail: '',
  president_position: '',
  president_name: '',
  flg_use: false,
  flg_order: false,
  flg_supplier: false,
  closing_date: 31,
  closing_date_type: 2,
  flg_billing_person: false,
  invoice_no: '',
  address1: '',
  address2: '',
};

export const initCompanySearch: tCompanySearch = {
  freewords: '',
};
