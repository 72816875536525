import React, { Component, ReactNode } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  PDFViewer,
  usePDF,
} from '@react-pdf/renderer';
import { tInvoice, tInvoiceDetail } from 'types/invoice';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import { CustomText, EmphaText } from 'components/react-pdf/Text';
import { strDateTimeOrigin, formatMonthDay } from 'functions/time';
import { Button } from '@mui/material';
import { truncateString } from 'functions/index';
import { tSelfInformation } from 'types/mst';
import { formatNumber } from 'functions/index';

interface Props {
  invoice: tInvoice;
  details: tInvoiceDetail[];
  mycompany: tSelfInformation;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>PDF作成に失敗しました</div>;
    }

    return this.props.children;
  }
}

// ダウンロードリンクのコンポーネント
export const PDFDownload = ({
  invoice,
  details,
  mycompany,
  name,
}: Props & { name: string }) => {
  const [instance] = usePDF({
    document: (
      <Main invoice={invoice} details={details} mycompany={mycompany} />
    ),
  });

  if (instance.loading) return <SecondButton label="作成中..." />;

  if (instance.error) {
    return (
      <Button variant="text" color="error">
        PDF作成に失敗しました
      </Button>
    );
  }

  return (
    <ErrorBoundary>
      <div>
        <PDFDownloadLink
          document={
            <Main invoice={invoice} details={details} mycompany={mycompany} />
          }
          fileName={name}
        >
          <PrimaryButton label={'ダウンロード'} />
        </PDFDownloadLink>
      </div>
    </ErrorBoundary>
  );
};

// PDFビューアコンポーネント
export const PDFPreview = ({ invoice, details, mycompany }: Props) => (
  <ErrorBoundary>
    <PDFViewer width="100%" height="600px">
      <Main invoice={invoice} details={details} mycompany={mycompany} />
    </PDFViewer>
  </ErrorBoundary>
);

export default function Main({ invoice, details, mycompany }: Props) {
  return (
    <ErrorBoundary>
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          <Header />
          <Amont invoice={invoice} />
          <Invoice invoice={invoice} mycompany={mycompany} />
          <Details details={details} />
          <Footer invoice={invoice} />
        </Page>
      </Document>
    </ErrorBoundary>
  );
}

const Header = () => {
  return (
    <View style={styles.header} fixed>
      <CustomText style={{ textAlign: 'right' }}>
        {strDateTimeOrigin(new Date())}
      </CustomText>
    </View>
  );
};

const Invoice = ({
  invoice,
  mycompany,
}: {
  invoice: tInvoice;
  mycompany: tSelfInformation;
}) => {
  return (
    <View style={styles.invoiceContainer}>
      <View style={styles.invoiceSection}>
        <CustomText>{`〒${invoice.c_postal_code}`}</CustomText>
        <CustomText>{`${invoice.c_address1}`}</CustomText>
        {invoice.c_address2 && (
          <CustomText>{`${invoice.c_address2}`}</CustomText>
        )}
        <View
          style={{
            marginTop: 8,
            marginLeft: 8,
            flexDirection: 'row', // flexFlowの代わりにflexDirectionを使用
            flexWrap: 'nowrap', // 必要に応じて追加
            justifyContent: 'space-between', // 配置を調整
            alignItems: 'center', // 縦方向の配置を調整
          }}
        >
          <EmphaText>{invoice.c_name}</EmphaText>
          {invoice.pj_in_charge_name && (
            <CustomText>{invoice.pj_in_charge_name}</CustomText>
          )}
          <EmphaText>御中</EmphaText>
        </View>
        <CustomText>{invoice.pj_in_charge_name}</CustomText>
      </View>
      <View style={styles.invoiceSection}>
        <CustomText style={styles.invoiceTitle}>請求書</CustomText>
        <CustomText
          style={{ textAlign: 'center' }}
        >{`${invoice.title}`}</CustomText>
      </View>
      <View style={{ ...styles.invoiceSection, marginTop: '50px' }}>
        <CustomText>{mycompany.name}</CustomText>
        <CustomText>{`〒${mycompany.postal_code}`}</CustomText>
        <CustomText>{mycompany.address1}</CustomText>
        <CustomText>{`(TEL)${mycompany.tel}`}</CustomText>
        <CustomText>{`(FAX)${mycompany.fax}`}</CustomText>
        <CustomText>{`${mycompany.billing_info}`}</CustomText>
      </View>
    </View>
  );
};

const Footer = ({ invoice }: { invoice: tInvoice }) => {
  return (
    <View style={styles.footer} fixed>
      <Text>{invoice.invoice_number}</Text>
    </View>
    /*
    <Text
        render={({ pageNumber, totalPages }) =>
          `Page ${pageNumber} of ${totalPages}`
        }
      />*/
  );
};

const Amont = ({ invoice }: { invoice: tInvoice }) => {
  return (
    <View
      style={[
        styles.invoiceContainer,
        styles.tableRow,
        {
          position: 'absolute',
          top: '150px',
          left: '30px',
          width: '50%',
        },
      ]}
    >
      <View style={{ width: '30%' }}>
        <CustomText>請求金額</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(invoice.billing_amount.toString())}
        </CustomText>
      </View>
      <View style={{ width: '31%' }}>
        <CustomText>消費税</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(invoice.tax.toString())}
        </CustomText>
      </View>
      <View style={{ width: '31%' }}>
        <CustomText>合計金額</CustomText>
        <CustomText style={{ textAlign: 'right' }}>
          {formatNumber(invoice.total_amount.toString())}
        </CustomText>
      </View>
    </View>
  );
};

const Details = ({ details }: { details: tInvoiceDetail[] }) => {
  return (
    <View style={styles.detailsContainer}>
      <View style={styles.tableRow}>
        <CustomText style={styles.tableCell}>日付</CustomText>
        <CustomText style={styles.tableCell}>発地</CustomText>
        <CustomText style={styles.tableCell}>着地</CustomText>
        <CustomText style={styles.tableCell}>品名</CustomText>
        <CustomText style={styles.tableCell}>輸送量</CustomText>
        <CustomText style={styles.tableCell}>金額</CustomText>
        <CustomText style={styles.tableCell}>備考</CustomText>
        <CustomText style={styles.tableCell}>管理番号</CustomText>
      </View>
      {details.map((detail, index) => (
        <View key={index} style={styles.tableRow}>
          <CustomText style={styles.tableCell}>
            {`${formatMonthDay(new Date(detail.date))}`}
          </CustomText>
          <CustomText style={styles.tableCell}>
            {truncateString(detail.load_name || '', 5)}
          </CustomText>
          <CustomText style={styles.tableCell}>
            {truncateString(detail.unload_name || '', 5)}
          </CustomText>
          <CustomText style={styles.tableCell}>
            {truncateString(detail.luggage_name || '', 5)}
          </CustomText>
          <CustomText style={[styles.tableCell, { textAlign: 'right' }]}>
            {`${
              detail.transport_quantity
                ? formatNumber(detail.transport_quantity.toString())
                : ''
            }${detail.transport_unit || ''}`}
          </CustomText>
          <CustomText style={[styles.tableCell, { textAlign: 'right' }]}>
            {`${formatNumber(
              (
                detail.unit_price * detail.quantity +
                detail.extra_amount
              ).toString()
            )}`}
          </CustomText>
          <CustomText style={[styles.tableCell, { textAlign: 'left' }]}>
            {truncateString(detail.memo || '', 5)}
          </CustomText>
          <CustomText style={styles.tableCell}>{`${
            detail.pj_id || ''
          }`}</CustomText>
        </View>
      ))}
    </View>
  );
};

// スタイルシート
const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    position: 'relative',
    width: '100%',
  },
  header: {
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    padding: 10,
    top: 0,
    left: 0,
  },
  invoiceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%',
  },
  invoiceSection: {
    width: '32%',
  },
  invoiceTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  detailsContainer: {
    marginTop: 15,
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    borderBottom: '1px solid black',
    padding: 5,
  },
  tableCell: {
    width: '14%',
    textAlign: 'center',
    overflow: 'hidden',
    paddingLeft: 5,
    paddingRight: 5,
  },
  footer: {
    marginTop: 20,
    textAlign: 'right',
    position: 'absolute',
    bottom: 10,
    right: 10,
    width: '100%',
  },
});
