import React, { useState } from 'react';
import { Console_log } from 'functions/index';
import { useNavigate, useLocation } from 'react-router-dom';
import * as axiosCustom from 'functions/axios';
import * as env from 'functions/env';
import LoginFrame from 'frames/LoginFrame';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { menus, typeMenu } from 'const/menu';

export default function Main() {
  // 未ログインユーザがアクセスした場合のリダイレクト先
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [useMessage, setMessage] = useState('Please Sign in!');
  const [useAxios] = useState('');

  const [useEmail, setEmail] = useState(env.API_LOGIN_USER());
  const emailChange = (e: any) => setEmail(e.target.value);
  const [usePass, setPass] = useState(''); //useState(env.API_LOGIN_PASS());
  const passChange = (e: any) => setPass(e.target.value);

  localStorage.clear();
  const getToken = () => {
    setMessage('stay, authentication');

    const data = {
      username: useEmail,
      password: usePass,
    };

    localStorage.setItem('username', useEmail);
    localStorage.setItem('password', usePass);
    axiosCustom.login(data, function (res: any, flg: boolean) {
      try {
        if (flg) {
          localStorage.setItem('username', useEmail);
          localStorage.setItem('datetime', new Date().toISOString());

          // メニューのどこから来たかでリダイレクト先を変更
          for (let i = 0; i < menus.length; i++) {
            if (from.startsWith('/' + menus[i].path)) {
              navigate(from, { replace: true });
              //window.location.href = from;
              return;
            }
          }

          //navigate("/", { replace: true });
          window.location.href = '/';
        } else {
          setMessage('misstake');
          Console_log('misstake', res);
        }
      } catch (error) {
        Console_log('login faild', error);
      }
    });
  };

  return (
    <LoginFrame>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        defaultValue={useEmail}
        onChange={emailChange}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        defaultValue={usePass}
        onChange={passChange}
      />
      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label="Remember me"
      />
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={getToken}
      >
        {useMessage}
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="#" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href={''} variant="body2">
            {"Don't have an account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          {useAxios}
        </Grid>
      </Grid>
    </LoginFrame>
  );
}
