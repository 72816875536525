import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useSelection } from 'contexts/Schedules';
import TableFrame from 'frames/TableFrame';
import { tTransportCard, tTransportKey } from 'types/transport';
import {
  tInstructionCard,
  tInstSearch,
  tInstruction,
  cbGetInstructionCardList,
} from 'types/instruction';
import { MstDataContext } from 'contexts/Mst';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DragTransportCardArea } from 'components/TransportCard';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { tUser } from 'types/mst';
import { GridItemTextFieldCustom } from 'components/GridItem';
import { AutocompleteSelect } from 'atoms/Select';
import * as InstCard from 'components/instruction/InstructionCard';
import { TableCell, TableRow } from '@mui/material';
import * as apiInst from 'functions/api/instruction';
import InstructionModal from 'components/instruction/Modal';
import * as InstButton from 'components/instruction/Button';
import log from 'functions/logger';
import initInstSearch from 'const/instruction/search';
import {
  changeDateFromTypeDate,
  mathDateTime,
  strDateTime,
  isSameDate,
  changeFromDatetimeToInputTypeDatetime,
} from 'functions/time';
import { ListItem, apiGetListSuccess } from 'types/index';
import initInstruction from 'const/instruction';
import Loading from 'atoms/Loading';
import { SecondButton } from 'atoms/Button';
import themeBase from 'themes/Base';
import { LockTwoTone } from '@mui/icons-material';

const ddType = 'TransportCard';

type tranCardDropProps = (item: tInstruction) => void;
type driverBlankClickProps = ({
  driver,
  key,
}: {
  driver: tUser['id'];
  key: string;
}) => void;
type instCardClickProps = ({ pj_id, no }: tTransportKey) => void;
type tTableRowData = {
  driver: tUser['id'];
  data: tInstructionCard[];
  dataBefore1: tInstructionCard[];
  dataBefore2: tInstructionCard[];
  dataBefore3: tInstructionCard[];
  dataAfter: tInstructionCard[];
  key: string;
};
const initTableRowData: tTableRowData = {
  driver: 0,
  data: [],
  dataBefore1: [],
  dataBefore2: [],
  dataBefore3: [],
  dataAfter: [],
  key: '',
};

export default function Main() {
  const navigate = useNavigate();
  const { drivers, SYSTEM, loading } = useContext(MstDataContext);
  const { transportCards } = useSelection();
  const dayworkKey = strDateTime(new Date());

  const [targetDriver, setTargetDriver] = useState<ListItem[]>(drivers || []);
  const [tableRowData, setTableRowData] = useState<tTableRowData[]>([]);

  const [trigger, setTrigger] = useState('');
  const [searchInst, setSearchInst] = useState<tInstSearch>(initInstSearch);
  const [date, setDate] = useState<Date>(new Date());

  const [openInstModal, setOpenInstModal] = useState(false);
  const [dropInst, setDropInst] = useState<tInstruction>(initInstruction);

  useEffect(() => {
    log.debug('after', date);

    if (!loading && drivers) {
      setTargetDriver(drivers);

      // driversの数だけtableRowDataを作成
      const rows: tTableRowData[] = drivers.map((driver, index: number) => {
        return {
          ...initTableRowData,
          key: `${date.toISOString()}-${index}`,
        };
      });

      //log.debug('rows', rows);
      const callback = ({ data, filter }: apiGetListSuccess) => {
        /*
        const existData = changeTableRowDataFromData({
          date: date,
          data: data,
          drivers: drivers || [],
        });
        */

        //log.debug('existData', existData);

        // 既存データをマージ
        setTableRowDataFromData({
          date: date,
          data: data,
          drivers: drivers || [],
          tableRowData: rows,
          setTableRowData: setTableRowData,
        });
      };

      getDataList(date, callback);
    }
  }, [loading, date]);

  useEffect(() => {
    // モーダル表示初期値を更新
    setDropInst((prev) => {
      return {
        ...prev,
        start_datetime: changeDateFromTypeDate(date),
        end_datetime: changeDateFromTypeDate(date),
      };
    });
  }, [date]);

  useEffect(() => {
    if (!drivers) {
      return;
    }
    // tableRowDataのdriverを配列にする
    const selectDriverList = tableRowData.map((rowData) => rowData.driver);
    // driversからtableRowDataにないdriverを取得
    const newDriver = drivers.filter(
      (driver) => !selectDriverList.includes(driver.id)
    );
    //log.debug('newDriver', newDriver);
    setTargetDriver(newDriver);
  }, [tableRowData]);

  /**
   * 対象日の運行指示を取得
   * @param date
   */
  const getDataList = (
    date: Date,
    callback:
      | (({ data, filter }: apiGetListSuccess) => void)
      | undefined = undefined
  ) => {
    //log.debug('getDataList', date);
    const filter: tInstSearch = {
      key: null,
      pj_id: null,
      no: null,
      order: null,
      start_datetime_from: null,
      start_datetime_to: null,
      end_datetime_from: changeDateFromTypeDate(
        mathDateTime(date, [0, 0, -3, 0, 0, 0])
      ),
      end_datetime_to: changeDateFromTypeDate(
        mathDateTime(date, [0, 0, 1, 0, 0, 0])
      ),
      tm_id: null,
      status: null,
      user_id: null,
      v_id: null,
      c_id: null,
    };
    apiInst.getInstructionCardList({
      filter: filter,
      callbackSuccess: ({ data, filter }: apiGetListSuccess) => {
        if (callback) callback({ data, filter });
      },
    });
  };

  /**
   * ここでtmpデータを作成する
   * @param param0
   */
  const handleDrop = (item: tInstruction) => {
    log.debug('handleDrop', item);
    setDropInst((prev) => {
      return {
        ...prev,
        ...item,
        start_datetime: prev.start_datetime || changeDateFromTypeDate(date),
        end_datetime: prev.end_datetime || changeDateFromTypeDate(date),
      };
    });
    setOpenInstModal(true);
  };

  /**
   * 運行指示カードクリック時の処理
   * @param param0
   */
  const handleInstCardClick: instCardClickProps = ({
    pj_id: pjId,
    no: tranNo,
  }: tTransportKey) => {
    // 運行指示入力モーダルを表示
    //log.debug('instCardClick', pjId, tranNo);
    setDropInst({
      ...initInstruction,
      pj_id: pjId,
      no: tranNo,
    });
    setOpenInstModal(true);
  };

  /**
   * Blankカードクリック時の処理
   * @param param0
   * @returns
   */
  const handleBlankClick = ({
    driver,
    key,
  }: {
    driver: tUser['id'];
    key: string;
  }) => {
    // 運行指示入力モーダルを表示
    //log.debug('blankClick', driver);
    if (!SYSTEM) return;
    setDropInst({
      ...initInstruction,
      key: key,
      tm_id: SYSTEM.tranMethod.own.id,
      user_id: driver,
      start_datetime: changeDateFromTypeDate(date),
      end_datetime: changeDateFromTypeDate(date),
    });
    setOpenInstModal(true);
  };

  /**
   * モーダルでデータ登録後の処理
   * ここでtableRowDataにデータを追加する
   * @param data
   */
  const callbackInserts = (data: tInstruction[]) => {
    log.debug('callbackInserts', data);
  };

  const closeInstModal = () => {
    log.debug('closeInstModal', date);
    setOpenInstModal(false);
    //setTrigger(new Date().getTime().toString());
    const callback = ({ data, filter }: apiGetListSuccess) => {
      setTableRowDataFromData({
        date: date,
        data: data,
        drivers: drivers || [],
        tableRowData: tableRowData,
        setTableRowData: setTableRowData,
      });
    };

    getDataList(date, callback);
  };

  useEffect(() => {
    if (!openInstModal) {
      setDropInst(initInstruction);
    }
  }, [openInstModal]);

  const callbackGetList = ({ data, filter }: cbGetInstructionCardList) => {
    //setInstructions(data);
  };

  const getList = () => {
    // searchList(searchInst, [], [], callbackGetList);
  };

  useEffect(() => {
    getList();
  }, [trigger, searchInst]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Loading flg={SYSTEM === undefined} />
      <TableFrame
        HeadContent={
          <HeadContent
            date={date}
            setDate={setDate}
            setSearchInst={setSearchInst}
            trigger={trigger}
          />
        }
        TableHeaderRows={<HeadData date={date} />}
        TableBodyRows={
          <BodyData
            drivers={targetDriver}
            data={tableRowData}
            setData={setTableRowData}
            handleDrop={handleDrop}
            handleInstCardClick={handleInstCardClick}
            handleBlankClick={handleBlankClick}
          />
        }
        SubContent={
          <DragTransportCardArea
            ddType={ddType}
            transportCards={transportCards}
            optionNode={
              <Button
                variant="contained"
                onClick={() => {
                  navigate('/full-screen/schedules');
                }}
              >
                予定表へ
              </Button>
            }
          />
        }
      />

      <InstructionModal
        open={openInstModal}
        onClose={closeInstModal}
        tranKey={{ pj_id: dropInst?.pj_id || 0, no: dropInst?.no || 0 }}
        info={dropInst}
        callbackNomal={callbackInserts}
      />
    </DndProvider>
  );
}

interface HeadContentProps {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  setSearchInst: React.Dispatch<React.SetStateAction<tInstSearch>>;
  trigger: string;
}
const HeadContent = ({
  date,
  setDate,
  setSearchInst,
  trigger,
}: HeadContentProps) => {
  const theme = useTheme();
  log.debug('HeadContent', date.toDateString());
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <SecondButton
          onClick={() => {
            log.debug('before', date);
            setDate(mathDateTime(date, [0, 0, -1, 0, 0, 0]));
          }}
          label={'<'}
        />
        <Typography variant="h6">{changeDateFromTypeDate(date)}</Typography>
        <SecondButton
          onClick={() => setDate(mathDateTime(date, [0, 0, 1, 0, 0, 0]))}
          label={'>'}
        />
      </Box>
      <Buttons setSearchInst={setSearchInst} trigger={trigger} />
    </>
  );
};

interface ButtonsProps {
  setSearchInst: React.Dispatch<React.SetStateAction<tInstSearch>>;
  trigger: string;
}
const Buttons = ({ setSearchInst, trigger }: ButtonsProps) => {
  return (
    <>
      <InstButton.ShowSearchModal
        callbackSearch={(data: tInstSearch) => {
          setSearchInst(data);
        }}
        trigger={trigger}
      />
    </>
  );
};

interface HeadDataProps {
  date: Date;
}
const HeadData = ({ date }: HeadDataProps) => {
  return (
    <TableRow>
      <TableCell className="driver" sx={{ minWidth: '200px' }}>
        ドライバー
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        {changeDateFromTypeDate(mathDateTime(date, [0, 0, -3, 0, 0, 0]))}
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        {changeDateFromTypeDate(mathDateTime(date, [0, 0, -2, 0, 0, 0]))}
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        {changeDateFromTypeDate(mathDateTime(date, [0, 0, -1, 0, 0, 0]))}
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        {changeDateFromTypeDate(date)}
      </TableCell>
      <TableCell className="day" sx={{ minWidth: '300px' }}>
        {changeDateFromTypeDate(mathDateTime(date, [0, 0, 1, 0, 0, 0]))}
      </TableCell>
    </TableRow>
  );
};

interface BodyDataProps {
  drivers: ListItem[];
  data: tTableRowData[];
  setData: React.Dispatch<React.SetStateAction<tTableRowData[]>>;
  handleDrop: tranCardDropProps;
  handleInstCardClick: instCardClickProps;
  handleBlankClick?: driverBlankClickProps;
}
const BodyData = ({
  drivers,
  data,
  setData,
  handleDrop,
  handleInstCardClick,
  handleBlankClick = undefined,
}: BodyDataProps) => {
  /**
   * ドライバー変更時のコールバック
   * @param param0
   */
  const callbackRowDirver = ({
    driver,
    idx,
  }: {
    driver: tUser['id'];
    idx: number;
  }) => {
    setData((prev) => {
      const newData = [...prev];
      newData[idx].driver = driver;

      // 運行指示のドライバーIDを変更
      try {
        newData[idx].data.forEach((inst) => {
          inst.driver_id = driver;

          if (inst.id) {
            const callbackSuccess = (data: tInstruction) => {
              log.debug('callbackSuccess', data);
            };

            const callbackError = () => {
              log.debug('callbackError');
              alert('更新に失敗しました');
              throw new Error('更新に失敗しました');
            };

            // 更新処理を実行
            apiInst.update(
              inst.id,
              { user_id: driver },
              callbackSuccess,
              callbackError
            );
          }
        });
      } catch (e) {
        log.debug('error', e);
      }

      //log.debug('callbackRowDirver', newData);
      return newData;
    });
  };

  const CellInstData = ({
    rowKey,
    driver,
    data,
    flgBlank = false,
  }: {
    rowKey: tTableRowData['key'];
    driver: tUser['id'];
    data: tInstructionCard[];
    flgBlank?: boolean;
  }) => {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexBasis: 'auto',
          flexWrap: 'wrap',
        }}
      >
        {data.map((inst, idx) => {
          return (
            <InstCard.ToDragDrop
              key={rowKey + '-' + idx}
              ddType="instruction-scheduleEdit"
              instructionCard={inst}
              flgOmit={false}
              switchCtrl={false}
              callbackDoubleClick={() => {
                log.debug('doubleClick', inst);
                handleInstCardClick({
                  pj_id: inst.pj_id || 0,
                  no: inst.no || 0,
                });
              }}
            />
          );
        })}
        {flgBlank && (
          <TranCardBlank
            driver={driver}
            callbackDrop={(item: tInstruction) => {
              item.key = rowKey;
              handleDrop(item);
            }}
            callbackClick={() => {
              if (handleBlankClick)
                handleBlankClick({
                  driver: driver,
                  key: rowKey,
                });
            }}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {data.map((rowData: tTableRowData, idx: number) => {
        //log.debug('BodyData', rowData);
        return (
          <TableRow key={`instruction-scheduleEdit-row-${idx}`}>
            <TableCell variant="head">
              <RowDriver
                items={drivers}
                value={rowData.driver}
                callbackChange={({ driver }: { driver: tUser['id'] }) => {
                  callbackRowDirver({ driver: driver, idx: idx });
                }}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataBefore3}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataBefore2}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataBefore1}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.data}
                flgBlank={true}
              />
            </TableCell>
            <TableCell>
              <CellInstData
                rowKey={rowData.key}
                driver={rowData.driver}
                data={rowData.dataAfter}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

const TranCardBlank = ({
  driver,
  callbackDrop,
  callbackClick = undefined,
}: {
  driver: tUser['id'];
  callbackDrop: tranCardDropProps;
  callbackClick?: (() => void) | undefined;
}) => {
  const { SYSTEM } = useContext(MstDataContext);

  const { isOver, dropRef } = useDropHandler(callbackDrop, {
    user_id: driver,
    tm_id: SYSTEM?.tranMethod.own.id || 1,
  });

  const Toolchip = () => {
    return (
      <Box>
        <Typography>ドラッグして運行指示を追加</Typography>
      </Box>
    );
  };

  return (
    <InstCard.Blank
      ref={dropRef}
      flgOmit={false}
      label="新"
      callbackDoubleClick={(event) => {
        if (callbackClick) {
          callbackClick();
        }
      }}
      isOver={isOver}
      //toolchipChildren={<Toolchip />}
    />
  );
};

interface RowDriverProps {
  value: tTableRowData['driver'];
  callbackChange: ({ driver }: { driver: tUser['id'] }) => void;
  size?: Record<string, number>;
  items: ListItem[];
}
export const RowDriver = ({
  value,
  callbackChange,
  size = { xs: 12, lg: 3, xl: 3 },
  items,
}: RowDriverProps & { items: ListItem[] }) => {
  const { drivers } = useContext(MstDataContext);
  const selectedOption = drivers?.find((item) => item.id === value);

  return (
    <GridItemTextFieldCustom size={size}>
      <AutocompleteSelect
        disableClearable={false}
        name="user_id"
        label={''}
        options={items}
        value={selectedOption || null}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          if (!newValue) {
            callbackChange({ driver: 0 });
            return;
          }
          callbackChange({ driver: newValue.id });
        }}
      />
    </GridItemTextFieldCustom>
  );
};

/**
 * ドロップ処理のハンドラ
 * @param day
 * @param callbackDrop
 * @param extraData
 * @returns
 */
export const useDropHandler = (
  callbackDrop: (item: tInstruction) => void,
  extraData: Partial<tInstruction> = {}
) => {
  const extraDataRef = useRef(extraData);

  // extraData が変更された場合に ref を更新
  extraDataRef.current = extraData;

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: 'TransportCard', // ドラッグ対象の種類
      drop: (item: tTransportCard) => {
        // ドロップされた時の処理
        const defInst: tInstruction = {
          ...initInstruction,
          pj_id: item.pj_id,
          no: item.tran_no,
          ...extraDataRef.current, // driver_id や tm_id を渡せるようにする
        };
        callbackDrop(defInst);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [callbackDrop, extraData]
  );

  return { isOver, dropRef };
};

const changeTableRowDataFromData = ({
  date,
  data,
  drivers,
}: {
  date: Date;
  data: tInstructionCard[];
  drivers: ListItem[];
}) => {
  //log.debug('changeTableRowDataFromData', data);
  const newTableRowData: tTableRowData[] = [];

  // dataの中からドライバーが設定されているものを抽出
  const driverData = data.filter(
    (inst) => inst.driver_id && isSameDate(inst.end_datetime, date)
  );

  // ドライバー単位でグループ化
  const driverGroupData = driverData.reduce(
    (acc, cur) => {
      (acc[cur.driver_id || 0] = acc[cur.driver_id || 0] || []).push(cur);
      return acc;
    },
    {} as Record<tUser['id'], tInstructionCard[]>
  );

  // グループ毎にnewTableRowDataにデータを追加
  Object.keys(driverGroupData).forEach((key) => {
    const driverId = Number(key); // 文字列を数値に変換

    // 卸日がdateのものを抽出
    const dateData = driverGroupData[driverId].filter((inst) =>
      isSameDate(inst.end_datetime, date.toLocaleDateString())
    );

    if (!dateData || dateData.length === 0) {
      return;
    }

    // 卸日がdate - 1のものを抽出
    const dateBefore1Data = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, -1, 0, 0, 0]).toLocaleDateString()
      )
    );

    // 卸日がdate - 2のものを抽出
    const dateBefore2Data = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, -2, 0, 0, 0]).toLocaleDateString()
      )
    );

    // 卸日がdate - 3のものを抽出
    const dateBefore3Data = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, -3, 0, 0, 0]).toLocaleDateString()
      )
    );

    //  卸日がdate + 1のものを抽出
    const dateAfterData = driverGroupData[driverId].filter((inst) =>
      isSameDate(
        inst.end_datetime,
        mathDateTime(date, [0, 0, 1, 0, 0, 0]).toLocaleDateString()
      )
    );

    newTableRowData.push({
      driver: Number(key),
      data: dateData,
      dataBefore1: dateBefore1Data,
      dataBefore2: dateBefore2Data,
      dataBefore3: dateBefore3Data,
      dataAfter: dateAfterData,
      key: `${date.toISOString()}-${key}`,
    });
  });

  // dataの中からドライバーが設定されていないものを抽出
  const noDriverData = data.filter((inst) => !inst.driver_id);

  // keyでグループ化
  const groupData = noDriverData.reduce(
    (acc, cur) => {
      (acc[cur.key] = acc[cur.key] || []).push(cur);
      return acc;
    },
    {} as Record<string, tInstructionCard[]>
  );

  // グループの並び順をkeyの昇順に変更
  const groupDataKeys = Object.keys(groupData).sort();
  const groupDataSorted = groupDataKeys.reduce(
    (acc, key) => {
      acc[key] = groupData[key];
      return acc;
    },
    {} as Record<string, tInstructionCard[]>
  );

  // グループ毎にnewTableRowDataにデータを追加
  Object.keys(groupDataSorted).forEach((key) => {
    // 卸日がdateのものを抽出
    const dateData = groupDataSorted[key].filter((inst) =>
      isSameDate(inst.end_datetime, changeDateFromTypeDate(date))
    );

    if (!dateData || dateData.length === 0) {
      return;
    }

    newTableRowData.push({
      driver: 0,
      data: dateData,
      dataBefore1: [],
      dataBefore2: [],
      dataBefore3: [],
      dataAfter: [],
      key: key,
    });
  });

  return newTableRowData;
};

/**
 * tableRowDataにdataを設定する
 * @param param0
 */
const setTableRowDataFromData = ({
  date,
  data,
  drivers,
  tableRowData,
  setTableRowData,
}: {
  date: Date;
  data: tInstructionCard[];
  drivers: ListItem[];
  tableRowData: tTableRowData[];
  setTableRowData: React.Dispatch<React.SetStateAction<tTableRowData[]>>;
}) => {
  const newTableRowData: tTableRowData[] = [];

  // tableRowDataの配列分だけループ
  tableRowData.forEach((rowData) => {
    let rowInst: tInstructionCard[] = [];
    if (rowData.driver) {
      // ドライバーが設定されている場合、dataから同じドライバーのデータを抽出
      rowInst = data.filter((inst) => inst.driver_id === rowData.driver);
      log.debug('ドライバーが設定されている場合', rowData.driver);
    } else {
      // ドライバーが設定されていない場合、keyが一致するデータを抽出
      //log.debug('key', rowData.key);
      rowInst = data.filter((inst) => inst.key === rowData.key);
    }

    // dataからrowInstを削除
    data = data.filter((inst) => !rowInst.includes(inst));

    //log.debug('rowInst', rowInst);

    // dataの中から卸日がdateのものを抽出
    const [before3, brfore2, before1, nowDay, after] = dataSortSameDate(
      date,
      rowInst
    );

    // グループ毎にnewTableRowDataにデータを追加
    newTableRowData.push({
      driver: rowData.driver,
      data: nowDay,
      dataBefore1: before1,
      dataBefore2: brfore2,
      dataBefore3: before3,
      dataAfter: after,
      key: rowData.key,
    });
  });

  // dataの中からドライバーが設定されていないものを抽出
  const noDriverData = data.filter((inst) => !inst.driver_id);

  // noDriverDataをdataから削除
  data = data.filter((inst) => !noDriverData.includes(inst));

  // keyでグループ化
  const groupData = noDriverData.reduce(
    (acc, cur) => {
      (acc[cur.key] = acc[cur.key] || []).push(cur);
      return acc;
    },
    {} as Record<string, tInstructionCard[]>
  );

  // グループの並び順をkeyの昇順に変更
  const groupDataKeys = Object.keys(groupData).sort();
  const groupDataSorted = groupDataKeys.reduce(
    (acc, key) => {
      acc[key] = groupData[key];
      return acc;
    },
    {} as Record<string, tInstructionCard[]>
  );

  // グループ毎にnewTableRowDataにデータを追加
  Object.keys(groupDataSorted).forEach((key) => {
    // 卸日がdateのものを抽出
    const dateData = groupDataSorted[key].filter((inst) =>
      isSameDate(inst.end_datetime, changeDateFromTypeDate(date))
    );

    if (!dateData || dateData.length === 0) {
      return;
    }

    newTableRowData.push({
      driver: 0,
      data: dateData,
      dataBefore1: [],
      dataBefore2: [],
      dataBefore3: [],
      dataAfter: [],
      key: key,
    });
  });

  // dataにまだデータが残っている場合（ドライバー付き）は追加
  if (data.length > 0) {
    // ドライバー単位でグループ化
    const driverGroupData = data.reduce(
      (acc, cur) => {
        (acc[cur.driver_id || 0] = acc[cur.driver_id || 0] || []).push(cur);
        return acc;
      },
      {} as Record<tUser['id'], tInstructionCard[]>
    );

    // driver_idの順に並び替える
    const driverGroupDataKeys = Object.keys(driverGroupData).sort();
    const driverGroupDataSorted = driverGroupDataKeys.reduce(
      (acc, key) => {
        acc[Number(key)] = driverGroupData[Number(key)];
        return acc;
      },
      {} as Record<tUser['id'], tInstructionCard[]>
    );

    Object.keys(driverGroupDataSorted).forEach((driverID) => {
      const [before3, brfore2, before1, nowDay, after] = dataSortSameDate(
        date,
        driverGroupDataSorted[Number(driverID)]
      );

      newTableRowData.push({
        driver: Number(driverID),
        data: nowDay,
        dataBefore1: before1,
        dataBefore2: brfore2,
        dataBefore3: before3,
        dataAfter: after,
        key: '',
      });
    });
  }

  setTableRowData(newTableRowData);
};

/**
 * 日別にデータを分ける
 * @param date
 * @param data
 * @returns
 */
const dataSortSameDate = (date: Date, data: tInstructionCard[]) => {
  const dateData = data.filter((inst) =>
    isSameDate(inst.end_datetime, changeDateFromTypeDate(date))
  );

  // 卸日がdate - 1のものを抽出
  const dateBefore1Data = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, -1, 0, 0, 0]).toLocaleDateString()
    )
  );

  // 卸日がdate - 2のものを抽出
  const dateBefore2Data = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, -2, 0, 0, 0]).toLocaleDateString()
    )
  );

  // 卸日がdate - 3のものを抽出
  const dateBefore3Data = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, -3, 0, 0, 0]).toLocaleDateString()
    )
  );

  //  卸日がdate + 1のものを抽出
  const dateAfterData = data.filter((inst) =>
    isSameDate(
      inst.end_datetime,
      mathDateTime(date, [0, 0, 1, 0, 0, 0]).toLocaleDateString()
    )
  );

  return [
    dateBefore3Data,
    dateBefore2Data,
    dateBefore1Data,
    dateData,
    dateAfterData,
  ];
};
