import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { isSameDate } from 'functions/time';
import { tInstructionCard, cardLabelType } from 'types/instruction';
import { strDateTimeOrigin } from 'functions/time';
import { MstDataContext } from 'contexts/Mst';
import { tTransportMethod } from 'types/mst';
import BaseCard from 'atoms/Card';
import { Toolchip } from 'atoms/Toolchip';
import { cardType } from 'types/index';
import { useDrag } from 'react-dnd';
import * as Button from 'atoms/Button';
import log from 'functions/logger';

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const Nomal = forwardRef<
  HTMLDivElement,
  {
    instruction: tInstructionCard;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    selected?: boolean;
    children?: React.ReactNode;
    type: cardType;
    labelType?: cardLabelType;
  }
>((props, ref) => {
  const {
    instruction,
    callbackClick,
    flgOmit = true,
    children,
    selected = false,
    type = 'box',
    labelType = 'index',
  } = props;

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // 親への伝播を止める
    if (flgOmit) {
      return;
    }
    //navigate(`/project/edit/${transportCard.pj_id}`);
  };

  return (
    <BaseCard
      ref={ref}
      selected={selected}
      flgOmit={flgOmit}
      type={type}
      callbackDoubleClick={handleCardDoubleClick}
      callbackClick={callbackClick}
      otherClassName={`size-auto`}
    >
      {children}
      <Labels type={labelType} flgOmit={flgOmit} instruction={instruction} />
      <AttributeChip instruction={instruction} flgOmit={flgOmit} />
    </BaseCard>
  );
});
Nomal.displayName = 'instructioncard-card-nomal';

/**
 * 輸送情報カード(空)
 * @param param0
 * @returns
 */
export const Blank = forwardRef<
  HTMLDivElement,
  {
    label: string;
    callbackDoubleClick?:
      | ((event: React.MouseEvent<HTMLElement>) => void)
      | undefined;
    callbackClick?:
      | ((event: React.MouseEvent<HTMLElement>) => void)
      | undefined;
    flgOmit?: any;
    ref?: any;
    isOver: boolean;
  }
>((props, ref) => {
  const {
    label,
    callbackDoubleClick,
    callbackClick,
    flgOmit = true,
    isOver = false,
  } = props;

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackDoubleClick) {
      callbackDoubleClick(event);
    }
  };

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackClick) {
      callbackClick(event);
    }
  };

  return (
    <BaseCard
      ref={ref}
      flgOmit={flgOmit}
      callbackDoubleClick={handleCardDoubleClick}
      callbackClick={handleCardClick}
      className={'InstructionCardBlank blank'}
      isOver={isOver}
    >
      {!flgOmit && <Typography className="label">{`${label}`}</Typography>}
    </BaseCard>
  );
});
Blank.displayName = 'instructioncard-card-blank';

/**
 * ツールチップ付き運行指示作成用輸送情報カード
 */
export const WithToolchip = forwardRef<
  HTMLDivElement,
  {
    instruction: tInstructionCard;
    flgOmit?: any;
    ref?: any;
    selected?: boolean;
    cardChildren?: React.ReactNode;
    toolchipChildren: React.ReactNode;
    type?: cardType;
    labelType?: cardLabelType;
    className?: string;
    trigger?: string;
  }
>((props, ref) => {
  const {
    instruction,
    flgOmit = true,
    selected = false,
    cardChildren,
    toolchipChildren,
    type = 'box',
    labelType = 'index',
    className = '',
    trigger = '',
  } = props;
  const theme = useTheme();
  const { SYSTEM } = useContext(MstDataContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  let statusKey = '';
  switch (instruction.status) {
    case SYSTEM?.instruction.status.InTransit:
      statusKey = 'InTransit';
      break;
    case SYSTEM?.instruction.status.Instructed:
      statusKey = 'Instructed';
      break;
    case SYSTEM?.instruction.status.Suspend:
      statusKey = 'Suspend';
      break;
    default:
      statusKey = 'none';
      break;
  }

  //log.debug("InstructionCardWithToolchip", instruction);

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  useEffect(() => {
    handleToolchipClose();
  }, [trigger]);

  return (
    <>
      <BaseCard
        ref={ref}
        selected={selected}
        flgOmit={flgOmit}
        type={type}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        otherClassName={`instruction-card size-auto ${className} status-${statusKey}`}
      >
        {cardChildren}
        <Labels type={labelType} flgOmit={flgOmit} instruction={instruction} />
        <AttributeChip instruction={instruction} flgOmit={flgOmit} />
      </BaseCard>
      <Toolchip
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        <Box sx={{ display: 'flex', flexFlow: 'row', gap: theme.spacing(2) }}>
          {toolchipChildren}
        </Box>
      </Toolchip>
    </>
  );
});
WithToolchip.displayName = 'instructioncard-card-withtoolchip';

const Labels = ({
  instruction,
  type,
  flgOmit,
}: LabelsTypeIndexProps & { type: cardLabelType }) => {
  switch (type) {
    case 'index':
      return <LabelsTypeIndex instruction={instruction} flgOmit={flgOmit} />;
    case 'driver':
      return <LabelsTypeDriver instruction={instruction} flgOmit={flgOmit} />;
    case 'charter':
      return <LabelsTypeCharter instruction={instruction} flgOmit={flgOmit} />;
    case 'method':
      return <LabelsTypeMethoed instruction={instruction} flgOmit={flgOmit} />;
    default:
      return <></>;
  }
};

interface LabelsTypeIndexProps {
  instruction: tInstructionCard;
  flgOmit: boolean;
}
const LabelsTypeIndex = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    if (instruction.tm_id === SYSTEM?.tranMethod.own.id) {
      return `${SYSTEM?.tranMethod.own.label}(${instruction.driver_name})`;
    } else if (instruction.tm_id === SYSTEM?.tranMethod.charter.id) {
      return `${SYSTEM?.tranMethod.charter.label}(${instruction.c_abbreviation})`;
    } else {
      const obj = tranMethods?.find(
        (tm: tTransportMethod) => tm.id === instruction.tm_id
      );
      return `${obj?.abbreviation}`;
    }
  };

  if (!flgOmit) {
    return (
      <>
        <Typography>{titleLabel()}</Typography>
        <Box>
          <Typography>{`${strDateTimeOrigin(
            new Date(instruction.start_datetime)
          )}`}</Typography>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>
        </Box>

        <Box>
          <Typography>{`${strDateTimeOrigin(
            new Date(instruction.end_datetime)
          )}`}</Typography>
          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeDriver = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  if (!flgOmit) {
    return (
      <>
        <Box className={'contents'}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
        </Box>
        <Box className={'contents'}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>

          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeCharter = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    return `${instruction.c_abbreviation}(${instruction.c_vehicle})`;
  };
  if (!flgOmit) {
    return (
      <>
        <Box className={'contents'}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
          <Typography>{titleLabel()}</Typography>
        </Box>
        <Box className={'contents'}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>

          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeMethoed = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    const obj = tranMethods?.find(
      (tm: tTransportMethod) => tm.id === instruction.tm_id
    );
    return `${obj?.abbreviation}`;
  };

  if (!flgOmit) {
    return (
      <>
        <Box className={'contents'}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
          <Typography>{titleLabel()}</Typography>
        </Box>
        <Box className={'contents'}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>
          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const AttributeChip = ({
  instruction,
  flgOmit,
}: LabelsTypeIndexProps): React.ReactElement => {
  const sameDate = isSameDate(
    instruction.start_datetime,
    instruction.end_datetime
  );

  return (
    <Box className="attribute-chip">
      {sameDate && <Typography className="chip evening">当</Typography>}
    </Box>
  );
};

/**
 * D&D可能なカード
 * @param param0
 * @returns
 */
export const ToDragDrop = ({
  ddType,
  className = '',
  instructionCard, // 輸送情報
  callbackDoubleClick = undefined, // ダブルクリック時の処理
  flgOmit = true, // 略称表示かどうか
  switchCtrl = true, // 選択機能の有無
  flgAttributeLabel = true, // 属性ラベルの表示
}: {
  ddType: string;
  className?: string;
  instructionCard: tInstructionCard;
  callbackDoubleClick?: (() => void) | undefined;
  flgOmit?: any;
  switchCtrl?: boolean;
  flgAttributeLabel?: boolean;
}) => {
  const { SYSTEM } = useContext(MstDataContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // ポップオーバーのアンカー
  const [selected, setSelected] = useState(false);

  let statusKey = '';
  switch (instructionCard.status) {
    case SYSTEM?.instruction.status.InTransit:
      statusKey = 'InTransit';
      break;
    case SYSTEM?.instruction.status.Instructed:
      statusKey = 'Instructed';
      break;
    case SYSTEM?.instruction.status.Suspend:
      statusKey = 'Suspend';
      break;
    default:
      statusKey = 'none';
      break;
  }

  /**
   * D&D用のフック
   */
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ddType,
    item: instructionCard,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackDoubleClick) {
      callbackDoubleClick();
    }
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  /**
   * 解除用ツールチップ処理
   * @param type
   */
  const handleToolchipSelectOFF = () => {
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  //useEffect(() => {}, [anchorEl]);

  const open = Boolean(anchorEl);

  return (
    <>
      <BaseCard
        ref={drag}
        selected={selected}
        flgOmit={flgOmit}
        type={'box'}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        otherClassName={`instruction-card ${className} status-${statusKey}`}
      >
        <Labels
          type={'driver'}
          flgOmit={flgOmit}
          instruction={instructionCard}
        />
        <AttributeChip instruction={instructionCard} flgOmit={flgOmit} />
      </BaseCard>
      <Toolchip
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {selected && (
          <>
            <Button.SecondButton
              onClick={() => handleToolchipSelectOFF()}
              label={'解除'}
            />
          </>
        )}
      </Toolchip>
    </>
  );
};
