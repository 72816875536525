import React from 'react';
import themeBase, { weekPalette, palette } from 'themes/Base';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import deepmerge from 'deepmerge';
import { alpha } from '@mui/material/styles';

const themeInstruction = createTheme(
  deepmerge(themeBase, {
    ...themeBase,
    components: {
      MuiCssBaseline: {
        styleOverrides: {},
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: 'xl',
        },
        styleOverrides: {
          root: {
            '&.SlideArea': {
              position: 'fixed',
              zIndex: 1000,
              backgroundColor: themeBase.palette.common.white,

              '&.SlideArea-bottom': {
                bottom: 0,
                left: 0,
                width: '100%',
                minHeight: '150px', // 自動で変えられるように
              },
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {},
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            '&.Monday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Monday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Monday, 0.6) },
            },
            '&.Tuesday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Tuesday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Tuesday, 0.6) },
            },
            '&.Wednesday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Wednesday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Wednesday, 0.6) },
            },
            '&.Thursday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Thursday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Thursday, 0.6) },
            },
            '&.Friday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Friday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Friday, 0.6) },
            },
            '&.Saturday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Saturday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Saturday, 0.6) },
            },
            '&.Sunday': {
              '&.odd': { backgroundColor: alpha(weekPalette.Sunday, 0.3) },
              '&.even': { backgroundColor: alpha(weekPalette.Sunday, 0.6) },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            textAlign: 'center',
            backgroundColor: themeBase.palette.primary.contrastText,
            color: themeBase.palette.primary.main,
          },
          root: {
            '& >*': {
              display: 'flex',
              justifyContent: 'space-evenly',
              flexFlow: 'wrap',
            },
            '&.day': {
              minWidth: 150,
              maxWidth: 150,
              minHeight: 50,
            },
            '&.driver': {
              minWidth: 400,
              maxWidth: 400,
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            '&.card-custom': {},
            '&.status-InTransit': {
              color: palette.red,
              borderColor: palette.red,
              '& *': {
                fontWeight: 'bold !important',
              },
            },
            '&.status-Instructed': {
              backgroundColor: palette.glayDark,
              color: themeBase.palette.common.white,
            },
            '&.status-Suspend': {},
          },
        },
      },
    },
  })
);

interface ThemeProps {
  children: React.ReactNode;
}

const InstructionThemeProvider: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={themeInstruction}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default InstructionThemeProvider;
