/**
 * 案件情報一覧画面
 */
import React, { useState, useEffect } from 'react';
import { tProject, tProjectSearch } from 'types/project';
import { TableRow, TableCell } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getProjects } from 'functions/api/project';
import TableFrame from 'frames/TableFrame';
import * as PJBtn from 'components/project/Button';
import {
  getTorisakiName,
  getLoadName,
  getUnloadName,
} from 'functions/project/index';
import {
  strDateOrigin,
  mathDateTime,
  changeDateFromTypeDate,
} from 'functions/time';
import { getStatusLabel } from 'functions/project/validate';
import PageNation from 'components/Pagenation';
import * as PJSearchModal from 'components/project/Search';

const initSearch: tProjectSearch = {
  status: [],
  load_datetime_from: changeDateFromTypeDate(
    mathDateTime(new Date(), [0, -1, 0, 0, 0, 0])
  ),
  load_datetime_to: changeDateFromTypeDate(
    mathDateTime(new Date(), [0, 1, 0, 0, 0, 0])
  ),
  unload_datetime_from: null,
  unload_datetime_to: null,
  c_id: null,
  kind: null,
};

export default function Main() {
  const [projects, setProjects] = useState<tProject[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [search, setSearch] = useState<tProjectSearch>(initSearch);

  const getSearch = async (page: number) => {
    await getProjects(page, search).then(
      (res: {
        data: { data: tProject[]; current_page: number; last_page: number };
      }) => {
        setProjects(res.data.data);
        setCurrentPage(res.data.current_page);
        setTotalPages(res.data.last_page);
      }
    );
  };

  const handleClickSearch = ({ terms }: PJSearchModal.callbackProps) => {
    setSearch(terms);
    getSearch(1);
  };

  useEffect(() => {
    getSearch(1);
  }, []);

  return (
    <TableFrame
      HeadContent={
        <HeadContent
          search={search}
          setSearch={setSearch}
          searchCallbackSuccess={handleClickSearch}
        />
      }
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows projects={projects} />}
      SubContent={
        <SubContent
          totalPages={totalPages}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
}

interface HeadContentProps {
  search: tProjectSearch;
  setSearch: React.Dispatch<React.SetStateAction<tProjectSearch>>;
  searchCallbackSuccess?: (props: PJSearchModal.callbackProps) => void;
}
const HeadContent = ({
  search,
  setSearch,
  searchCallbackSuccess,
}: HeadContentProps) => {
  return (
    <>
      <PJBtn.ShowSearchModal
        values={search}
        setValues={setSearch}
        callbackSuccess={searchCallbackSuccess}
      />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>取引先</TableCell>
      <TableCell>初回積地情報</TableCell>
      <TableCell>最終卸地情報</TableCell>
      <TableCell>ステータス</TableCell>
      <TableCell>区分</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  projects: tProject[];
}
const TableBodyRows = ({ projects }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  return (
    <>
      {projects.map((project) => {
        return (
          <TableRow
            key={`project-list-${project.id}`}
            onDoubleClick={() => navigate(`/project/edit/${project.id}`)}
          >
            <TableCell>{getTorisakiName(project)}</TableCell>
            <TableCell>
              <li>{strDateOrigin(project.load_datetime)}</li>
              <li>{getLoadName(project)}</li>
            </TableCell>
            <TableCell>
              <li>{strDateOrigin(project.unload_datetime)}</li>
              <li>{getUnloadName(project)}</li>
            </TableCell>
            <TableCell>{getStatusLabel(project.status)}</TableCell>
            <TableCell>{project.project_kind?.name}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

interface SubContentProps {
  totalPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({
  totalPages,
  currentPage,
  getSearch,
}: SubContentProps) => {
  return (
    <PageNation
      totalPages={totalPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};
