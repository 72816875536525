import React, { useEffect, useContext } from 'react';
import * as Atoms from 'components/customInvocie/Atom';
import { tCustomInvoiceEdit } from 'types/invoice';
import { Grid } from '@mui/material';
import log from 'functions/logger';
import { MstDataContext } from 'contexts/Mst';

interface MainProps {
  data: tCustomInvoiceEdit;
  setData: React.Dispatch<React.SetStateAction<tCustomInvoiceEdit>>;
}
const Main = ({ data, setData }: MainProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  useEffect(() => {
    const tax = SYSTEM?.tax.list.find((item) => item.id === data.tax_id);
    log.debug('Main.tsx', 'useEffect', 'data', data);
    const price = data.unit_price * data.quantity;
    const priceTax = tax ? price * tax.tax : 0;
    const priceTaxInclude = price + priceTax;

    setData((prev) => ({
      ...prev,
      price: price,
      price_tax: priceTax,
      price_tax_include: priceTaxInclude,
    }));
  }, [data.unit_price, data.quantity, data.tax_id, setData]);

  return (
    <Grid container spacing={2}>
      <Atoms.ClientCompany values={data} setValues={setData} />
      <Atoms.Date values={data} setValues={setData} />
      <Atoms.Label values={data} setValues={setData} />
      <Atoms.UnitPrice values={data} setValues={setData} />
      <Atoms.Quantity values={data} setValues={setData} />
      <Atoms.Unit values={data} setValues={setData} />
      <Atoms.Price values={data} setValues={setData} />
      <Atoms.Tax values={data} setValues={setData} />
      <Atoms.PriceTax values={data} setValues={setData} />
      <Atoms.PriceTaxInclude values={data} setValues={setData} />
      <Atoms.Memo values={data} setValues={setData} />
    </Grid>
  );
};

export default Main;
