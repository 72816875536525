import React, {
  useState,
  useRef,
  useCallback,
  FocusEvent,
  KeyboardEvent,
  useEffect,
} from 'react';
import { getGridSize } from 'functions/index';
import { Grid } from '@mui/material';
import { typeHashValue } from 'types';
import { SmallCheckbox } from 'atoms/Checkbox';
import { SmallTextField } from 'atoms/TextField';
import { toHalfWidth, formatNumber } from 'functions/index';
import { DateInput } from 'atoms/DateInput';

//////////////////////////////////////// textfield

interface GridItemTextFieldProps {
  name: string;
  label: string;
  value: typeHashValue;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
}
export function GridItemTextField({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemTextFieldProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          name: name,
          value: value,
          onChange: handleChangeValues,
        }}
      />
    </Grid>
  );
}

interface GridItemRowTextFieldProps {
  name: string;
  label: string;
  value: typeHashValue;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  row?: number;
}
export function GridItemRowTextField({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
  row = 4,
}: GridItemRowTextFieldProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          name: name,
          value: value,
          onChange: handleChangeValues,
          multiline: true,
          rows: row,
        }}
      />
    </Grid>
  );
}

interface GridItemNumberProps {
  name: string;
  label: string;
  value: number;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  callback?: (val: number) => void;
  //handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
  flgFloat?: boolean;
  disabled?: boolean;
}
export function GridItemNumberField({
  name,
  label,
  value,
  setValues,
  callback,
  size = { xs: 4, lg: 1.5, xl: 1 },
  flgFloat = false,
  disabled = false,
}: GridItemNumberProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  const [viewVal, setViewVal] = useState<string>('');

  const changeNumber = (val: string): string => {
    val = toHalfWidth(val); // 全角を半角に変換

    if (flgFloat) {
      // 数値とドット以外の文字を削除
      val = val.replace(/[^0-9.]/g, '');

      // 小数点が複数ある場合、最初の1つだけ残す
      const dotIndex = val.indexOf('.');
      if (dotIndex !== -1) {
        val =
          val.slice(0, dotIndex + 1) +
          val.slice(dotIndex + 1).replace(/\./g, '');
      }
    } else {
      // 整数モード: 数値以外の文字を削除
      val = val.split('.')[0].replace(/[^0-9]/g, '');
    }

    // 数値に変換できない場合、空文字を返す
    if (!isNaN(Number(val))) {
      return val;
    } else {
      return '';
    }
  };

  const changeFormatNumber = (numStr: string): void => {
    const valNum = changeNumber(numStr);

    if (callback) {
      callback(Number(valNum));
    } else {
      setValues((prev: any) => {
        return { ...prev, [name]: valNum };
      });
    }

    // 小数点以下2桁にフォーマットして表示
    setViewVal(
      formatNumber(
        flgFloat ? parseFloat(valNum).toFixed(2) : valNum.split('.')[0]
      )
    );
  };

  useEffect(() => {
    // 初期値をフォーマットして表示
    setViewVal(
      formatNumber(
        flgFloat
          ? parseFloat(value.toString()).toFixed(2)
          : value.toString().split('.')[0]
      )
    );
  }, [value]);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          name: name,
          value: viewVal,
          disabled: disabled,
          onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            changeFormatNumber(e.target.value);
          },
          onFocus: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setViewVal(changeNumber(e.target.value));
          },
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            setViewVal(e.target.value);
          },
          onKeyPress: (e: KeyboardEvent<HTMLInputElement>) => {
            const char = e.key;
            if (char === 'Enter') {
              const temp = e as unknown as FocusEvent<
                HTMLInputElement | HTMLTextAreaElement
              >;
              // フォーカスを外す
              temp.target.blur();
            }
          },
        }}
      />
    </Grid>
  );
}

interface GridItemDateFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (value: string) => void;
  size?: Record<string, number> | null;
}
export function GridItemDateField({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemDateFieldProps) {
  const [xs, sm, md, lg, xl] = getGridSize(size);

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <DateInput
        type="date"
        label={label}
        value={value as string}
        name={name}
        onDateChange={handleChangeValues}
      />
    </Grid>
  );
}

interface GridItemTimeFieldProps {
  name: string;
  label: string;
  value: string;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: Record<string, number> | null;
}
export const GridItemTimeField = ({
  name,
  label,
  value,
  handleChangeValues,
  size = undefined,
}: GridItemTimeFieldProps) => {
  const [xs, lg, xl] = getGridSize(size);

  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <SmallTextField
        label={label}
        props={{
          type: 'time',
          name: name,
          value: value,
          onChange: handleChangeValues,
        }}
      />
    </Grid>
  );
};

export function GridItemTextFieldCustom({
  children,
  size,
}: {
  children: React.ReactNode;
  size?: Record<string, number> | null;
}) {
  const [xs, sm, md, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      {children}
    </Grid>
  );
}

//////////////////////////////////////// checkbox
interface GridItemCheckboxProps {
  labelId?: string;
  id: string;
  name: string;
  label: string;
  value: boolean;
  handleChangeValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  size?: Record<string, number> | null;
}
export function GridItemCheckbox({
  labelId = '',
  id,
  name,
  label,
  value,
  handleChangeValues,
  size,
}: GridItemCheckboxProps) {
  const [xs, lg, xl] = getGridSize(size);
  return (
    <Grid item xs={xs} lg={lg} xl={xl}>
      <SmallCheckbox
        labelId={labelId}
        label={label}
        props={{
          id: id,
          name: name,
          checked: value,
          onChange: handleChangeValues,
        }}
      />
    </Grid>
  );
}
