import { List, ListItem } from 'types';

export const projectStatusQuotation: ListItem = {
  id: 0,
  label: '見積中',
};

export const projectStatusOrdered: ListItem = {
  id: 10,
  label: '受注済',
};

export const projectStatusInstrunctionCreated: ListItem = {
  id: 20,
  label: '運行指示作成済み',
};

export const projectStatusDelivered: ListItem = {
  id: 30,
  label: '運送済み',
};

export const projectStatusPending: ListItem = {
  id: 40,
  label: '処理待ち',
};

export const projectStatusRemant: ListItem = {
  id: 41,
  label: '差戻し',
};

export const projectStatusChecked: ListItem = {
  id: 49,
  label: 'チェック済み',
};

export const projectStatusClosed: ListItem = {
  id: 50,
  label: '締め処理済み',
};

export const projectStatusCharged: ListItem = {
  id: 59,
  label: '請求済み',
};

export const projectStatusCompleted: ListItem = {
  id: 90,
  label: '完了系',
};

export const projectStatusLost: ListItem = {
  id: 99,
  label: '失注',
};

export const projectStatus: List = [
  projectStatusQuotation,
  projectStatusOrdered,
  projectStatusInstrunctionCreated,
  projectStatusDelivered,
  projectStatusChecked,
  projectStatusClosed,
  projectStatusCompleted,
  projectStatusLost,
];

export const projectEditStatus: List = [
  projectStatusQuotation,
  projectStatusOrdered,
];
