import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Box, Button, Paper, useTheme } from '@mui/material';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { expensesColumns } from 'const/project/table';
import { TableCellTextField } from 'atoms/TextField';
import Modal from 'atoms/Modal';

import { typeHash, typeIndexTable } from 'types';
import {
  tProject,
  initProject,
  tWaypoints,
  initWaypoints,
} from 'types/project';
import { ButtonContainer } from 'atoms/Button';
import { getProject } from 'functions/api/project';
import { tErrMsg, ErrMsgBox } from 'components/ErrorMessage';
import Details from 'components/project/Detail';
import * as PJButton from 'components/project/Button';
import * as InstButton from 'components/instruction/Button';
import { MstDataContext } from 'contexts/Mst';

export default function Main() {
  const theme = useTheme();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { SYSTEM } = useContext(MstDataContext);

  const [open, setOpen] = useState(false);
  const [errMsg, setErrMsg] = useState<tErrMsg>({});

  const defKind = Number(searchParams.get('kind')) ?? 0;
  const tempProjectInfo = {
    ...initProject,
    kind: defKind,
    tax_id: SYSTEM?.tax.default.id || 0,
  };
  const [projectInfo, setProjectInfo] = useState<tProject>(tempProjectInfo);

  // const defDate = searchParams.get('date') ?? new Date().toISOString();

  // 積卸地
  const [waypoints, setWaypoints] = useState<tWaypoints>(initWaypoints);

  // その他金額
  // const [priceOther, setPriceOther] = useState<tPriceOther[]>([]);

  // 経費テーブル
  const [expenseInfo, setExpenseInfo] = useState<typeIndexTable>([
    expensesColumns.reduce(
      (acc, column) => ({ ...acc, [column.field]: '' }),
      {}
    ),
  ]);

  /*
  const isEmptyObject = (obj: object) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  */

  /**
   * 案件情報取得処理
   * @param id
   */
  const getPj = (id: string | number) => {
    getProject(id)
      .then((res) => {
        setProjectInfo(res.data);
        setWaypoints(res.data.waypoints);
      })
      .catch((err) => {
        console.error(err);
        alert('データ取得処理に失敗しました。');
      });
  };

  /**
   * プロジェクト情報取得
   */
  useEffect(() => {
    if (id) {
      getPj(id);
    } else {
      tempProjectInfo.status = 10; // 受注済みにする
      setProjectInfo(tempProjectInfo);
      setWaypoints(initWaypoints);
    }
  }, [id]);

  return (
    <>
      <Modal
        title="経費を入力する"
        open={open}
        onClose={() => setOpen(false)}
        actions={
          <ButtonContainer>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(false)}
            >
              閉じる
            </Button>
          </ButtonContainer>
        }
      >
        <ModalContent
          id={'expense'}
          projectInfo={expenseInfo}
          setProjectInfo={setExpenseInfo}
        />
      </Modal>
      {Object.keys(errMsg).length > 0 && (
        <Box sx={{ padding: 2 }}>
          <ErrMsgBox errMsg={errMsg} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: theme.spacing(2),
        }}
      >
        {id ? (
          <>
            <PJButton.Update
              projectInfo={projectInfo}
              waypoints={waypoints}
              callbackNomal={() => navigate('/project')}
              callbackError={() => setErrMsg({})}
            />
            <InstButton.ModalInstruction pjId={Number(id)} />
            <PJButton.Delete
              pjId={projectInfo.id}
              callbackNomal={() => navigate('/project')}
              callbackError={() => setErrMsg({})}
            />
          </>
        ) : (
          <>
            <PJButton.Insert
              label="登録"
              projectInfo={projectInfo}
              waypoints={waypoints}
              callbackNomal={() => navigate('/project')}
              callbackError={() => setErrMsg({})}
            />
            <PJButton.Insert
              label="続けて登録"
              projectInfo={projectInfo}
              waypoints={waypoints}
              callbackError={() => setErrMsg({})}
            />
          </>
        )}
      </Box>
      <Details
        values={projectInfo}
        setValues={setProjectInfo}
        waypoints={waypoints}
        setWaypoints={setWaypoints}
      />
    </>
  );
}

// 経費を入力するモーダルのコンテンツ
interface ModalContentProps {
  id: string;
  projectInfo: typeIndexTable;
  setProjectInfo: React.Dispatch<typeIndexTable>;
}

function ModalContent({ id, projectInfo, setProjectInfo }: ModalContentProps) {
  const handleAddRow = () => {
    setProjectInfo([
      ...projectInfo,
      expensesColumns.reduce(
        (acc, column) => ({ ...acc, [column.field]: '' }),
        {}
      ),
    ]);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {expensesColumns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {projectInfo.map((row: typeHash, rowIndex: number) => (
              <TableRow key={rowIndex}>
                {expensesColumns.map((column) => (
                  <TableCell key={column.field}>
                    <TableCellTextField
                      props={{
                        name: `${id}[${rowIndex}][${column.field}]`,
                        value: row[column.field],
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                          projectInfo[rowIndex][column.field] = e.target.value;
                          setProjectInfo([...projectInfo]);
                        },
                      }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        color={'secondary'}
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleAddRow}
      >
        行追加
      </Button>
    </>
  );
}
