import React, { useState, useEffect, useContext } from 'react';
import InstrcutionThemeProvider from 'themes/InstructionTheme';
import Modal from 'atoms/Modal';
import InstructionDetail from 'components/instruction/Detail';
import { tInstruction } from 'types/instruction';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import * as InstButton from 'components/instruction/Button';
import { tAddress } from 'types/address';
import { getInstructionList } from 'functions/api/instruction';
import { initAddress } from 'const/address/index';
import initInstruction from 'const/instruction/index';
import { MstDataContext } from 'contexts/Mst';
import log from 'functions/logger';
import { tProject } from 'types/project';
import { getProject } from 'functions/api/project';
import { tTransportKey } from 'types/transport';

interface MainProps {
  open: boolean;
  onClose: () => void;
  tranKey: tTransportKey;
  info: tInstruction;
  flgProduction?: boolean;
  callbackTmpNomal?: (() => void) | undefined;
  callbackNomal?: ((data: tInstruction[]) => void) | undefined;
  callbackError?: (() => void) | undefined;
}
export default React.memo(function Main({
  open,
  onClose,
  tranKey,
  info,
  flgProduction,
  callbackTmpNomal = undefined,
  callbackNomal = undefined,
  callbackError = undefined,
}: MainProps) {
  log.debug('Instruction Modal', info);
  const { SYSTEM } = useContext(MstDataContext);

  const [selectedInstIdx, setSelectedInsIdx] = useState(0);
  // 入力内容
  const [values, setValues] = useState<tInstruction>(info || initInstruction);

  //
  const [startAddress, setStartAddress] = useState<tAddress>(initAddress);
  const [endAddress, setEndAddress] = useState<tAddress>(initAddress);

  const [instructions, setInstructions] = useState<tInstruction[]>([]);
  /*
  useEffect(() => {
    log.debug('Instruction Modal useEffect.selectedInstIdx', [
      selectedInstIdx,
      instructions,
    ]);
  }, [instructions]);

  useEffect(() => {
    log.debug('Instruction Modal useEffect.selectedInstIdx', [
      selectedInstIdx,
      instructions,
    ]);
  }, [selectedInstIdx]);
  */

  // 案件情報の取得
  const [project, setProject] = useState<tProject | undefined>(undefined);
  useEffect(() => {
    if (!tranKey.pj_id) {
      setProject(undefined);
      setSelectedInsIdx(0);
      setValues(initInstruction);
      setInstructions([]);
      setStartAddress(initAddress);
      setEndAddress(initAddress);
    } else {
      getProject(tranKey.pj_id)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error('案件情報の取得に失敗しました');
          }
          setProject(res.data);
        })
        .catch((err) => {
          alert('案件情報の取得に失敗しました。');
        });

      getInsList();
    }
  }, [tranKey]);

  /**
   * ドライバーが変更された場合の処理
   */
  useEffect(() => {
    setValues(info || initInstruction);
  }, [info, SYSTEM]);

  /**
   * データ取得処理
   */
  const getInsList = () => {
    getInstructionList(
      (data) => {
        if (data.length > 0) {
          setInstructions(data);
          setSelectedInsIdx(data.length);
        } else {
          setInstructions([]);
          setSelectedInsIdx(0);
        }
      },
      undefined,
      { instruction: tranKey }
    );
  };

  /**
   * 次へボタンクリック時の処理
   */
  const handleConfirmClick = () => {
    //log.debug('Instruction Modal handleConfirmClick', values);
    // 入力欄の初期化
    setStartAddress({ ...endAddress });
    setValues((prev: any) => {
      return {
        ...prev,
        tm_id: SYSTEM?.tranMethod.own.id || 0,
        start_datetime: values.end_datetime,
        start_a_id: values.end_a_id,
        start_name: values.end_name,
        start_prefectures: values.end_prefectures,
        start_city: values.end_city,
        start_street: values.end_street,
        start_building: values.end_building,
        start_trailer_v_id: values.end_trailer_v_id,

        end_datetime: values.end_datetime,
        end_a_id: 0,
        end_name: '',
        end_prefectures: '',
        end_city: '',
        end_street: '',
        end_building: '',
        end_trailer_v_id: null,
      };
    });
    setEndAddress(initAddress);
    setSelectedInsIdx(selectedInstIdx + 1);

    if (callbackTmpNomal) {
      callbackTmpNomal();
    }
  };

  const callbackInserts = () => {
    getInsList();
    if (callbackNomal) {
      callbackNomal(instructions);
    }
  };

  //log.debug('Instruction Modal', values.user_id);
  return (
    <InstrcutionThemeProvider>
      <Modal
        width={'lg'}
        title={`運行指示`}
        open={open}
        onClose={onClose}
        actions={
          <>
            <PrimaryButton onClick={onClose} label={'完走'} />
            <InstButton.MakeCompEvening
              label={'宵積 完走'}
              defInstruction={{
                ...info,
                ...tranKey,
                tm_id: values.tm_id,
                user_id: values.user_id,
                v_id: values.v_id,
              }}
              datetime={values.start_datetime}
              lastWaypoint={
                project?.waypoints[project.waypoints.length - 1] || undefined
              }
              lastInstruction={
                instructions[instructions.length - 1] || undefined
              }
              instructions={instructions}
              setInstructions={setInstructions}
              callbackSuccess={callbackInserts}
            />
            <PrimaryButton onClick={onClose} label={'宵積 切替'} />
            <PrimaryButton onClick={onClose} label={'南港行き'} />
            <PrimaryButton onClick={onClose} label={'東予行き'} />
            <InstButton.InsertTmp
              values={values}
              startAddress={startAddress}
              endAddress={endAddress}
              tranKey={tranKey}
              selectedInstIdx={selectedInstIdx}
              setInstructions={setInstructions}
              callbackNomal={handleConfirmClick}
              callbackError={callbackError}
            />
            <InstButton.Inserts
              instructions={instructions}
              tranKey={tranKey}
              CallbackSuccess={() => callbackInserts}
            />
            <SecondButton onClick={onClose} label={'閉じる'} />
          </>
        }
      >
        <InstructionDetail
          project={project}
          tranNo={tranKey.no}
          selectedIdx={selectedInstIdx}
          setSelectedIdx={setSelectedInsIdx}
          values={values}
          setValues={setValues}
          instructions={instructions}
          setInstructions={setInstructions}
          startAddress={startAddress}
          setStartAddress={setStartAddress}
          endAddress={endAddress}
          setEndAddress={setEndAddress}
          closeCallback={() => onClose}
          initInstCustom={info}
        />
      </Modal>
    </InstrcutionThemeProvider>
  );
});
