import React, { forwardRef } from 'react';
import {
  Box,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface MainProps {
  HeadContent: React.ReactNode;
  TableHeaderRows: React.ReactNode;
  TableBodyRows: React.ReactNode;
  SubContent: React.ReactNode;
}
const Main = forwardRef<HTMLTableSectionElement, MainProps>(
  (
    { HeadContent: Buttons, TableHeaderRows, TableBodyRows, SubContent },
    ref
  ) => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            flexGrow: 1, // 残りの高さを占める
            transition: 'height 0.3s ease',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 'auto',
                ClassName: 'buttons',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: theme.spacing(2),
                p: theme.spacing(2),
                position: 'sticky',
                top: 0,
                zIndex: 1,
                left: 0,
              }}
            >
              {Buttons}
            </Box>
            <TableContainer
              sx={{
                flexGrow: 1, // 残りの高さを占める
                transition: 'height 0.3s ease',
                overflow: 'auto', // 高さが足りない時にスクロールバーを表示
              }}
            >
              <Table className={`tableframe-table`} stickyHeader>
                <TableHead>{TableHeaderRows}</TableHead>
                <TableBody ref={ref}>{TableBodyRows}</TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Container
          sx={{
            height: 'auto',
            backgroundColor: theme.palette.common.white,
            transition: 'height 0.3s ease',
            //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
          }}
        >
          {SubContent}
        </Container>
      </Box>
    );
  }
);

Main.displayName = 'TableFrame';

export default Main;
