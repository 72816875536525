import React, { useState } from 'react';
import { PrimaryButton } from 'atoms/Button';
import { tCompany, tCompanySearch } from 'types/company';
import SearchModal from 'components/company/Search';
import { getCompanies } from 'functions/api/mst';
import { apiGetIndexSuccess } from 'types/index';
import zIndex from '@mui/material/styles/zIndex';
import log from 'functions/logger';

interface SearchBtnProps {
  setData: React.Dispatch<React.SetStateAction<tCompany[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tCompanySearch>>;
}
export const SearchBtn = ({
  setData,
  setCurrentPage,
  setLastPage,
  setFilter,
}: SearchBtnProps) => {
  const [open, setOpen] = useState(false);
  const handleClickSearch = () => {
    log.debug('handleClickSearch');
    setOpen(true);
  };

  const callbackGetCompanies = ({
    data,
    currentPage,
    lastPage,
    filter,
  }: apiGetIndexSuccess) => {
    setData(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setFilter(filter);
  };

  const callbackSearch = (filter: tCompanySearch) => {
    getCompanies({
      page: 1,
      filter: filter,
      callbackSuccess: callbackGetCompanies,
    });
  };

  return (
    <>
      <SearchModal
        open={open}
        setOpen={setOpen}
        callback={(data) => callbackSearch(data)}
      />
      <PrimaryButton label="検索" onClick={handleClickSearch} />
    </>
  );
};
