import { TableFooterClassKey } from '@mui/material';
import { tAddress } from 'types/address';

/**
 * 積地、降地、事務所を指定して、対象の住所を返す
 * @param list
 * @param flgOffice
 * @param flgLoad
 * @param flgUnload
 * @returns
 */
export function getTargetAddress(
  list: tAddress[],
  flgOffice?: boolean,
  flgLoad?: boolean,
  flgUnload?: boolean
) {
  return list.filter((address: tAddress) => {
    return (
      (flgLoad && address.flg_load === flgLoad) ||
      (flgUnload && address.flg_unload === flgUnload) ||
      (flgOffice && address.flg_office === flgOffice)
    );
  });
}
