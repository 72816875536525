import { tAddress, tAddressSearch } from 'types/address';
export const initAddress: tAddress = {
  id: 0,
  c_id: 0,
  name: '',
  abbreviation: '',
  prefectures: '',
  city: '',
  street: '',
  building: '',
  post_number: '',
  tell: '',
  fax: '',
  flg_load: false,
  flg_unload: false,
  flg_office: false,
  internal_memo: '',
};

export const initAddressSearch: tAddressSearch = {
  free_word: null,
  flg_load: null,
  flg_unload: null,
  flg_office: null,
};
