import React from 'react';
import { useTheme } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import { Link } from 'react-router-dom';
import { typeMenu, typeSubMenu } from 'const/menu';
import { useLocation } from 'react-router-dom';

/**
 *
 * @returns
 */
export default function Main({ menu }: { menu: typeMenu }): React.ReactElement {
  const location = useLocation();

  const theme = useTheme();
  const styles = {
    headerMenu: {
      width: '130px',
      maxHeight: '100%',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  };

  return (
    <div style={styles.headerMenu}>
      <List sx={styles.headerMenu}>
        {menu.subMenus.map((subMenu: typeSubMenu) => {
          if (subMenu.flg) {
            //const isSubPage = location.pathname.startsWith(`${subMenu.path}`);
            const isSubPage = location.pathname === `${subMenu.path}`;
            return (
              <ListItemButton
                key={`${subMenu.path}`}
                component={Link}
                to={`${window.location.origin}${subMenu.path}`}
                target={subMenu.aTab ? '_blank' : ''}
              >
                <ListItemText
                  primary={subMenu.label}
                  primaryTypographyProps={{
                    color: theme.palette.text.secondary,
                    fontSize: '1rem',
                    borderBottom: isSubPage ? '2px solid' : 'none',
                  }}
                />
              </ListItemButton>
            );
          }
        })}
      </List>
    </div>
  );
}
