import React, { useState, useEffect } from 'react';
import { tProjectKind, initialProjectKind } from 'types/mst';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import {
  deleteProjectKind,
  getProjectKind,
  storeProjectKind,
  updateProjectKind,
} from 'functions/api/mst';
import { Console_log } from 'functions';
import { GridItemTextField } from 'components/GridItem';
import { PrimaryButton } from 'atoms/Button';

export default function Main() {
  const { id } = useParams();
  const [data, setData] = useState<tProjectKind>(initialProjectKind);

  Console_log('id', id);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        // データ取得
        getProjectKind(id).then((res) => {
          if (res.status !== 200) throw new Error('案件区分取得に失敗しました');
          setData(res.data);
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  // マスタ更新
  const setPostRequestUpdate = () => {
    updateProjectKind(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        window.location.href = '/mst/project-kind';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // マスタ新規登録
  const setPostRequestStore = () => {
    storeProjectKind(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        window.location.href = '/mst/project-kind';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // マスタ削除
  const setPostRequestDelete = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteProjectKind(data.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        window.location.href = '/mst/project-kind';
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestUpdate} label="更新" />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStore} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>

        <GridItemTextField
          name={'name'}
          label="名称"
          value={data.name}
          handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
            setData({ ...data, name: e.target.value })
          }
        />
      </Grid>
    </div>
  );
}
