import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { handleChangeValues } from 'functions/handles';
import { List, ListItem } from 'types/index';
import { GridItemSelect } from 'atoms/Select';
import {
  GridItemNumberField,
  GridItemTextField,
  GridItemCheckbox,
  GridItemRowTextField,
  GridItemTimeField,
  GridItemTextFieldCustom,
  GridItemDateField,
} from 'components/GridItem';
import { AutocompleteSelect } from 'atoms/Select';
import log from 'functions/logger';

interface ItemFieldProps {
  label: string;
  name?: string;
  value?: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  size?: Record<string, number> | null;
  disabled?: boolean;

  // RowTextFiled用
  row?: number;

  // Number用
  flgFloat?: boolean;

  // Select用
  id?: string;
  labelId?: string;
  items?: List;

  // Datetime用
  type?: string;
}

export const ItemTextFiled = ({
  label,
  name = '',
  value = '',
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemTextField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={size}
  />
);

export const ItemRowTextFiled = ({
  label,
  name = '',
  value = '',
  setValues,
  size,
  row = 4,
}: ItemFieldProps) => (
  <GridItemRowTextField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={size}
    row={row}
  />
);

export const ItemNumberField = ({
  label,
  name = '',
  value = '',
  setValues,
  flgFloat = false,
  disabled = false,
}: ItemFieldProps) => (
  <GridItemNumberField
    label={label}
    value={value}
    name={name}
    /*
      handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeValues(e.target.value, e.target.name, setValues);
      }}
        */
    setValues={setValues}
    flgFloat={flgFloat}
    disabled={disabled}
  />
);

export const ItemSelect = ({
  labelId = '',
  name = '',
  label,
  value = 0,
  items = [],
  setValues,
  size = null,
}: ItemFieldProps) => {
  return (
    <GridItemSelect
      labelId={labelId}
      id={name}
      label={label}
      selectedValue={value}
      items={items}
      size={size}
      handleChangeValues={(
        e: SelectChangeEvent<number | string>,
        child: React.ReactNode
      ) => {
        handleChangeValues(e.target.value, name, setValues);
      }}
    />
  );
};

export const ItemCheckbox = ({
  labelId = '',
  name = '',
  label,
  value = false,
  setValues,
  size = null,
}: ItemFieldProps) => {
  return (
    <GridItemCheckbox
      labelId={labelId}
      id={name}
      name={name}
      label={label}
      value={value}
      size={size}
      handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeValues(e.target.checked, name, setValues);
      }}
    />
  );
};

export const ItemDatetime = ({
  labelId = '',
  name = '',
  label,
  value = false,
  setValues,
  size = null,
}: ItemFieldProps) => {
  return (
    <GridItemDateField
      name={name}
      label={label}
      value={value}
      size={size}
      handleChangeValues={(val: string) => {
        handleChangeValues(val, name, setValues);
      }}
    />
  );
};

export const ItemTimeFiled = ({
  label,
  name = '',
  value = '',
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemTimeField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) => {
      log.debug(e.target.value);
      handleChangeValues(e.target.value, name, setValues);
    }}
    size={size}
  />
);

export const ItemAutoComplete = ({
  label,
  name = '',
  setValues,
  size,
  options,
  selectedOption,
  disableClearable = true,
}: ItemFieldProps & {
  options: List;
  selectedOption: ListItem | undefined;
  disableClearable?: boolean;
}) => {
  return (
    <GridItemTextFieldCustom size={size}>
      <AutocompleteSelect
        disableClearable={disableClearable}
        name={name}
        label={label}
        options={options}
        value={selectedOption || null}
        onChange={(
          e: React.SyntheticEvent<Element, Event>,
          newValue: ListItem
        ) => {
          if (!newValue) {
            handleChangeValues(null, name, setValues);
            return;
          }
          handleChangeValues(newValue.id, name, setValues);
        }}
      />
    </GridItemTextFieldCustom>
  );
};
