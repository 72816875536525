import React, { useContext, useState, useEffect } from 'react';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import Modal from 'atoms/Modal';
import { tAddress } from 'types/address';
import { tInstruction, tInstSearch, tInstructionCard } from 'types/instruction';
import { useValidate } from 'functions/instruction/validate';
import { tErrMsg } from 'components/ErrorMessage';
import ErrModal from 'components/ErrModal';
import { MstDataContext } from 'contexts/Mst';
import { tTransport } from 'types/transport';
import * as handle from 'functions/instruction/handle';
import SearchModal from 'components/instruction/Search';
import { getInstructionCards, update } from 'functions/api/instruction';
import { getTransportList } from 'functions/api/transport';
import { tOrder, apiListProps } from 'types';
import initSearch, {
  initOrder as initInstOrder,
} from 'const/instruction/search';
import InstructionModal from 'components/instruction/Modal';
import initInstruction from 'const/instruction';
import log from 'functions/logger';
import { tProject, tWaypoint } from 'types/project';
import { tTransportKey } from 'types/transport';

interface ConfirmProps {
  values: any;
  startAddress: tAddress;
  endAddress: tAddress;
  tranKey: tTransportKey;
  selectedInstIdx: number;
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  callbackNomal?: (() => void) | undefined;
  callbackError?: (() => void) | undefined;
}

/**
 * setInstructionsに入力内容を追加するボタン
 * @param param0
 * @returns
 */
export const InsertTmp = ({
  values,
  startAddress,
  endAddress,
  tranKey,
  selectedInstIdx,
  setInstructions,
  callbackNomal,
}: ConfirmProps) => {
  const { SYSTEM } = useContext(MstDataContext);
  const { validate } = useValidate();
  const [showModal, setShowModal] = React.useState(false);
  const [validationMsg, setValidationMsg] = React.useState<tErrMsg>({});

  /**
   * 入力内容を確定し、送信内容に追加
   * @param tranKey
   * @param values
   * @param loadAddress
   * @param unloadAddress
   * @param setInstructions
   * @returns
   */
  const hadleClick = (
    tranKey: tTransportKey,
    selectedInstIdx: number,
    values: any,
    loadAddress: tAddress,
    unloadAddress: tAddress,
    setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>
  ) => {
    log.debug('SecondButton instruction', values);

    // loadAddressをvaluesに追加
    values.start_a_id = loadAddress.id;
    values.start_name = loadAddress.name;
    values.start_prefectures = loadAddress.prefectures;
    values.start_city = loadAddress.city;
    values.start_street = loadAddress.street;
    values.start_building = loadAddress.building;

    // unloadAddressをvaluesに追加
    values.end_a_id = unloadAddress.id;
    values.end_name = unloadAddress.name;
    values.end_prefectures = unloadAddress.prefectures;
    values.end_city = unloadAddress.city;
    values.end_street = unloadAddress.street;
    values.end_building = unloadAddress.building;

    // バリデーション
    let errMsg: tErrMsg = {};
    errMsg = validate(values);

    // エラーメッセージ
    if (Object.keys(errMsg).length > 0) {
      setValidationMsg(errMsg);
      setShowModal(true);
      return;
    }

    // 送信用データに追加
    if (selectedInstIdx >= 0) {
      log.debug('SecondButton instruction update', selectedInstIdx);
      setInstructions((prev) => {
        if (prev.length > selectedInstIdx) {
          return prev.map((instruction, idx) =>
            idx === selectedInstIdx
              ? {
                  ...instruction,
                  ...values, // 更新したい値をここに展開
                }
              : instruction
          );
        } else {
          return [
            ...prev,
            {
              ...values,
              pj_id: tranKey.pj_id,
              no: tranKey.no,
            },
          ];
        }
      });
    }
    if (callbackNomal) {
      callbackNomal();
    }
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <SecondButton
        label="次へ"
        onClick={() => {
          hadleClick(
            tranKey,
            selectedInstIdx,
            values,
            startAddress,
            endAddress,
            setInstructions
          );
        }}
      />
    </>
  );
};

interface InsertProps {
  instructions: tInstruction[];
  tranKey: tTransportKey;
  CallbackSuccess: () => void;
}
/**
 * データ登録ボタン
 * ※運行指示は全て新規登録、更新はない
 */
export const Inserts = (props: InsertProps) => {
  return <PrimaryButton label="確定" onClick={() => handle.inserts(props)} />;
};

interface CompEveningProps {
  label: string;
  defInstruction: tInstruction;
  datetime: string;
  lastWaypoint: tWaypoint | undefined;
  lastInstruction: tInstruction;
  instructions: tInstruction[];
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  callbackSuccess?: ((data: tInstruction[]) => void) | undefined;
}

/**
 * 宵積完走の運行指示を作成し、登録するボタン
 * @param props
 * @returns
 */
export const MakeCompEvening = (props: CompEveningProps) => {
  const callback = (data: tInstruction[]) => {
    if (props.callbackSuccess) {
      props.callbackSuccess(data);
    }
  };

  const handleClck = () => {
    log.debug('MakeCompEvening click', props);
    const insts = handle.makeCompEvening(props); // 宵積完走の運行指示を作成
    handle.inserts({
      instructions: insts,
      tranKey: { pj_id: insts[0].pj_id, no: insts[0].no },
      callbackSuccess: callback,
    });
  };
  return <PrimaryButton label={props.label} onClick={handleClck} />;
};

interface SearchModalProps {
  initTerms?: tInstSearch;
  callbackSearch?: ((filter: tInstSearch) => void) | undefined;
  trigger?: string;
}

/**
 * 運行指示検索モーダル 成功したらsetValuesに値をセット
 * @param param0
 * @returns
 */
export const ShowSearchModal = ({
  initTerms = initSearch,
  callbackSearch = undefined,
  trigger = '',
}: SearchModalProps) => {
  const [searchInst, setSearchInst] = useState<tInstSearch>(initTerms);
  const [openSearch, setOpenSearch] = useState(false);

  const handleClickSearch = () => {
    setOpenSearch(false);
    if (callbackSearch) {
      callbackSearch(searchInst);
    }
    /*
    getInstructionCards({
      filter: searchInst,
      order: initOrder,
      withs: ['address'],
      callbackSuccess: (data: tInstSearch) => {
        setValues(data);
        if (callbackSearch) {
          callbackSearch(searchInst, data);
        }
      },
    });
    */
  };

  useEffect(() => {
    handleClickSearch();
  }, [trigger]);

  return (
    <>
      <SearchModal
        open={openSearch}
        onClose={() => setOpenSearch(false)}
        callbackSearch={() => {
          handleClickSearch();
        }}
        values={searchInst}
        setValues={setSearchInst}
      />
      <PrimaryButton label="検索" onClick={() => setOpenSearch(true)} />
    </>
  );
};

export const Update = ({
  label,
  id,
  data,
  callbackSuccess = undefined,
  callbackError = undefined,
  flg = true,
}: {
  label: string;
  id: tInstruction['id'];
  data: any;
  callbackSuccess?: (() => void) | undefined;
  callbackError?: (() => void) | undefined;
  flg?: boolean;
}) => {
  const handleClck = () => {
    log.debug('UpdateStatus', id, data);
    update(id, data, callbackSuccess, callbackError);
  };

  return <PrimaryButton label={label} onClick={handleClck} disabled={!flg} />;
};

interface ModalInstructionProps {
  pjId: tProject['id'];
}
/**
 * 運行指示詳細モーダル
 * @param param0
 * @returns
 */
export const ModalInstruction = ({ pjId }: ModalInstructionProps) => {
  const [flgInstModal, setFlgInstModal] = useState(false);
  const [selectTranNo, setSelectTranNo] = useState(1);
  const [flgInstSelectModal, setFlgInstSelectModal] = useState(false);
  const [transports, setTransports] = useState<tTransport[]>([]);

  const handleClick = () => {
    getTransportList({ pj_id: pjId })
      .then((res) => {
        if (res.status !== 200) throw new Error('API Error');
        setTransports(res.data);

        if (res.data.length === 1) {
          setFlgInstModal(true);
          setSelectTranNo(selectTranNo);
        } else {
          setFlgInstSelectModal(true);
        }
      })
      .catch((err) => {
        console.error(err);
        alert('運行指示情報の取得に失敗しました');
      });
  };

  return (
    <>
      <Modal
        title={`配車対象選択`}
        open={flgInstSelectModal}
        onClose={() => setFlgInstSelectModal(false)}
        actions={
          <>
            <SecondButton
              onClick={() => setFlgInstSelectModal(false)}
              label={'閉じる'}
            />
          </>
        }
      >
        {transports.map((tran: tTransport) => (
          <PrimaryButton
            key={`transport-${tran.no}`}
            onClick={() => {
              setFlgInstSelectModal(false);
              setSelectTranNo(tran.no);
              setFlgInstModal(true);
            }}
            label={String(tran.no)}
          />
        ))}
      </Modal>

      <InstructionModal
        open={flgInstModal}
        onClose={() => setFlgInstModal(false)}
        tranKey={{ pj_id: pjId, no: selectTranNo }}
        info={initInstruction}
      />
      <SecondButton label="配車手配" onClick={handleClick} />
    </>
  );
};
