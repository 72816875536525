import React from 'react';
import Modal from 'atoms/Modal';
import { PrimaryButton, SecondButton } from 'atoms/Button';
import { tInstSearch } from 'types/instruction';
import { Grid, Typography } from '@mui/material';
import { DateInput } from 'atoms/DateInput';
import { mathDateTime, changeDateFromTypeDate } from 'functions/time';

const Search = ({
  open,
  onClose,
  callbackSearch,
  values,
  setValues,
}: {
  open: boolean;
  onClose: () => void;
  callbackSearch: () => void;
  values: tInstSearch;
  setValues: React.Dispatch<React.SetStateAction<tInstSearch>>;
}) => {
  return (
    <Modal
      width={'lg'}
      title={`運行指示検索`}
      open={open}
      onClose={onClose}
      actions={
        <>
          <PrimaryButton onClick={callbackSearch} label={'検索'} />
          <SecondButton onClick={onClose} label={'閉じる'} />
        </>
      }
    >
      <RangeStartDatetime values={values} setValues={setValues} />
    </Modal>
  );
};

export default Search;

/**
 * 初回積日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeStartDatetime({
  values,
  setValues,
}: {
  values: tInstSearch;
  setValues: React.Dispatch<React.SetStateAction<tInstSearch>>;
}) {
  return (
    <>
      <Typography variant="h6">開始日</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.start_datetime_from || ''}
            name="start_datetime_from"
            onDateChange={(newDate: string) => {
              const Add7Day = mathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                start_datetime_from: newDate,
                start_datetime_to: changeDateFromTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: 'center' }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.start_datetime_to || ''}
            name="start_datetime_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, start_datetime_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}
