import React from 'react';
import * as cCommon from 'components/Common';

interface GridItemFieldProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}

export const Freewords = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="フリーワード"
    name="freewords"
    value={values.freewords}
    setValues={setValues}
    size={{ xs: 12, lg: 10, xl: 8 }}
  />
);
