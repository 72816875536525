/**
 * 運行指示一覧表示
 *
 *  */
import React, { useContext, useEffect, useState } from 'react';
import { tProject } from 'types/project';
import {
  tInstruction,
  tInstSearch,
  cbGetInstructionCards,
} from 'types/instruction';
import { tInstructionCard } from 'types/instruction';
import { TableRow, TableCell, Typography } from '@mui/material';
import PJModal from 'components/project/Modal';
import * as InstButton from 'components/instruction/Button';
import TableFrame from 'frames/TableFrame';
import { MstDataContext } from 'contexts/Mst';
import Loading from 'atoms/Loading';
import { SecondButton } from 'atoms/Button';
import { useNavigate } from 'react-router-dom';
import PageNation from 'components/Pagenation';
import { getInstructionCards } from 'functions/api/instruction';
import initFilter from 'const/instruction/search';
import { apiGetIndexSuccess, apiGetIndexProps } from 'types/index';
import { searchIndex } from 'functions/instruction';

export default function Main() {
  const [trigger, setTrigger] = useState('');
  const [filter, setFilter] = useState<tInstSearch>(initFilter);
  const [instructions, setInstructions] = useState<tInstructionCard[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedPJID, setSelectedPJID] = useState<tProject['id']>();

  const callbackSearch = ({
    data,
    currentPage,
    lastPage,
    filter,
  }: cbGetInstructionCards) => {
    setInstructions(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setTotalPages(lastPage);
    setFilter(filter);
  };

  const getSearch = (page: number) => {
    searchIndex(page, filter, callbackSearch);
  };

  useEffect(() => {
    getSearch(currentPage);
  }, [trigger, filter]);

  return (
    <>
      <PJModal
        open={selectedPJID !== undefined}
        onClose={() => setSelectedPJID(undefined)}
        pjId={selectedPJID ? selectedPJID : 0}
        callbackNomal={() => {
          setTrigger(Math.random().toString());
        }}
      />
      <TableFrame
        HeadContent={<HeadContent setFilter={setFilter} trigger={trigger} />}
        TableHeaderRows={
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>輸送方法</TableCell>
            <TableCell>案件ID</TableCell>
            <TableCell>開始情報</TableCell>
            <TableCell>終了情報</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>実績</TableCell>
          </TableRow>
        }
        TableBodyRows={
          <DataRows
            instructions={instructions}
            setSelectedPJID={setSelectedPJID}
          />
        }
        SubContent={
          <SubContent
            getSearch={getSearch}
            totalPages={totalPages}
            currentPage={currentPage}
          />
        }
      />
    </>
  );
}

interface HeadContentProps {
  setFilter: React.Dispatch<React.SetStateAction<tInstSearch>>;
  trigger: string;
}
const HeadContent = ({ setFilter, trigger }: HeadContentProps) => {
  return (
    <>
      <InstButton.ShowSearchModal
        callbackSearch={(data: tInstSearch) => setFilter(data)}
        trigger={trigger}
      />
    </>
  );
};

interface DataRowsProps {
  instructions: tInstructionCard[];
  setSelectedPJID: React.Dispatch<
    React.SetStateAction<tProject['id'] | undefined>
  >;
}
const DataRows = ({ instructions, setSelectedPJID }: DataRowsProps) => {
  const navigate = useNavigate();
  const handleDoubleClick = (id: tInstructionCard['id']) => {
    if (id) {
      navigate(`/operation/instruction/edit/${id}`);
    }
  };
  return (
    <>
      {instructions.map((instruction: tInstructionCard) => {
        return (
          <TableRow
            key={`instruction-${instruction.pj_id}-${instruction.no}-${instruction.order}`}
            onDoubleClick={() => handleDoubleClick(instruction.id)}
          >
            <TableCell>{instruction.id}</TableCell>
            <TableCell>
              <CellMethod {...instruction} />
            </TableCell>
            <TableCell>
              <SecondButton
                onClick={() => setSelectedPJID(instruction.pj_id || 0)}
                label={`ID:${instruction.pj_id}`}
              />
            </TableCell>
            <TableCell>
              <Typography>{`${instruction.start_datetime}`}</Typography>
              <Typography>{`${instruction.start_name}`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{`${instruction.end_datetime}`}</Typography>
              <Typography>{`${instruction.end_name}`}</Typography>
            </TableCell>
            <TableCell>
              <CellStatus {...instruction} />
            </TableCell>
            <TableCell>
              <CellAchievements {...instruction} />
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

const CellMethod = (inst: tInstructionCard) => {
  const { SYSTEM } = useContext(MstDataContext);
  if (!SYSTEM) return <Loading flg={!SYSTEM} />;

  if (inst.tm_id === SYSTEM.tranMethod.own.id) {
    return (
      <>
        <Typography>{`自社：${inst.driver_name}`}</Typography>
        <Typography>{`${inst.v_plate}`}</Typography>
        <Typography>
          {inst.start_trailer_plate ? inst.start_trailer_plate : '-'}
        </Typography>
      </>
    );
  } else if (inst.tm_id === SYSTEM.tranMethod.charter.id) {
    return (
      <>
        <Typography>{`傭車：${inst.c_abbreviation}`}</Typography>
        <Typography>{`${inst.c_vehicle}`}</Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography>{`${inst.tm_name}`}</Typography>
      </>
    );
  }
};

const CellStatus = (inst: tInstructionCard) => {
  const { SYSTEM } = useContext(MstDataContext);
  if (!SYSTEM) return <Loading flg={!SYSTEM} />;

  const lbl = SYSTEM.instruction.status.list.find(
    (status) => status.id === inst.status
  )?.label;

  return <Typography>{lbl}</Typography>;
};

const CellAchievements = (inst: tInstructionCard) => {
  const { SYSTEM } = useContext(MstDataContext);
  if (!SYSTEM) return <Loading flg={!SYSTEM} />;

  return (
    <>
      {inst.instruction_datetime && (
        <Typography>{`指示日：${inst.instruction_name}(${inst.instruction_datetime})`}</Typography>
      )}
      {inst.execute_datetime && (
        <Typography>{`輸送開始：${inst.execute_name}(${inst.execute_datetime})`}</Typography>
      )}
      {inst.complete_datetime && (
        <Typography>{`完了：${inst.complete_name}(${inst.complete_datetime})`}</Typography>
      )}
    </>
  );
};

interface SubContentProps {
  totalPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({
  totalPages,
  currentPage,
  getSearch,
}: SubContentProps) => {
  return (
    <PageNation
      totalPages={totalPages}
      currentPage={currentPage}
      handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
        getSearch(value)
      }
    />
  );
};
