import React, { useEffect, useState } from 'react';
import TableFrame from 'frames/TableFrame';
import { useNavigate } from 'react-router-dom';
import { tAddress, tAddressSearch } from 'types/address';
import { TableRow, TableCell, Grid } from '@mui/material';
import { getAddresses } from 'functions/api/mst';
import { PrimaryButton } from 'atoms/Button';
import PageNation from 'components/Pagenation';
import * as cMst from 'components/mst/Index';
import * as AddressBtn from 'components/address/Button';
import { apiGetIndexSuccess } from 'types/index';

export default function Main() {
  const [addresses, setAddresses] = useState<tAddress[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState({});

  const callbackGetIndex = ({
    data,
    currentPage,
    lastPage,
    filter,
  }: apiGetIndexSuccess) => {
    setAddresses(data);
    setCurrentPage(currentPage);
    setLastPage(lastPage);
    setSearch(filter);
  };

  // データ取得処理
  const getSearch = async (page: number) => {
    // ここにデータ取得処理を記述します
    getAddresses({
      page: page,
      filter: search,
      callbackSuccess: callbackGetIndex,
    });
  };

  useEffect(() => {
    getSearch(1);
  }, []);

  return (
    <TableFrame
      HeadContent={
        <HeadContent
          setData={setAddresses}
          setCurrentPage={setCurrentPage}
          setLastPage={setLastPage}
          setFilter={setSearch}
        />
      }
      TableHeaderRows={<TableHeaderRows />}
      TableBodyRows={<TableBodyRows addresses={addresses} />}
      SubContent={
        <SubContent
          lastPages={lastPage}
          currentPage={currentPage}
          getSearch={getSearch}
        />
      }
    />
  );
}

const HeadContent = ({
  setData,
  setCurrentPage,
  setLastPage,
  setFilter,
}: {
  setData: React.Dispatch<React.SetStateAction<tAddress[]>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setLastPage: React.Dispatch<React.SetStateAction<number>>;
  setFilter: React.Dispatch<React.SetStateAction<tAddressSearch>>;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <AddressBtn.SearchBtn
        setData={setData}
        setCurrentPage={setCurrentPage}
        setLastPage={setLastPage}
        setFilter={setFilter}
      />
      <PrimaryButton
        onClick={() => {
          navigate('/mst/company/input');
        }}
        label="新規"
      />
    </>
  );
};

const TableHeaderRows = () => {
  return (
    <TableRow>
      <TableCell>場所名</TableCell>
      <TableCell>住所</TableCell>
      <TableCell>積地</TableCell>
      <TableCell>卸地</TableCell>
      <TableCell>事務所</TableCell>
    </TableRow>
  );
};

interface TableBodyRowsProps {
  addresses: tAddress[];
}
const TableBodyRows = ({ addresses }: TableBodyRowsProps) => {
  const navigate = useNavigate();
  return (
    <>
      {addresses.map((address) => {
        return (
          <TableRow
            key={`addresses-${address.id}`}
            onDoubleClick={() => navigate(`/mst/address/edit/${address.id}`)}
          >
            <TableCell>{address.name}</TableCell>
            <TableCell>{`${address.prefectures}${address.city}${address.street}${address.building}`}</TableCell>
            <TableCell>{address.flg_load ? '◯' : '×'}</TableCell>
            <TableCell>{address.flg_unload ? '◯' : '×'}</TableCell>
            <TableCell>{address.flg_office ? '◯' : '×'}</TableCell>
          </TableRow>
        );
      })}
    </>
  );
};

const SubContent = ({
  lastPages,
  currentPage,
  getSearch,
}: {
  lastPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}) => {
  return (
    <Grid container justifyContent="center">
      <PageNation
        totalPages={lastPages}
        currentPage={currentPage}
        handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
          getSearch(value)
        }
      />
    </Grid>
  );
};
