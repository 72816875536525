import React, { useEffect, useState } from 'react';
import TableFrame from 'frames/TableFrame';
import { useNavigate } from 'react-router-dom';
import { tCustomInvoiceEditGroupCId } from 'types/invoice';
import { getCustomInvoiceEditGroupCId } from 'functions/api/paperwork';
import { TableCell, TableRow } from '@mui/material';
import PageNation from 'components/Pagenation';
import * as CIEBtn from 'components/customInvocie/Button';

//const ddType = 'TransportCard';

export default function Main() {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [cie, setCIE] = useState<tCustomInvoiceEditGroupCId[]>([]);
  const [search, setSearch] = useState<Record<string, string | number>>({});

  const callbackGet = (
    data: tCustomInvoiceEditGroupCId[],
    currentPage: number,
    lastPage: number,
    filter: Record<string, string | number>
  ) => {
    setCIE(data);
    setCurrentPage(currentPage);
    setTotalPages(lastPage);
    setSearch(filter);
  };

  const getSearch = async (page: number) => {
    getCustomInvoiceEditGroupCId(page, callbackGet, search);
  };

  useEffect(() => {
    getSearch(1);
  }, []);

  useEffect(() => {
    getSearch(currentPage);
  }, [currentPage]);

  return (
    <>
      <TableFrame
        HeadContent={<HeadContent />}
        TableHeaderRows={<HeadRow />}
        TableBodyRows={<TableBodyRows data={cie} />}
        SubContent={
          <SubContent
            totalPages={totalPages}
            currentPage={currentPage}
            getSearch={getSearch}
          />
        }
      />
    </>
  );
}

//interface ButtonsProps {}
const HeadContent = () => {
  return (
    <>
      <CIEBtn.NavigateInput />
    </>
  );
};

// interface DataCellProps {}
const HeadRow = () => {
  return (
    <TableRow>
      <TableCell className="day">会社名</TableCell>
      <TableCell className="day">明細件数</TableCell>
      <TableCell className="day">合計金額(税抜)</TableCell>
      <TableCell className="day">合計金額(税込)</TableCell>
    </TableRow>
  );
};

interface BodyDataProps {
  data: tCustomInvoiceEditGroupCId[];
}
const TableBodyRows = ({ data }: BodyDataProps) => {
  const navigate = useNavigate();
  return (
    <>
      {data.map((row) => (
        <TableRow
          key={row.id}
          onDoubleClick={() =>
            navigate(`/paperwork/custom-invoice/input?c_id=${row.c_id}`)
          }
        >
          <TableCell>{row.company.abbreviation}</TableCell>
          <TableCell>{row.group_count}</TableCell>
          <TableCell>{row.total_price}</TableCell>
          <TableCell>{row.total_price + row.total_price_tax}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

interface SubContentProps {
  totalPages: number;
  currentPage: number;
  getSearch: (page: number) => void;
}
const SubContent = ({
  totalPages,
  currentPage,
  getSearch,
}: SubContentProps) => {
  return (
    <PageNation
      totalPages={totalPages}
      currentPage={currentPage}
      handlePageChange={(event: React.ChangeEvent<unknown>, value: number) => {
        getSearch(value);
      }}
    />
  );
};
