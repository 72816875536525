import { create, API_URL } from 'functions/axios';

interface Props {
  callbackSuccess?: () => void;
  callbackError?: () => void;
}

// 認証チェック
export const isAuthenticated = ({
  callbackSuccess = undefined,
  callbackError = undefined,
}: Props) => {
  const ins = create();
  ins
    .get(API_URL.base + '/token-check', {})
    .then((res: any) => {
      if (res.status !== 200) {
        if (callbackSuccess) {
          callbackSuccess();
        } else {
          // エラーがある場合は、ログアウトしてログインページにリダイレクト
          window.location.href = '/logout';
        }
      }
    })
    .catch((error: any) => {
      if (callbackError) {
        callbackError();
      } else {
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        window.location.href = '/logout';
      }
    });
};

export default isAuthenticated;
