import React, { useState, forwardRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { isSameDate } from 'functions/time';
import { tInstruction } from 'types/instruction';
import { strDateTimeOrigin } from 'functions/time';
import { MstDataContext } from 'contexts/Mst';
import { tTransportMethod } from 'types/mst';
import BaseCard from 'atoms/Card';
import { Toolchip } from 'atoms/Toolchip';
import { cardType } from 'types/index';
import { cardLabelType } from 'types/instruction';

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const Nomal = forwardRef<
  HTMLDivElement,
  {
    instruction: tInstruction;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    selected?: boolean;
    children?: React.ReactNode;
  }
>((props, ref) => {
  const {
    instruction,
    callbackClick,
    flgOmit = true,
    children,
    selected = false,
  } = props;
  const navigate = useNavigate();

  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    if (instruction.tm_id === SYSTEM?.tranMethod.own.id) {
      return `${SYSTEM?.tranMethod.own.label}(${instruction.driver?.family_name})`;
    } else if (instruction.tm_id === SYSTEM?.tranMethod.charter.id) {
      return `${SYSTEM?.tranMethod.charter.label}(${instruction.transport_company?.abbreviation})`;
    } else {
      const obj = tranMethods?.find(
        (tm: tTransportMethod) => tm.id === instruction.tm_id
      );
      return `${obj?.abbreviation}`;
    }
  };

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // 親への伝播を止める
    if (flgOmit) {
      return;
    }
    //navigate(`/project/edit/${transportCard.pj_id}`);
  };

  return (
    <>
      <BaseCard
        ref={ref}
        selected={selected}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={callbackClick}
        otherClassName="size-auto"
      >
        {children}
        {!flgOmit ? (
          <>
            <Typography>{titleLabel()}</Typography>

            <Box>
              <Typography>{`${strDateTimeOrigin(
                new Date(instruction.start_datetime)
              )}`}</Typography>
              <Typography>
                {`${instruction.start_name}`}
                {`(${instruction.start_prefectures}${instruction.start_city})`}
              </Typography>
            </Box>

            <Box>
              <Typography>{`${strDateTimeOrigin(
                new Date(instruction.end_datetime)
              )}`}</Typography>
              <Typography>
                {`${instruction.end_name}`}
                {`(${instruction.end_prefectures}${instruction.end_city})`}
              </Typography>
            </Box>
          </>
        ) : (
          ''
        )}
      </BaseCard>
    </>
  );
});
Nomal.displayName = 'instruction-card-nomal';

/**
 * 運行指示情報カード(空)
 * @param param0
 * @returns
 */
export const Blank = forwardRef<
  HTMLDivElement,
  {
    label: string;
    callbackDoubleClick?:
      | ((event: React.MouseEvent<HTMLElement>) => void)
      | undefined;
    flgOmit?: any;
    ref?: any;
    isOver: boolean;
    toolchipChildren?: React.ReactNode | undefined;
    openToolchip?: boolean;
  }
>((props, ref) => {
  const {
    label,
    callbackDoubleClick = undefined,
    flgOmit = true,
    isOver = false,
    toolchipChildren = undefined,
    openToolchip = false,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackDoubleClick) {
      callbackDoubleClick(event);
    }
  };

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (toolchipChildren === undefined) {
      return;
    }

    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  useEffect(() => {
    if (openToolchip) {
      // クリックイベントを発火する
      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      });
    }
  }, [openToolchip]);

  return (
    <Box className={`instruction-card-blank`}>
      <BaseCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        className={'blank'}
        isOver={isOver}
      >
        {!flgOmit && <Typography className="stamp">{`${label}`}</Typography>}
      </BaseCard>
      {toolchipChildren && (
        <Toolchip
          open={open}
          anchorEl={anchorEl}
          handleClose={handleToolchipClose}
        >
          {toolchipChildren}
        </Toolchip>
      )}
    </Box>
  );
});
Blank.displayName = 'instruction-card-blank';

/**
 * ツールチップ付き運行指示作成用輸送情報カード
 */
export const WithToolchip = forwardRef<
  HTMLDivElement,
  {
    instruction: tInstruction;
    flgOmit?: any;
    ref?: any;
    selected?: boolean;
    cardChildren?: React.ReactNode;
    toolchipChildren: React.ReactNode;
    type?: cardType;
    labelType?: cardLabelType;
    callbackDoubleClick?:
      | ((event: React.MouseEvent<HTMLElement>) => void)
      | undefined;
  }
>((props, ref) => {
  const {
    instruction,
    flgOmit = true,
    selected = false,
    cardChildren,
    toolchipChildren,
    type = 'box',
    labelType = 'index',
    callbackDoubleClick = undefined,
  } = props;
  const navigate = useNavigate();
  const { SYSTEM } = useContext(MstDataContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  let statusKey = '';
  switch (instruction.status) {
    case SYSTEM?.instruction.status.InTransit:
      statusKey = 'InTransit';
      break;
    case SYSTEM?.instruction.status.Instructed:
      statusKey = 'Instructed';
      break;
    case SYSTEM?.instruction.status.Suspend:
      statusKey = 'Suspend';
      break;
    default:
      statusKey = 'none';
      break;
  }

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };
  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    if (callbackDoubleClick) {
      callbackDoubleClick(event);
    }
  };

  return (
    <>
      <BaseCard
        ref={ref}
        selected={selected}
        flgOmit={flgOmit}
        type={type}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        otherClassName={`size-auto status-${statusKey}`}
      >
        {selected && (
          <Typography className="stamp secondary">{`選`}</Typography>
        )}
        {cardChildren}
        <Labels type={labelType} flgOmit={flgOmit} instruction={instruction} />
        <AttributeChip instruction={instruction} flgOmit={flgOmit} />
      </BaseCard>
      <Toolchip
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {toolchipChildren}
      </Toolchip>
    </>
  );
});
WithToolchip.displayName = 'instruction-card-with-toolchip';

const Labels = ({
  instruction,
  type,
  flgOmit,
}: LabelsTypeIndexProps & { type: cardLabelType }) => {
  switch (type) {
    case 'index':
      return <LabelsTypeIndex instruction={instruction} flgOmit={flgOmit} />;
    case 'driver':
      return <LabelsTypeDriver instruction={instruction} flgOmit={flgOmit} />;
    case 'charter':
      return <LabelsTypeCharter instruction={instruction} flgOmit={flgOmit} />;
    case 'method':
      return <LabelsTypeMethoed instruction={instruction} flgOmit={flgOmit} />;
    default:
      return <></>;
  }
};

interface LabelsTypeIndexProps {
  instruction: tInstruction;
  flgOmit: boolean;
}
const LabelsTypeIndex = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    if (instruction.tm_id === SYSTEM?.tranMethod.own.id) {
      return `${SYSTEM?.tranMethod.own.label}(${instruction.driver?.family_name})`;
    } else if (instruction.tm_id === SYSTEM?.tranMethod.charter.id) {
      return `${SYSTEM?.tranMethod.charter.label}(${instruction.transport_company?.abbreviation})`;
    } else {
      const obj = tranMethods?.find(
        (tm: tTransportMethod) => tm.id === instruction.tm_id
      );
      return `${obj?.abbreviation}`;
    }
  };

  if (!flgOmit) {
    return (
      <>
        <Typography>{titleLabel()}</Typography>
        <Box>
          <Typography>{`${strDateTimeOrigin(
            new Date(instruction.start_datetime)
          )}`}</Typography>
          <Typography>
            {`${instruction.start_name}`}
            {`(${getStartAddress(instruction)})`}
          </Typography>
        </Box>

        <Box>
          <Typography>{`${strDateTimeOrigin(
            new Date(instruction.end_datetime)
          )}`}</Typography>
          <Typography>
            {`${instruction.end_name}`}
            {`(${getEndAddress(instruction)})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeDriver = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  if (!flgOmit) {
    return (
      <>
        <Box className={'contents'}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
        </Box>
        <Box className={'contents'}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${getStartAddress(instruction)})`}
          </Typography>

          <Typography>
            {`${instruction.end_name}`}
            {`(${getEndAddress(instruction)})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeCharter = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    return `${instruction.transport_company?.abbreviation}(${instruction.c_vehicle})`;
  };
  if (!flgOmit) {
    return (
      <>
        <Box className={'contents'}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
          <Typography>{titleLabel()}</Typography>
        </Box>
        <Box className={'contents'}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${getStartAddress(instruction)})`}
          </Typography>

          <Typography>
            {`${instruction.end_name}`}
            {`(${getEndAddress(instruction)})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeMethoed = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    const obj = tranMethods?.find(
      (tm: tTransportMethod) => tm.id === instruction.tm_id
    );
    return `${obj?.abbreviation}`;
  };

  if (!flgOmit) {
    return (
      <>
        <Box className={'contents'}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
          <Typography>{titleLabel()}</Typography>
        </Box>
        <Box className={'contents'}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${getStartAddress(instruction)})`}
          </Typography>
          <Typography>
            {`${instruction.end_name}`}
            {`(${getEndAddress(instruction)})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const getStartAddress = (instruction: tInstruction) => {
  let str = '';
  if (instruction.start_prefectures) {
    str += instruction.start_prefectures;
  }
  if (instruction.start_city) {
    str += instruction.start_city;
  }
  if (instruction.start_street) {
    str += instruction.start_street;
  }
  if (instruction.start_building) {
    str += instruction.start_building;
  }
  return str;
};
const getEndAddress = (instruction: tInstruction) => {
  let str = '';
  if (instruction.end_prefectures) {
    str += instruction.end_prefectures;
  }
  if (instruction.end_city) {
    str += instruction.end_city;
  }
  if (instruction.end_street) {
    str += instruction.end_street;
  }
  if (instruction.end_building) {
    str += instruction.end_building;
  }
  return str;
};

const AttributeChip = ({
  instruction,
  flgOmit,
}: LabelsTypeIndexProps): React.ReactElement => {
  const sameDate = isSameDate(
    instruction.start_datetime,
    instruction.end_datetime
  );

  return (
    <Box className="attribute-chip">
      {sameDate && <Typography className="chip evening">当</Typography>}
    </Box>
  );
};
