import React, { useState, useEffect, forwardRef, useRef } from 'react';
import Popover from 'atoms/Toolchip';
import { Box, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Grid, Container } from '@mui/material';
import { useSelection } from 'contexts/Schedules';
import { truncateString } from 'functions/index';
import { isSameDate } from 'functions/time';
import { tTransportCard } from 'types/transport';
import { MailOutlineSharp } from '@mui/icons-material';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface ToolchipProps {
  children?: React.ReactNode;
  toolchipChildren: React.ReactNode;
}

export const CardWithToolchip = ({
  children,
  toolchipChildren,
}: ToolchipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  // 子要素が変化した場合はポップオーバーを閉じる
  useEffect(() => {
    setAnchorEl(null);
  }, [children]);

  return (
    <Box className="CardWithToolchip">
      <Box onClick={handleCardClick}>{children}</Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {toolchipChildren}
      </Popover>
    </Box>
  );
};

export default CardWithToolchip;
