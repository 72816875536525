import React, { useState, useEffect, useContext } from 'react';
import { tVehicle, initialVehicle, tTransportMethod } from 'types/mst';
import { tAddress } from 'types/address';
import { Grid } from '@mui/material';
import * as cTranMethod from 'components/mst/TransportMethod';
import { useParams } from 'react-router-dom';
import {
  getTransportMethod,
  storeTransportMethod,
  updateTransportMethod,
  deleteTransportMethod,
  getAddress,
} from 'functions/api/mst';
import { PrimaryButton } from 'atoms/Button';
import { initTransportMethod } from 'const/transportMethod';
import { Box, Typography } from '@mui/material';
import { InputFormes } from 'components/mst/Address';
import { useTheme } from '@mui/material/styles';
import { initAddress } from 'const/address/index';

export default function Main() {
  const { id } = useParams();
  const theme = useTheme();
  const [data, setData] = useState<tTransportMethod>(initTransportMethod);
  const [startAddress, setStartAddress] = useState<tAddress>(initAddress);
  const [endAddress, setEndAddress] = useState<tAddress>(initAddress);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        getTransportMethod(id).then((res) => {
          if (res.status !== 200) throw new Error('データ取得に失敗しました');

          const tranMethod: tTransportMethod = res.data;
          setData(tranMethod);

          // 開始地点情報をセット
          if (tranMethod.start_a_id) {
            getAddress(tranMethod.start_a_id)
              .then((resSA) => {
                if (resSA.status !== 200)
                  throw new Error('開始地点のデータ取得に失敗しました。');
                setStartAddress(resSA.data);
              })
              .catch((resSA) => {
                alert('開始地点のデータ取得に失敗しました。');
              });
          } else {
            setStartAddress((prev) => {
              return {
                ...prev,
                id: tranMethod.start_a_id || 0,
                name: tranMethod.start_name,
                prefectures: tranMethod.start_prefectures,
                city: tranMethod.start_city,
                street: tranMethod.start_street,
                building: tranMethod.start_building,
              };
            });
          }

          // 終了地点情報をセット
          if (tranMethod.end_a_id) {
            getAddress(tranMethod.end_a_id)
              .then((resSA) => {
                if (resSA.status !== 200)
                  throw new Error('終了地点のデータ取得に失敗しました。');
                setEndAddress(resSA.data);
              })
              .catch((resSA) => {
                alert('終了地点のデータ取得に失敗しました。');
              });
          } else {
            setEndAddress((prev) => {
              return {
                ...prev,
                id: tranMethod.end_a_id || 0,
                name: tranMethod.end_name,
                prefectures: tranMethod.end_prefectures,
                city: tranMethod.end_city,
                street: tranMethod.end_street,
                building: tranMethod.end_building,
              };
            });
          }
        });
      } catch (error) {
        console.error(error);
        alert('データ取得に失敗しました');
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  // マスタ更新
  const setPostRequestUpdate = () => {
    updateTransportMethod(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ更新に失敗しました');
        alert('データを更新しました');
        window.location.href = '/mst/transport-method';
      })
      .catch((err) => {
        console.error(err);
        alert('データ更新に失敗しました');
      });
  };

  // マスタ新規登録
  const setPostRequestStore = () => {
    storeTransportMethod(data)
      .then((res) => {
        if (res.status !== 200) throw new Error('データ登録に失敗しました');
        alert('データを登録しました');
        window.location.href = '/mst/transport-method';
      })
      .catch((err) => {
        console.error(err);
        alert('データ登録に失敗しました');
      });
  };

  // マスタ削除
  const setPostRequestDelete = () => {
    if (!window.confirm('削除しますか？')) return;
    deleteTransportMethod(data.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error('データ削除に失敗しました');
        alert('データを削除しました');
        window.location.href = '/mst/transport-method';
      })
      .catch((err) => {
        console.error(err);
        alert('データ削除に失敗しました');
      });
  };

  useEffect(() => {
    setData((prev: tTransportMethod) => ({
      ...prev,
      start_a_id: startAddress.id,
      start_name: startAddress.name,
      start_prefectures: startAddress.prefectures,
      start_city: startAddress.city,
      start_street: startAddress.street,
      start_building: startAddress.building,
    }));
  }, [startAddress]);

  useEffect(() => {
    setData((prev: tTransportMethod) => ({
      ...prev,
      end_a_id: endAddress.id,
      end_name: endAddress.name,
      end_prefectures: endAddress.prefectures,
      end_city: endAddress.city,
      end_street: endAddress.street,
      end_building: endAddress.building,
    }));
  }, [endAddress]);

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestUpdate} label="更新" />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStore} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <cTranMethod.Name values={data} setValues={setData} />
        <cTranMethod.Abbreviation values={data} setValues={setData} />
        <cTranMethod.ChipName values={data} setValues={setData} />

        <Grid item xs={12}>
          <Typography variant="h4">発</Typography>
          <Box
            sx={{ display: 'flex', flexFlow: 'rows', gap: theme.spacing(1) }}
          >
            <cTranMethod.StartTime values={data} setValues={setData} />
            <InputFormes values={startAddress} setValues={setStartAddress} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">着地</Typography>
          <Box
            sx={{ display: 'flex', flexFlow: 'rows', gap: theme.spacing(1) }}
          >
            <cTranMethod.EndTime values={data} setValues={setData} />
            <InputFormes values={endAddress} setValues={setEndAddress} />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
