import { create, API_URL, getCsrf } from 'functions/axios';
import { tCustomInvoiceEdit, tCustomInvoiceEditGroupCId } from 'types/invoice';
import log from 'functions/logger';
import { tOrder } from 'types';
import { tInvoice } from 'types/invoice';
import {
  apiGetIndexProps,
  apiGetListSuccess,
  apiListProps,
  apiGetShowProps,
} from 'types/index';

/**
 * 差戻し登録
 * @param filter
 * @returns
 */
export function postSahimodoshi(filter: any = {}) {
  const ins = create();
  return ins.post(API_URL.base + '/project-remants/store', filter);
}

/**
 * 請求書登録
 * @param params
 * @returns
 */
export function storeInvoice(
  params: any,
  callbackSuccess?: (data: tInvoice) => void,
  callbackError?: () => void
) {
  const ins = create();
  return ins
    .post(API_URL.base + '/invoice/store', params)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの登録に失敗しました');
      }
      log.debug('storeInvoice', res.data);
      if (callbackSuccess) callbackSuccess(res.data);
    })
    .catch((err) => {
      console.error(err);
      alert('データの登録に失敗しました');
      if (callbackError) callbackError();
    });
}

/**
 * 請求書PDFダウンロード
 * @param i_id
 * @returns
 */
export function getInvoice(
  id: number,
  callbackSuccess: (data: tInvoice) => void,
  callbackError: (() => void) | undefined = undefined
) {
  const ins = create();
  return ins
    .get(`${API_URL.base}/invoice/show/${id}`)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getInvoice', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      if (callbackError) {
        callbackError();
      }
      alert('データの取得に失敗しました');
    });
}

/**
 * 請求書PDFダウンロード
 * @param i_id
 * @returns
 */
export function getInvocies(page: number, filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(`${API_URL.base}/invoice/index`, {
    params: { page: page, filter: filter, order: order },
  });
}

/**
 * 個別請求会一覧
 * @returns
 */
export function getCustomInvoiceEditList({
  filter,
  order = [], // ここでデフォルト値を設定
  withs = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiListProps & {
  callbackSuccess: (({ data, filter }: apiGetListSuccess) => void) | undefined;
}) {
  if (order.length === 0) {
    order.push({ field: 'date', direction: 'asc' });
  }

  const ins = create();
  return ins
    .get(API_URL.base + '/invoice-custom/list', {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getCustomInvoiceEditList', res.data);
      if (callbackSuccess) {
        callbackSuccess({ data: res.data, filter: filter });
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 個別請求会社別一覧
 * @returns
 */
export function getCustomInvoiceEditGroupCId(
  page: number,
  callbackSuccess: (
    data: tCustomInvoiceEditGroupCId[],
    currentPage: number,
    lastPage: number,
    filter: any
  ) => void,
  filter: any = {},
  order: tOrder[] = [],
  withs: any = [],
  callbackError: (() => void) | undefined = undefined
) {
  if (order.length === 0) {
    order = [{ field: 'c_id', direction: 'asc' }];
  }
  const ins = create();
  return ins
    .get(API_URL.base + '/invoice-custom/index/group-cid', {
      params: { page: page, filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getCustomInvoiceEditGroupCId', res.data);
      callbackSuccess(
        res.data.data,
        res.data.current_page,
        res.data.last_page,
        filter
      );
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 個別請求会社別一覧
 * @returns
 */
export function getCustomInvoiceEdit({
  id,
  withs = [],
  callbackSuccess = undefined,
  callbackError = undefined,
}: apiGetShowProps & {
  callbackSuccess: ((data: tCustomInvoiceEdit) => void) | undefined;
}) {
  const ins = create();
  return ins
    .get(`${API_URL.base}/invoice-custom/show/${id}`, {
      params: { withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの取得に失敗しました');
      }
      log.debug('getCustomInvoiceEdit', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの取得に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 個別請求登録
 * @param callbackSuccess
 * @param callbackError
 * @param data
 * @returns
 */
export function storeCustomInvoiceEdit(
  callbackSuccess: ((data: tCustomInvoiceEdit) => void) | undefined,
  callbackError: (() => void) | undefined,
  data: tCustomInvoiceEdit
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/store`, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの登録に失敗しました');
      }
      log.debug('storeCustomInvoiceEdit', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの登録に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}

/**
 * 個別請求更新
 * @param callbackSuccess
 * @param callbackError
 * @param data
 * @returns
 */
export function updateCustomInvoiceEdit(
  callbackSuccess: ((data: tCustomInvoiceEdit) => void) | undefined,
  callbackError: (() => void) | undefined,
  data: tCustomInvoiceEdit
) {
  const ins = create();
  return ins
    .post(`${API_URL.base}/invoice-custom/update/${data.id}`, data)
    .then((res) => {
      if (res.status !== 200) {
        throw new Error('データの更新に失敗しました');
      }
      log.debug('updateCustomInvoiceEdit', res.data);
      if (callbackSuccess) {
        callbackSuccess(res.data);
      }
    })
    .catch((err) => {
      console.error(err);
      alert('データの更新に失敗しました');
      if (callbackError) {
        callbackError();
      }
    });
}
