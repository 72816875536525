import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { tTransportMethod } from 'types/mst';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Grid,
} from '@mui/material';
import { getTransportMethods } from 'functions/api/mst';
import { PrimaryButton } from 'atoms/Button';
import { MstDataContext } from 'contexts/Mst';
import PageNation from 'components/Pagenation';
import LoadingCircular from 'atoms/Loading';
import { initTransportMethod } from 'const/transportMethod';
import { startLabel, endLabel } from 'functions/transportMethod';

export default function Main() {
  const navigate = useNavigate();
  const [tranMethods, setTranMethods] = useState<tTransportMethod[]>([
    initTransportMethod,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { loading, SYSTEM } = useContext(MstDataContext);

  const getSearch = async (page: number) => {
    try {
      getTransportMethods(page)
        .then((res) => {
          if (res.status !== 200) throw new Error('データ取得に失敗しました');
          setTranMethods(res.data.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalPages(res.data.last_page);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSearch(currentPage);
  }, []);

  if (loading || !SYSTEM) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate('/mst/transport-method/input');
              }}
              label="新規"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ maxWidth: '600px' }}>
            <TableHead>
              <TableRow>
                <TableCell>名称</TableCell>
                <TableCell>開始地点</TableCell>
                <TableCell>終了地点</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tranMethods.map((tranMethod: tTransportMethod) => {
                if (tranMethod.domain === SYSTEM.domain) return null;
                return (
                  <TableRow
                    key={`tranMethod-${tranMethod.id}`}
                    onDoubleClick={() =>
                      navigate(`/mst/transport-method/edit/${tranMethod.id}`)
                    }
                  >
                    <TableCell>{tranMethod.name}</TableCell>
                    <TableCell>
                      <li>{`${tranMethod.start_time}`}</li>
                      <li>{`${startLabel(tranMethod)}`}</li>
                    </TableCell>
                    <TableCell>
                      <li>{`${tranMethod.end_time}`}</li>
                      <li>{`${endLabel(tranMethod)}`}</li>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
