import {
  tInstruction,
  cbGetInstructionCards,
  cbGetInstructionCardList,
} from 'types/instruction';
import {
  getInstructionCards,
  getInstructionCardList,
} from 'functions/api/instruction';
import { apiGetIndexSuccess, apiGetListSuccess, tOrder } from 'types/index';

export const checkTranMethod = (
  instructions: tInstruction[],
  tmpInstructions: tInstruction[],
  tmId: number
) => {
  const margeInst = instructions.concat(tmpInstructions);

  return margeInst.find((inst) => inst.tm_id === tmId);
};

/**
 * 運行指示検索処理（ページネーション付き）
 * @param page
 * @param filter
 * @param callback
 */
export const searchIndex = (
  page: number,
  filter: any = {},
  callback: (props: cbGetInstructionCards) => void
) => {
  getInstructionCards({
    page: page,
    filter: filter,
    callbackSuccess: (props: apiGetIndexSuccess) => callback(props),
  });
};

/**
 * 運行指示検索処理（一覧）
 * @param page
 * @param filter
 * @param callback
 */
export const searchList = (
  filter: any = {},
  order: tOrder[] = [],
  withs: any[] = [],
  callback: (props: cbGetInstructionCardList) => void
) => {
  getInstructionCardList({
    filter: filter,
    order: order,
    withs: withs,
    callbackSuccess: (props: apiGetListSuccess) => callback(props),
  });
};
